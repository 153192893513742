import React from "react";
import CardFilter from "../components/CardFilter/CardFilter";
import Header from "../components/Header/Header";
import Loader from "../components/Loader";
import Product_card from "../components/ProductInfo/Product_card";
import GetAll from "../hooks/GetAll";
function Mahsulotlar() {
  const { data, loading } = GetAll({
    url: '/landing-products/'
  })
  if (loading) return <Loader/>
  return (
    <div className="mahsulotlar container2">
      <Header prod={data?.product} product={true} />
      <CardFilter data={data?.products} />
      {
        data?.discounts?.map((elem) => (
          <Product_card warehouses={data?.warehouses} data={elem} />
        ))
      }
    </div>
  );
}

export default Mahsulotlar;
