import GetAll from 'modules/GetAll';
import s from './SalaryHistory.module.scss'
import OverallIncome from 'container/OverallIncome'

const Index = () => {
  return (
    <GetAll name={"salary_all"} url={"/medics-profile/"}>
      {({ items }) => {
        return (
          <div className={s.Salary}>
            <div className="container">
              <div className={s.Tables_wrapper}>
                <h1>Umumiy maosh tarixi</h1>
                {items?.data?.salary_history?.map((history) => {
                  return <OverallIncome history={history} />;
                })}
              </div>
            </div>
          </div>
        );
      }}
    </GetAll>
  );
}

export default Index