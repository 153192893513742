import React from "react";
import Chart from "chart.js/auto";
import { Bar, Pie } from "react-chartjs-2";
import s from './BarChart.module.scss'
import { Link } from "react-router-dom";
import { ResponsivePie } from '@nivo/pie'
const BarChartBig = ({ elem }) => {

  function getData() {
    let data = []
    Object.keys(elem).forEach((item) => {
      // console.log(elem[item]);
      data.push({ label: item, value: elem[item].sales_amount ,id:item})
    })
    return data
  }
  const data = [
    {
      "id": "scala",
      "label": "scala",
      "value": 302,
      // "color": "hsl(117, 70%, 50%)"
    },
    {
      "id": "c",
      "label": "c",
      "value": 46,
      // "color": "hsl(191, 70%, 50%)"
    },
    {
      "id": "stylus",
      "label": "stylus",
      "value": 94,
      // "color": "hsl(292, 70%, 50%)"
    },
    {
      "id": "haskell",
      "label": "haskell",
      "value": 419,
      // "color": "hsl(284, 70%, 50%)"
    },
    {
      "id": "elixir",
      "label": "elixir",
      "value": 271,
      // "color": "hsl(230, 70%, 50%)"s
    }
  ]
  return (
    <div className={s.Barchart_wrapper} >
      <div className={s.Barchart_wrapper_inner} >
        <div className={s.Barchart_wrapper_inner_element} >
          {/* <Pie {...config} /> */}
          <ResponsivePie
            data={getData()}
            margin={{ top: 40, right: 80, bottom: 80, left: 0 }}
            startAngle={-180}
            padAngle={1}
            cornerRadius={4}
            activeInnerRadiusOffset={10}
            activeOuterRadiusOffset={8}
            borderColor={{
              from: 'color',
              modifiers: [
                [
                  'darker',
                  '0.2'
                ]
              ]
            }}
            arcLinkLabelsSkipAngle={1}
            arcLinkLabelsTextOffset={14}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsOffset={0}
            arcLinkLabelsStraightLength={36}
            arcLinkLabelsThickness={2}
            arcLinkLabelsDiagonalLength={20}
            arcLinkLabelsColor={{ from: 'color' }}
            arcLabelsRadiusOffset={0.75}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
              from: 'color',
              modifiers: [
                [
                  'darker',
                  '2'
                ]
              ]
            }}
            defs={[
              {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                size: 4,
                padding: 1,
                stagger: true
              },
              {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
              }
            ]}
            fill={[
              {
                match: {
                  id: 'ruby'
                },
                id: 'dots'
              },
              {
                match: {
                  id: 'c'
                },
                id: 'dots'
              },
              {
                match: {
                  id: 'go'
                },
                id: 'dots'
              },
              {
                match: {
                  id: 'python'
                },
                id: 'dots'
              },
              {
                match: {
                  id: 'scala'
                },
                id: 'lines'
              },
              {
                match: {
                  id: 'lisp'
                },
                id: 'lines'
              },
              {
                match: {
                  id: 'elixir'
                },
                id: 'lines'
              },
              {
                match: {
                  id: 'javascript'
                },
                id: 'lines'
              }
            ]}
            legends={[
              {
                anchor: 'right',
                direction: 'column',
                justify: false,
                translateX: 0,
                translateY: 19,
                itemsSpacing: 2,
                itemWidth: 71,
                itemHeight: 35,
                itemTextColor: '#999',
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 23,
                symbolShape: 'circle',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemTextColor: '#000'
                    }
                  }
                ]
              }
            ]}
          />
        </div>

        {/* <p className={s.Barchart_wrapper_text_two} >Lorem ipsum dolor sit amet consectetur. Facilisis aenean nec laoreet nulla semper varius lectus nulla. Vitae metus vitae est non suspendisse purus. Etiam arcu non lorem id aliquam placerat morbi. Risus eget volutpat massa bibendum mauris sed. Nulla nam turpis purus sed sed fermentum mi. Elementum ut aenean pellentesque eget tortor in elit. Eleifend eros facilisi dictum at. Purus ligula auctor purus elit.
          Amet pharetra semper mauris arcu donec id molestie. Placerat ac tortor duis lectus consectetur lorem sit. </p> */}
      </div>
    </div>
  );
};

export default BarChartBig;
