import React, { useEffect, useState } from "react";
import s from './Consultants.module.scss'
import Table from "../../components/Table/Table";
import search from '../../assets/icons/Search_Magnifying_Glass.png'
import { elements } from "chart.js";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import AddMedModal from "components/AddMedModal";
import BrendmanagerAdd from "components/BrendmanagerAdd";
export default function Manager({ data, role,refatch }) {
    const [state, setState] = useState()
    const [add, setAdd] = useState(false)
    useEffect(() => {
        setState(data)
    }, [data])
    const column = [
        {
            title: 'ID',
            key: 'user_id',
        },

        {
            title: 'Ism familiya',
            key: 'first_name',
            render: (item, all) => (item + ' ' + all?.last_name)
        },
        {
            title: 'Oylik',
            key: 'salary',
            render: (item) => (item?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + 'uzs')
        },
        {
            title: 'Qarz',
            key: 'debt',
            render: (item) => (item?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + 'uzs')
        },
        {
            title: "To'landi",
            key: 'paid',
            render: (item) => (item?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + 'uzs')
        },
        {
            title: "Batafsil",
            key: 'id',
            render: (item) => (
                <Link className={s.inner_butn} to={`/brandmanager/${item}`} >
                    <Button text='Batafsil' />
                </Link>
            )
        },
    ]
    function Search(e) {
        let arr = []
        data?.forEach((elem) => {
            if (Object.values(elem).join(" ").toLowerCase().includes(e.target.value.toLowerCase())) {
                arr.push(elem)
            }
        })
        if (e.target.value.length > 1) setState(arr)
        else setState(data)
    }
    return (
        <div className={s.Consultants}>
            <div className={s.Consultants_top} >
                <h1 className={s.Consultants_title} >Brend menejerlar ro’yxati</h1>
                {/* <Button onClick={() => setAdd(true)} text={"Brend menejer qo'shish"} /> */}
                {
                    role ? null : <BrendmanagerAdd refatch={refatch}/>
                }
                <div className={s.Consultants_top_button} >
                    <input onChange={(e) => Search(e)} type={'text'} placeholder='Qidirish' />
                    <img src={search} />
                </div>
            </div>
            <div className={s.Consultants_table} >
                <Table column={column} data={state} />
            </div>
        </div>
    )

}