import React from 'react'
import s from './Textarea.module.scss'
export default function Textarea({value,disabled,submitVal,name}) {
    return(
        <div className={s.Textarea} >
            <h1 className={s.Textarea_title} >Biografiya</h1>
            <textarea onChange={(e)=>submitVal(e)} disabled={disabled} name={name} value={value} className={s.Textarea_inner} />
        </div>
    )
    
}