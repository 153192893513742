import React, { useState } from "react";
import s from './AddBranch.module.scss'
import BrachAdd from "components/addBranch";
import ViewBranch from "components/viewBranch/ViewBranch";
import { usePost } from "hooks";
import { toast } from 'react-toastify'
import {  useNavigate } from "react-router-dom";
import Loader from "components/Loader";
export default function AddBrach() {
    const [state, setState] = useState({})
    const { mutate, isLoading } = usePost()
    const navigate = useNavigate()
    function postData(e) {
        e.preventDefault()
        const formdata = new FormData
        Object.keys(state).forEach((elem) => {
            formdata.append(elem, state[elem])
        })
        mutate({
            url:'/warehouses/',
            method: 'post',
            data: formdata,
            onSuccess: success,
            onError: error
        })
    }
    function success(params) {
        toast.success("Filial muvoffaqiyatli qo'shildi!", {
            position: toast.POSITION.TOP_RIGHT,
        });
        navigate('/Branches')
    }
    // function name() {
    //     data?.map((elem)=>{
    //         if(sambo  > 0)
    //         return data
    //     })
    // }
    function error(params) {
        toast.error("Filial qo'shilmadi!", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    if (isLoading) return <Loader/>
    return (
        <div className={s.AddProducts}>
            <div className="container" >
                <BrachAdd postData={postData} setState={setState} state={state} />
                {
                    state?.photo ?
                        <ViewBranch setState={setState} state={state} />
                        :
                        null
                }
            </div>
        </div>
    )

}