import React, { useState } from "react";
import s from './Registration.module.scss'
import { Button, Checkbox, Form, Input, Select } from 'antd';
import axios from "axios";
import { setToken } from "service/storage/token";
import Loader from "components/Loader";
import { toast } from 'react-toastify'
import { useNavigate } from "react-router-dom";
export default function Step4() {
    const [loading, setLoading] = useState(false)
    const [role, setRole] = useState(false)
    const navigate = useNavigate()
    const onFinish = (values) => {
        setLoading(true)
        const formdata = new FormData
        formdata.append('email', values?.email)
        formdata.append('phone_number', values?.phone_number)
        axios
            .post('http://rizonwebappapi.pythonanywhere.com/api/users/signup/', formdata)
            .then((res) => {
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
            })
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
          <Select style={{ width: 80 }}>
            <Select.Option value="998">+998</Select.Option>
          </Select>
        </Form.Item>
      );
    return (
        <div className={s.Login4}>
            {
                loading ?
                    <div className={s.Login_loader_back} >
                        <Loader />
                    </div>
                    :
                    null
            }
            <Form
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                style={{
                    maxWidth: 600,
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="Email"
                    name="email"
                    style={{
                        width: 340
                    }}
                    rules={[
                        {
                            type: 'email',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="phone_number"
                    label="Phone"
                    rules={[{ required: true, message: 'Please input your phone number!' }]}
                >
                    <Input addonBefore={prefixSelector} style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    wrapperCol={{
                        offset: 8,
                        span: 16,
                    }}
                >
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )

}