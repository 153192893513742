import Table from 'components/Table/Table'
import s from './ConnectedProducts.module.scss'

const Index = ({items}) => {
  console.log(items?.data?.product_sales_data);
  const data = Object.keys(items?.data?.product_sales_data).map((item)=>{
    return {
      title: item,
      overallSells: items?.data?.product_sales_data[item].product_sale_amount,
      price: items?.data?.product_sales_data[item].product_sale_summa,
    };
  })
  
  return (
    <div className={s.Table_wrapper}>
        <Table  column={column()} data={data}/>
    </div>
  )
}

export default Index

function column(){
    return [
        {
            title:'Nomi',
            key:'title'
        },
        {
            title:'Narxi',
            key:'price',
        },
        {
            title:'Umumiy sotuvi',
            key:'overallSells',
            render:(value)=>{
                return `${value}ta`
            }
        }
    ]
}
