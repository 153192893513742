import React from "react";
import s from './UserTabel.module.scss'
import Table from "../../components/Table/Table";
import search from '../../assets/icons/Search_Magnifying_Glass.png'
import { elements } from "chart.js";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import group from '../../assets/icons/Group 186.png'
export default function UserTabel({ data, setPageTable, info, elem }) {
    const column = [
        {
            title: 'ID',
            key: 'user_id'
        },
        {
            title: 'Ism familiya',
            key: 'first_name',
            render: (item, all) => {
                return (
                    <span>{item} {all?.last_name}</span>
                )
            }
        },

        {
            title: 'Telefon raqam',
            key: 'phone_number',
        },
        {
            title: 'Shaxsiy Ball',
            key: 'user_score',
        },
        {
            title: 'Bonus',
            key: 'personal_bonus',
        },
        {
            title: 'Status',
            key: 'user_status',
        },
        {
            title: 'Oylik',
            key: 'salary',
            render: (item) => {
                return (
                    <span>{item?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}uzs</span>
                )
            }
        },
    ]
    function getNextPage() {
        info.forEach((item, i) => {
            if (elem == item) {
                setPageTable(info[i + 1])
            }
        })
    }
    function getBackPage() {
        info.forEach((item, i) => {
            if (elem == item) {
                setPageTable(info[i - 1])
            }
        })
    }
    return (
        <div className={s.UserTabel}>
            <div className={s.UserTabel_table} >
                <div className={s.table_title_wrapper} >
                    {
                        info &&
                        <button style={elem == info[0] ? { display: 'none' } : { display: 'block' }} onClick={getBackPage} ><img src={group} /></button>
                    }
                    <h1 className={s.table_title} >{elem}-Avlod</h1>
                    {
                        info &&
                        <button style={elem == info[info?.length - 1] ? { display: 'none' } : { display: 'block' }} onClick={getNextPage} ><img src={group} /></button>
                    }
                </div>
                <h2 className={s.izdosh} >Izdosh: {data?.length}ta</h2>
                <Table column={column} data={data} />
            </div>
        </div>
    )

}