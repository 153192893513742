import React from "react";
import s from './Table.module.scss'
export default function Table({ column, data, numbers=false }) {
    return (
        <>
            <div  className={s.table} >
                <table  >
                    <tr>
                        {
                            numbers ? <th>No</th> : null
                        }
                        {
                            column?.map((elem, i) => (
                                <th key={i} > {elem.title}</th>
                            ))
                        }
                    </tr>

                    {
                        data?.map((item, i) => (
                            <tr key={i}>
                                {
                                    numbers ? <td>{i+1}</td> : null
                                }
                                {
                                    column?.map((elem, index) => (
                                        <React.Fragment key={index}>
                                            {
                                                !elem.render ?
                                                    <td> {item[elem.key]}</td>
                                                    :
                                                    <td> {elem.render(item[elem.key], item)}</td>

                                            }
                                        </React.Fragment>
                                    ))
                                }
                            </tr>
                        ))
                    }
                </table>
            </div>
            <div className={s.table_mob} >
            <table>
                    {
                        data?.map((item, i) => (
                            <tr key={i}>
                                {
                                    column?.map((elem, index ) => (
                                        <div key={index}>
                                           <th> {elem.title}</th>
                                            {
                                                !elem.render ?
                                                    <td> {item[elem.key]}</td>
                                                    :
                                                    <td> {elem.render(item[elem.key], item)}</td>

                                            }
                                        </div>
                                    ))
                                }
                            </tr>
                        ))
                    }
                </table>
            </div>

        </>

    )
}