import React, { useState } from "react";
import s from "./Transfer.module.scss";
import Table from "../../../components/Table/Table";

import Info from "./info";
import GetAll from "modules/GetAll";
import Loader from "components/Loader";
import { generateDate } from "service/dates";
export default function Transfer() {
  const column = [
    {
      title: "Sana",
      key: "dateTime",
      render:(item)=>{
        return(
          <span>{generateDate(item)}</span>
        )
      }
    },
    {
      title: "ID",
      key: "receiver",
      render:(item)=>{
        return(
          <span>{item?.user_id}</span>
        )
      }
    },
    {
      title: "Ism familiya",
      key: "receiver",
      render:(item)=>{
        return(
          <span>{item?.first_name} {item?.last_name}</span>
        )
      }
    },
    {
      title: "Telefon raqam",
      key: "receiver",
      render:(item)=>{
        return(
          <span>{item?.phone_number}</span>
        )
      }
    },
    {
      title: "Miqdori",
      key: "used",
    },

  ];
  return (
    <GetAll name={'coupons/transfers/'} url={'/users-coupons/'}>
      {({ items }) => {
        if (items.isLoading) return <Loader/>
        return (
          <div className={s.Transfer}>
            <div className="container">
              <Info data={items?.data?.user} />
              <h1 className={s.title}>Kupon transfer tarixi</h1>
              <div className={s.TransferInfo}>
                    <Table column={column} data={items?.data?.transfers} />
              </div>
            </div>
          </div>
        )
      }}
    </GetAll>

  );
}
