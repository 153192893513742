import React, { useState } from "react";
import s from "./Branches.module.scss";
import Card from "../../../components/Card";
import { card } from "../../../assets/db";
import BarChart from "../../../components/BarChart";
import { Data } from "../../../assets/db";
import Button from "../../../components/Button";
import calendar from "../../../assets/icons/Calendar_Event.png";
import GetAll from "../../../modules/GetAll";
import Loader from "../../../components/Loader";
import card1 from "../../../assets/icons/Group 19 (1).png";
import card2 from "../../../assets/icons/Group 19 (2).png";
import card3 from "../../../assets/icons/Group 19 (3).png";
import { Link } from "react-router-dom";
import BarChartBig from "components/BarChartBig";
import { DateInput } from "components/DateInput";
import {
  getLastMonth,
  getLastYear,
  thisMonth,
  getThisYear,
  formatDate,
  generateYear,
} from "../../../service/dates";
import { DatePicker } from "antd";
import { arrayToColumns } from "service/columnGenerator";
import Table from "components/Table/Table";
export default function Branches() {
  const [filter, setFIlter] = useState(getThisYear());
  let filters = [
    { name: "O’tgan yil", val: getLastYear() },
    { name: "Hozirgi yil", val: getThisYear() },
  ];
  function choose(e) {
    setFIlter(e);
  }
  function top() {
    window.scrollTo(0, 0);
  }
  return (
    <GetAll
      name={"Branches_page"}
      url={`v2/manager-warehouses/month/${filter}/`}
    >
      {({ items }) => {
        // function getData(data) {
        //     if (data && data?.data?.warehouses) {
        //         let array = []
        //         Object.keys(data?.data?.warehouses).map((item) => {
        //             array.push({
        //                 title: item,
        //                 inner: '*Lorem ipsum dolor sit amet consectetur. Sit ante curabitur diam lectus laoreet. ',
        //                 info: formatDate(items?.data?.warehouses[item].sales_by_date),
        //                 id: items?.data?.warehouses[item].warehouse?.id
        //             })
        //         })
        //         return array
        //     }
        // }
        // console.log("DATA", items?.data);
        const card = [
          {
            icon: card1,
            title: "Umumiy Savdo",
            inner: items?.data?.total_sales_summa
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
            val: "ball",
          },
          {
            icon: card2,
            title: "Mijozlar soni",
            inner: items?.data?.total_users
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
            val: "ta",
          },
          {
            icon: card3,
            title: "Umumiy Kupon",
            inner: items?.data?.total_coupon
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
            val: "ball",
          },
          // {
          //   icon: card3,
          //   title: "Umumiy ball",
          //   inner: items?.data?.total_ball
          //     .toString()
          //     .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
          //   val: "ta",
          // },
        ];
        if (items.isLoading) return <Loader />;

        const filiallarUlushiPercent = Object.keys(
          items?.data?.warehouses_percentage[0]
        ).map((el) => {
          return {
            title: el,
            key: el,
            render: (val, data) => {
              return typeof val === "number" ? (
                val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
              ) :(
                <div dangerouslySetInnerHTML={{__html:val}}></div>
              )
            },
          };
        });

        const filiallarUlushiSum = Object.keys(
          items?.data?.warehouses[0]
        ).map((el) => {
          return {
            title: el,
            key: el,
            render: (val, data) => {
              return typeof val === "number" ? (
                val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
              ) : (
                <div dangerouslySetInnerHTML={{ __html: val }}></div>
              );
            },
          };
        });
        return (
          <div onLoad={top} className={s.Branches}>
            <div className="container">
              <div className={s.Button_wrapper}>
                <div className={s.filetr_part}>
                  {filters.map((item) => (
                    <button
                      onClick={() => choose(item.val)}
                      className={filter == item.val ? `${s.chosen}` : s.btns}
                    >
                      {item.name}
                    </button>
                  ))}
                </div>
                <DatePicker
                  picker="year"
                  placeholder="Oraliq tanlash"
                  onChange={(date) => choose(generateYear(date))}
                />
                {/* <DateInput change={choose} /> */}
              </div>
              <div className={s.cards_box}>
                {card?.map((elem, i) => (
                  <Card item={elem} key={i} />
                ))}
              </div>
              <Link to={"/BranchAdd"} style={{ textDecoration: "none" }}>
                <Button text={"Filial qo'shish"} />
              </Link>

              <div className={s.Table}>
                <h1>Filiallar savdo ulushi(%)</h1>
                <Table
                  column={filiallarUlushiPercent}
                  data={items?.data?.warehouses_percentage}
                  numbers
                />
              </div>
              <div className={s.Table}>
                <h1>Filiallar savdo ulushi(uzs)</h1>
                <Table
                  column={filiallarUlushiSum}
                  data={items?.data?.warehouses}
                  numbers
                />
              </div>
              {/* <div className={s.charts_box}>
                  <h3 className={s.cards_box_title}>
                    Filiallarlar savdo ulushi
                  </h3>
                  <div className={s.charts_box_inner}>
                    <BarChartBig elem={items?.data?.warehouses} />
                  </div>
                </div> */}
              {/* <div className={s.charts_box} >
                                <h3 className={s.cards_box_title} >Fililallar savdo aylanmalari (uzs) <Link to={'/BranchAdd'} style={{textDecoration:'none'}} ><Button text={"Filial qo'shish"} /></Link>  </h3> 
                                <div className={s.charts_box_inner} >
                                    {
                                        getData(items)?.map((item, i) => (
                                            <BarChart elem={item}/>
                                        ))
                                    }
                                </div>
                            </div> */}
            </div>
          </div>
        );
      }}
    </GetAll>
  );
}
