import React from "react";
import s from "./ViewProduct.module.scss";
import send from "../../assets/icons/send.svg";
import edit from "../../assets/icons/Note_Edit.svg";
import file_edit from "../../assets/icons/File_Edit.svg";
import product_img from "../../assets/imgs/Argenta kalloid 1.png";
import Button from "../Button";
export default function ViewBranch({ done, state, setState, archive }) {
  return (
    <div className={s.container}>
      <div className="">
        <div className={s.addProduct}>
          <h1 className={s.addProduct_title}>
            Foto
            {
              archive ?
                null :
                <span>
                  <div className={s.change_image_wrapper}>
                    <div className={s.change_image} >
                      <label htmlFor="image" >Rasm o'zgartrish</label>
                      <input onChange={(e) => setState({ ...state, photo: e.target.files[0] })} id='image' hidden type='file' />
                    </div>
                  </div>
                </span>
            }
          </h1>
          {
            archive ?
              <div className={s.product_img}>
                <img src={'https://rizonwebappapi.pythonanywhere.com/'+state?.photo} className={s.product_img} alt="" />
              </div>
              :
              <div className={s.product_img}>
                <img src={URL.createObjectURL(state?.photo)} className={s.product_img} alt="" />
              </div>
          }

        </div>
      </div>
    </div>
  );
}
