import React from "react";
import s from './HistorySalesWarehouse.module.scss'
import Table from "components/Table/Table";
import search from 'assets/icons/Search_Magnifying_Glass.png'
import { elements } from "chart.js";
import { Link } from "react-router-dom";
import Button from "components/Button";
import GetAll from "../../../../modules/GetAll";
import Loader from "../../../../components/Loader";
import { generateDate } from "../../../../service/dates";
export default function HistorySalesWarehouse() {
    const column = [
        {
            title: 'Sana',
            key: 'dateTime',
            render:(item)=>{
                return(
                    <span>{generateDate(item)}</span>
                )

            }
        },

        {
            title: 'Dori nomi',
            key: 'product',
            render:(item)=>{
                return(
                    <span>{item?.name}</span>
                )

            }
        },
        {
            title: 'Umumiy Miqdori',
            key: 'amount',
        },
        {
            title: 'Summa',
            key: 'summa',
            render:(item)=>{
                return(
                    <span>{item.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}uzs</span>
                )
            }
        },
        {
            title: 'Mijoz ID',
            key: 'id',
        },
    ]

    return (
        <GetAll name={'employees-warehouse'} url={'/employees-warehouse'}>
            {({ items }) => {
                if (items.isLoading) return <Loader/>
                return (
                    <div className={s.HistorySalesWarehouse}>
                        <div className="container" >
                            <div className={s.HistorySalesWarehouse_table} >
                                <Table column={column} data={items?.data?.products_history} />
                            </div>
                        </div>
                    </div>
                )
            }}
        </GetAll>

    )

}