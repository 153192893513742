import React, { useState } from "react";
import s from './Forgot.module.scss'
import { Button, Checkbox, Form, Input, Select } from 'antd';
import axios from "axios";
import { setToken } from "service/storage/token";
import Loader from "components/Loader";
import { toast } from 'react-toastify'
// import ReactCodeInput from 'react-verification-code-input';
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
export default function Step3() {
    const [loading, setLoading] = useState(false)
    const [role, setRole] = useState(false)
    const navigate = useNavigate()
    const [token, setToken] = useOutletContext()
    const onFinish = (values) => {
        setLoading(true)
        const formdata = new FormData
        Object.keys(values)?.forEach(elem => {
            formdata.append(elem, values[elem])
        });
        axios
            .patch('https://rizonwebappapi.pythonanywhere.com/api/reset-password/', formdata, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                setLoading(false)
                navigate('/login')
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setLoading(false)
            })
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
            <Select style={{ width: 80 }}>
                <Select.Option value="998">+998</Select.Option>
            </Select>
        </Form.Item>
    );
    return (
        <div className={s.Login2}>
            {
                loading ?
                    <div className={s.Login_loader_back} >
                        <Loader />
                    </div>
                    :
                    null
            }
            <Form
                name="basic"
                // labelCol={{
                //     span: 8,
                // }}
                // wrapperCol={{
                //     span: 16,
                // }}
                // style={{
                //     maxWidth: 600,
                // }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                {/* <Form.Item
                    name="username"
                    label="Login yarating"
                    rules={[{ required: true, message: 'Please input your username!' }]}
                >
                    <Input style={{ width: '100%' }} />
                </Form.Item> */}
                <Form.Item
                    name="password"
                    label="Parol yarating"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                    hasFeedback
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    name="confirm_password"
                    label="Parol qayta yozing"
                    dependencies={['password']}
                    // style={{ width: 400 }}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Please confirm your password!',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The new password that you entered do not match!'));
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    wrapperCol={{
                        offset: 8,
                        span: 16,
                    }}
                >
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )

}