import React, { useState } from "react";
import s from './Statistics.module.scss'
import Card from "components/Card";
import { card, cardChart } from 'assets/db'
import BarChart from 'components/BarChart'
import { Data } from 'assets/db'
import PromotionTable from "container/PromotionTable/PromotionTable";
import Button from "components/Button";
import calendar from 'assets/icons/Calendar_Event.png'
import GetAll from 'modules/GetAll'
import Loader from 'components/Loader'
import card1 from 'assets/icons/Group 19 (1).png'
import card2 from 'assets/icons/Group 19 (2).png'
import card3 from 'assets/icons/Group 19 (3).png'
import { getLastDay, getLastMonth, getLastYear, getThisYear, thisMonth } from 'service/dates'
import { usePost } from "hooks";
import { DateInput } from "components/DateInput";
import { arrayToColumns, objectToColumn } from "service/columnGenerator";
import Table from "components/Table/Table";
export default function Statistics() {
    const [filter, setFIlter] = useState(thisMonth())
    let filters = [
      { name: 'O’tgan Oy', val: getLastMonth().slice(- 2) == "00" ? `${getLastYear()}-12` : getLastMonth() },
      { name: 'Hozirgi oy', val: thisMonth() },
      { name: 'O’tgan yil', val: getLastYear() },
      { name: 'Hozirgi yil', val: getThisYear() }
    ]
    function choose(e) {
        setFIlter(e)
    }
    return (
        <GetAll name={'main_page'} url={`v2/manager-statistics/month/${filter}/`} >
            {({ items }) => {
                console.log(items?.data);
                // function getData(data) {
                //     if (data && data) {
                //         let array = []
                //         data?.map((item) => {
                //             array.push({
                //                 title: item?.warehouse?.name,
                //                 inner: '*Lorem ipsum dolor sit amet consectetur. Sit ante curabitur diam lectus laoreet. ',
                //                 info: item?.warehouse_sales,
                //                 id: item?.warehouse?.id
                //             })
                //         })
                //         return array
                //     }
                // }
                // const cardChart = [
                //     {
                //         title: 'Daromad grafigi (uzs)',
                //         inner: '*Lorem ipsum dolor sit amet consectetur. Sit ante curabitur diam lectus laoreet. ',
                //         info: items?.data?.users_salary
                //     },
                //     {
                //         title: 'Mijozlar grafigi (dona)',
                //         inner: '*Lorem ipsum dolor sit amet consectetur. Sit ante curabitur diam lectus laoreet. ',
                //         info: items?.data?.month_came_users
                //     },
                //     {
                //         title: 'Mahsulot sotuv ulushi',
                //         inner: '*Lorem ipsum dolor sit amet consectetur. Sit ante curabitur diam lectus laoreet. ',
                //         info: items?.data?.month_product_sales_data
                //     },
                //     {
                //         title: 'Umumiy maosh grafigi (uzs)   ',
                //         inner: '',
                //         info: items?.data?.month_sales_data
                //     },
                // ]
                const card = [
                    {
                        icon: card1,
                        title: 'Umumiy Savdo',
                        inner: items?.data?.total_sales_summa.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
                        val: "ball"
                    },
                    {
                        icon: card2,
                        title: 'Mijozlar soni',
                        inner: items?.data?.total_users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
                        val: 'ta'
                    },
                    {
                        icon: card3,
                        title: 'Umumiy Kupon',
                        inner: items?.data?.total_coupon.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
                        val: 'ball'
                    },
                    // {
                    //     icon: card3,
                    //     title: 'Umumiy ball',
                    //     inner: items?.data?.total_ball.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
                    //     val: 'ta'
                    // },
                ]
                if (items.isLoading) return <Loader />
                const mahsulotSotuvi = arrayToColumns(
                  items?.data?.product_sales_data
                );
                const umumiyMaosh = arrayToColumns(
                  items?.data?.salary_payer_warehouses
                );
                const daromadGrafigi = objectToColumn(items?.data?.sales_data);
                const mijozlarGrafigi = objectToColumn(
                  items?.data?.users_statistics
                );
                const filiallarUlushiSum = Object.keys(
                  items?.data?.warehouses_data[0]
                ).map((el) => {
                  return {
                    title: el,
                    key: el,
                    render: (val, data) => {
                      return typeof val === "number" ? (
                        val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                      ) : (
                        <div dangerouslySetInnerHTML={{ __html: val }}></div>
                      );
                    },
                  };
                });
                return (
                  <div className={s.Statistics}>
                    <div className="container">
                      <div className={s.Button_wrapper}>
                        <div className={s.filetr_part}>
                          {filters.map((item) => (
                            <button
                              onClick={() => choose(item.val)}
                              className={
                                filter == item.val ? `${s.chosen}` : s.btns
                              }
                            >
                              {item.name}
                            </button>
                          ))}
                        </div>
                        <DateInput change={choose} />
                      </div>
                      <div className={s.cards_box}>
                        {items?.data
                          ? card?.map((elem, i) => <Card item={elem} key={i} />)
                          : null}
                      </div>
                      <div className={s.Table}>
                        <h1>Daromad grafigi(uzs)</h1>
                        <Table
                          column={daromadGrafigi}
                          data={[items?.data?.sales_data]}
                        />
                      </div>
                      <div className={s.Table}>
                        <h1>Mahsulot sotuv ulushi</h1>
                        <Table
                          column={mahsulotSotuvi}
                          data={items?.data?.product_sales_data}
                          numbers
                        />
                      </div>
                      <div className={s.Table}>
                        <h1>Umumiy maosh grafigi (uzs)</h1>
                        <Table
                          column={umumiyMaosh}
                          data={items?.data?.salary_payer_warehouses}
                          numbers
                        />
                      </div>
                      <div className={s.Table}>
                        <h1>Mijozlar grafigi (dona)</h1>
                        <Table
                          column={mijozlarGrafigi}
                          data={[items?.data?.users_statistics]}
                        />
                      </div>
                      <div className={s.Table}>
                        <h1>Filiallar savdo ulushi(uzs)</h1>
                        <Table
                          column={filiallarUlushiSum}
                          data={items?.data?.warehouses_data}
                          numbers
                        />
                      </div>
                      {/* <div className={s.charts_box} >
                                {
                                    items?.data ? cardChart?.map((elem, i) => (
                                        <BarChart elem={elem} />
                                    ))
                                        :
                                        null
                                }
                            </div> */}
                      {/* <div className={s.charts_box} >
                                {
                                    getData(items?.data?.warehouses_sales_data)?.map((elem, i) => (
                                        <BarChart elem={elem} />
                                    ))
                                }
                            </div> */}
                    </div>
                  </div>
                );
            }}
        </GetAll>
    )
}