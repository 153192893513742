import s from './profile.module.scss'
import UserInfo from './components/userInfo'
import BranchInfo from './components/branchInfo'
import GetAll from '../../../modules/GetAll'
import Loader from '../../../components/Loader'

const Profile = () => {
  return (
    <GetAll name={"orders"} url={"/employees-profile/"}>
      {({items}) => {
        if (items?.isLoading) return <Loader/>
        return (
          <div className={s.Profile}>
            <div className="container">
              <UserInfo {...items}/>
              <BranchInfo data={items?.data?.warehouse}/>
            </div>
          </div>
        );
      }}
    </GetAll>
  );
}

export default Profile