import React from "react";
import s from './Promotion.module.scss'
import Table from "components/Table/Table";
import search from 'assets/icons/Search_Magnifying_Glass.png'
import { elements } from "chart.js";
import { Link, useParams } from "react-router-dom";
import Button from "components/Button";
import GetAll from "modules/GetAll";
import Loader from "components/Loader";
import { generateDate } from "service/dates";
export default function History() {
    const { id } = useParams()
    const column = [
        {
            title: 'Sana',
            key: 'dateTime',
            render:(item)=>(generateDate(item))
        },
        {
            title: 'Tovar nomi',
            key: 'promotion',
            render:(item)=>(item?.name)
        },
        {
            title: 'Soni',
            key: 'amount',
        },
        {
            title: 'Qiymati',
            key: 'coupon',
            render:(item)=>{
                return(
                    <span>{item} RV</span>
                )
            }
        },

    ]
    const data = [
        {
            date: '11/11/2022',
            salary: '7 300 000uzs',
            late: '2 000 000uzs',
            paying: '5 300 000uzs',
            pay: 1,
            id: 2
        },
        {
            date: '11/11/2022',
            salary: '7 300 000uzs',
            late: '2 000 000uzs',
            paying: '5 300 000uzs',
            pay: 1,
            id: 2
        },
        {
            date: '11/11/2022',
            salary: '7 300 000uzs',
            late: '2 000 000uzs',
            paying: '5 300 000uzs',
            pay: 1,
            id: 2
        },
        {
            date: '11/11/2022',
            salary: '7 300 000uzs',
            late: '2 000 000uzs',
            paying: '5 300 000uzs',
            pay: 1,
            id: 2
        },
    ]
    return (
        <GetAll name={'history-user'} url={`/users-promotions/purchases/`} >
            {({ items }) => {
                console.log(items);
                if (items.isLoading) return <Loader/>
                return (
                    <div className={s.HistorySale}>
                        <div className="container" >
                            <div className={s.HistorySale_table} >
                                <Table column={column} data={items.data.purchases} />
                            </div>
                        </div>
                    </div>
                )
            }}
        </GetAll>
    )
}