import React, { useState } from "react";
import Employee from "../../../container/Employee/Employee";
import s from './Employeeinner.module.scss'
import GetAll from "../../../modules/GetAll";
import { useParams } from "react-router-dom";
import Loader from "../../../components/Loader";
import SalaryPay from "../../../components/SalaryPay";
export default function Employeeinner({ }) {
    const {empoyId} =useParams()
    const [salary,setSalary]=useState(false)
    return (
        <GetAll name="branch_empoyee_inner" url={`/employees/${empoyId}/`} >
            {({ items }) => {
                if (items?.isLoading) return <Loader/>
                return (
                    <div className={s.Employeeinner} >
                        <div  className="container" >
                            <Employee setSalary={setSalary} data={items?.data} />
                        </div>
                        {
                            salary ? <SalaryPay setSalary={setSalary} data={items?.data} /> : null
                        }
                    </div>
                )
            }}
        </GetAll>

    )

}