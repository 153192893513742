import React, { useState } from "react";
import "./Job.css";
import { motion } from "framer-motion";
import team from '../public/images/team1.png'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useEffect } from "react";
import team1 from '../public/images/bg1.png'
import team2 from '../public/images/h4.png'
function Job({ data }) {
  // const { i18n } = useTranslation();
  const [datas, setDatas] = useState()
  useEffect(()=>{
    setDatas(data)
  },[data])
  const [numm, setNumm] = useState(3);
  const [numm1, setNumm1] = useState(2);
  const [numm2, setNumm2] = useState(1);
  const [numm3, setNumm3] = useState(0);
  const toLeft = () => {
    setNumm((numm) => (numm === 0 ? data.length - 1 : numm - 1));
    setNumm1((numm1) => (numm1 === 0 ? data.length - 1 : numm1 - 1));
    setNumm2((numm2) => (numm2 === 0 ? data.length - 1 : numm2 - 1));
    setNumm3((numm3) => (numm3 === 0 ? data.length - 1 : numm3 - 1));
  };
  const toRight = () => {
    let newData = []
    newData.push(...datas)
    let arr = newData.splice(0, 1)
    newData.push(...arr)
    setDatas(newData)
  };
  console.log(datas); 
  return (
    <>
      <div className="Job_flex">
        <div className="Job_text">
          <h1>{datas ? datas[0]?.full_name : null} </h1>
          <h4>{datas ? datas[0]?.job: null}</h4>
          <p>
            {datas ? datas[0]?.about: null}
          </p>
          <div className="icon__arrows">
            <div className="icon__arrow">
              <ArrowBackIcon />
            </div>
            <div onClick={toRight} className="icon__arrow"><ArrowForwardIcon /></div>
          </div>
        </div>
        <div className="Job_picture">
          {
            datas?.map((elem, i) => (
              <img className={`Sliderimages${i}`} style={{ display: 'none' }} src={'https://rizonwebappapi.pythonanywhere.com/'+elem.photo} />
            ))
          }
          {/* <motion.div
            initial={{ x: 300 }}
            animate={{ x: 0 }}
            exit={{ x: "-800px" }}
            transition={{
              opacity: { ease: "linear" },
              layout: { duration: 0.3 },
            }}
          >
            <img
              src={team}
              alt=""
              className="job_img first_img"
            />
          </motion.div>
          <motion.div
            layout
            animate={{ opacity: 0.5, x: 0 }}
            exit={{ x: 800 }}
            transition={{
              opacity: { ease: "linear" },
              layout: { duration: 0.3 },
            }}
          >
            <img
              src={team}
              alt=""
              className="job_img second_img"
            />
          </motion.div>
          <motion.img
            layout
            initial={{ x: "100%" }}
            animate={{opacity: 0.5, x: 0 }}
            transition={{
              opacity: { ease: "linear" },
              layout: { duration: 0.3 },
            }}
            src={team}
            alt=""
            className="job_img third_img"
          /> */}
          <img src={team} alt="" className="job_img fourth_img" />
        </div>
      </div>

    </>
  );
}

export default Job;
