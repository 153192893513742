import Table from 'components/Table/Table'
import s from './OverallIncome.module.scss'

const Index = ({history}) => {
  console.log(history);
  const data = [
  {
    fullName: `${history?.medic?.user?.first_name} ${history?.medic?.user?.last_name}`,
    product:history?.products,
    salary:history?.paid
  }
]

  return (
    <div className={s.Table_wrapper}>
      <h2>{history?.date}</h2>
      <Table column={column()} data={data} />
    </div>
  );
}

export default Index

function column() {
    return [
      {
        title: "Ism Familiya",
        key: "fullName",
      },
      {
        title: "Biriktirilgan mahsulot",
        key: "product",
        render:(value)=>{
            return (
              <div className={s.Products_wrapper}>
                {value?.map((item, i) => (
                  <p className={s.products} key={i}>{item?.name},</p>
                ))}
              </div>
            );
        }
      },
      {
        title: "Oylik",
        key: "salary",
        render: (value)=>{
            return `${value}uzs`
        }
      },
    ];
}

