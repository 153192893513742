import React from "react";
import "./CardFilter.css";
import Card from "../Card/Card";
import Data from "../Data";

function CardFilter({data}) {
  const { info } = Data();

  return (
    <div className="card-filter">
      <div className="card-list">
        <ul>
          <li className="active">Imunitet</li>
          <li>Erkaklar</li>
          <li>Ayollar</li>
          <li>Bolalar</li>
          <li>Keksalar</li>
        </ul>
      </div>
      <div className="card-main">
        {data?.map((item) => {
          return (
            <Card
              id={item.id}
              title={item.name}
              img={'https://rizonwebappapi.pythonanywhere.com/'+item.photo_link}
              price={item.extraPrice}
            />
          );
        })}
      </div>
    </div>
  );
}

export default CardFilter;
