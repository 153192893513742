import React from 'react'
import './Header.css'
import bg from '../../public/images/bg1.png'

function Header({ product,prod }) {
  // console.log('product', prod?.about);
	return (
    <div className="header container2">
      <div className="header_background">
        <img
          src={"https://rizonwebappapi.pythonanywhere.com/" + prod?.photo_link}
          alt=""
        />
        {/* <img src={photo} alt="" /> */}
        <div className="header_backgroundCards">
          {prod?.about
            ?.split(".")
            .splice(0, 3)
            .map((elem, i) => (
              <div className="header_backgroundCard" key={i}>
                {/* <h1>*Ichak yo’llari uchun foydali</h1> */}
                <p>{elem}</p>
              </div>
            ))}
        </div>
      </div>
      {product ? null : (
        <div className="header_text">
          <h1>Imunitetni oshiruvchi tabiiy sirop.</h1>
          {/* <button>Batafsil</button> */}
        </div>
      )}
    </div>
  );
}

export default Header