import s from './Modal.module.scss'
import CloseIcon from 'assets/icons/close.png'

const Modal = ({children, setModal, modal}) => {
  return (
    <div
      id="modal_wrapper"
      onClick={(e) => {
        if (e.target.id === "modal_wrapper")
          setModal({ data: null, isOpen: false });
      }}
      className={s.Modal_wrapper}
    >
      <div className={s.Modal}>
        <div className={s.Icon_wrapper}>
          <div onClick={() => setModal({ data: null, isOpen: false })}>
            <img src={CloseIcon} alt="" />
          </div>
        </div>
        {children(modal?.data)}
      </div>
    </div>
  );
}

export default Modal