import React from "react";
import s from './HistorySale.module.scss'
import Table from "../../../../components/Table/Table";
import search from '../../../../assets/icons/Search_Magnifying_Glass.png'
import { elements } from "chart.js";
import { Link, useParams } from "react-router-dom";
import Button from "../../../../components/Button";
import GetAll from "modules/GetAll";
import Loader from "components/Loader";
import { generateDate } from "service/dates";
export default function HistorySale() {
    const { id } = useParams()
    const column = [
        {
            title: 'Sana',
            key: 'dateTime',
            render:(item)=>{
                return(
                    <span>{generateDate(item)}</span>
                )
            }
        },
        {
            title: 'Dori nomi',
            key: 'product',
            render:(item)=>{
                return(
                    <span>{item?.name}</span>
                )
            }
        },
        {
            title: 'Miqdori',
            key: 'amount',
        },
        {
            title: 'Summa',
            key: 'summa',
            render:(item)=>{
                return(
                    <span>{item?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}uzs</span>
                )
            }
        },
    ]
    return (
        <GetAll name={'history-user'} url={`admin-users/${id}/sales/`} >
            {({ items }) => {
                if (items.isLoading) return <Loader/>
                return (
                    <div className={s.HistorySale}>
                        <div className="container" >
                            <div className={s.HistorySale_table} >
                                <Table column={column} data={items?.data?.sales_history} />
                            </div>
                        </div>
                    </div>
                )
            }}
        </GetAll>
    )
}