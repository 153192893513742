import React from "react";
import s from './HistorySale.module.scss'
import Table from "components/Table/Table";
import search from 'assets/icons/Search_Magnifying_Glass.png'
import { elements } from "chart.js";
import { Link, useParams } from "react-router-dom";
import Button from "components/Button";
import GetAll from "modules/GetAll";
import Loader from "components/Loader";
export default function HistorySalaryEmp() {
    const { id } = useParams()
    const column = [
      {
        title: "F.I.Sh.",
        render: (val, data) => {
          return `${data.employee?.user?.first_name} ${data.employee?.user?.last_name}`;
        },
      },
      {
        title: "To’langan maosh",
        key: "paid",
        render: (item) => {
          return (
            <span>
              {item?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}uzs
            </span>
          );
        },
      },
      {
        title: "Sana",
        key: "date",
      },
    ];
    
    return (
        <GetAll name={'history-user'} url={`/employees-salary/payments/${id}`} >
            {({ items }) => {
                if (items.isLoading) return <Loader/>
                // console.log(items?.data);
                return (
                  <div className={s.HistorySale}>
                    <div className="container">
                      <h2>
                        {`${items?.data?.payments[0].employee?.user?.first_name}
                         ${items?.data?.payments[0].employee?.user?.last_name}`}
                      </h2>
                      <div className={s.HistorySale_table}>
                        <Table column={column} data={items?.data?.payments} numbers/>
                      </div>
                    </div>
                  </div>
                );
            }}
        </GetAll>
    )
}