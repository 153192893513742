import React, { useState } from "react";
import s from "./Product.module.scss";
import Button from "../Button";
import ShoppingCart from "../../assets/icons/Shopping_Cart_01.png";
import productImg from "../../assets/imgs/Argenta kalloid 1.png";
import canada from "../../assets/icons/canada.png";
import icon from '../../assets/icons/Shopping_Cart_01.png'
import { useGetAll, usePost } from "hooks";
import { toast } from 'react-toastify'
import Loader from "components/Loader";
function CustomProduct({ item, product, x, medik, setOrder }) {
  const [state, setState] = useState({})
  function colculate(e) {
    setState({ ...state, amount: e, total_sum: item.price * e })
  }
  const { data } = useGetAll({
    url: '/warehouses/',
  })
  const { mutate, isLoading } = usePost()
  function postOprder(params) {
    const formdata = new FormData
    formdata.append('product', item.id)
    formdata.append('amount', state.amount)
    formdata.append('warehouse', state.warehouse)
    mutate({
      url: '/users/product/order/',
      data: formdata,
      method: 'post',
      onSuccess: success,
      onError: error
    })

  }
  function success(params) {
    toast.success("Muvoffaqiyatli buyurtma berildi!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  function error() {
    toast.error("Buyurtma qabul qilinmadi!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }

  return (
    <div className={s.Product}>
      {
        isLoading ?
          <div className={s.loader_back} >
            <Loader />
          </div>
          :
          null
      }
      <div className={s.product_img}>
        <img
          src={`https://rizonwebappapi.pythonanywhere.com/${item?.photo_link}`}
          alt=""
        />
      </div>
      {
        x ?
          <img onClick={() => setOrder(false)} className={s.cencel} src={x} />
          : null
      }
      <div className={s.product_info}>
      <img
      className={s.mob_img}
          src={`https://rizonwebappapi.pythonanywhere.com/${item?.photo_link}`}
          alt=""
        />
        <h2>{item?.name}</h2>
        <h2 className={s.price} >{item?.price}uzs</h2>
        <h3>Batafsil</h3>
        <p>{item?.about}</p>
        <h4 className={s.author}>
          Ishlab chiqaruvchi: <span>{item?.manufacturer}</span>
        </h4>
        <div className={s.product_form}>
          <label htmlFor="">
            <h3>Soni</h3>
            <input type="number" onChange={(e) => colculate(e.target.value)} />
          </label>
          <label htmlFor="">
            <h3>Umumiy summa</h3>
            <input
              type="text"
              name=""
              id=""
              disabled
              value={state?.total_sum}
            />
          </label>
          <label htmlFor="">
            <h3>Filial tanlang</h3>
            <select onChange={(e) => setState({ ...state, warehouse: e.target.value })} >
              <option  >Filial</option>
              {
                data?.map((elem) => (
                  <option value={elem?.id} >{elem?.name}</option>
                ))
              }
            </select>
          </label>
        </div>
        <Button onClick={state?.amount && state?.warehouse ? () => postOprder() : null} disabled={state?.amount && state?.warehouse ? false : true} text={"Buyurtma berish"} icon={ShoppingCart} />
      </div>
    </div>
  );
}

export default CustomProduct;
