import React, { useState } from "react";
import s from "./Login.module.scss";
import { Button, Checkbox, Form, Input, Select } from "antd";
import axios from "axios";
import { setToken } from "service/storage/token";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import Loader from "components/Loader";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Back from "../../assets/imgs/login_image.png";
export default function Login() {
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState(false);
  const navigate = useNavigate();
  const onFinish = (values) => {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("userinput", values?.username);
    formdata.append("password", values?.password);
    axios
      .post("https://rizonwebappapi.pythonanywhere.com/api/login/", formdata)
      .then((res) => {
        setToken(res.data.access);
        window.localStorage.setItem("role", res.data.user_role);
        setLoading(false);
        navigate("/");
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Login yoki parol noto'g'ri!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const handleChange = (value) => {
    setRole(value);
  };
  return (
    <div className={s.Login}>
      {loading ? (
        <div className={s.Login_loader_back}>
          <Loader />
        </div>
      ) : null}

      <Form
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Form.Item
          name="username"
          rules={[{ required: true, message: "Please input your Username!" }]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Username"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Please input your Password!" }]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <Link
            to={"/ForgotPassword/step1"}
            className="login-form-forgot"
            href=""
          >
            Forgot password
          </Link>
        </Form.Item>

        <Form.Item>
          <Button
            style={{ marginRight: "20px" }}
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Log in
          </Button>
          Or{" "}
          <Link
            to={"/Registration/Step1"}
            style={{ marginLeft: "20px" }}
            href=""
          >
            register now!
          </Link>
        </Form.Item>
      </Form>
      <img className={s.login_image} src={Back} />
    </div>
  );
}
