import React from "react";
import s from './Generations.module.scss'
import Table from "../../components/Table/Table";
import plus from '../../assets/icons/Add_Plus_Circlenew.svg'
import Button from "../../components/Button";
import { Link } from "react-router-dom";

export default function Generations({ data,}) {
    const column = [
        {
            title: 'ID',
            key: 'user_id',
        },
        {
            title: 'Ism va familiya',
            key: 'first_name',
            render:(item,all)=>{
                return(
                    <span>{all.last_name} {item} </span>
                )
            }
        },
        {
            title: 'Telefon raqam',
            key: 'phone_number',
        },
        {
            title: 'Status',
            key: 'user_status',
        },
        {
            title: 'Shaxsiy aylanma',
            key: 'user_score',
        },
        {
            title: 'Ustozlik bonusi',
            key: 'forMentorship',
        },
        {
            title: 'Jamoaviy aylanma',
            key: 'user_tree_score',
        },
        {
            title: 'Jamoaviy bonus',
            key: 'collective_bonus_amount',
            render:(item)=>{
                return <span>{item}uzs</span>
            }
        },
        {
            title: 'Extra bonus',
            key: 'extra_bonus',
            render:(item)=>{
                return <span>{item}uzs</span>
            }
        },
        {
            title: 'Kupon',
            key: 'coupon',
            
        },
        {
            title: 'Oylik',
            key: 'salary',
            render:(item)=>{
                return <span>{item}uzs</span>
            }
        },
        {
            title: 'To’landi',
            key: 'paid',
            render:(item)=>{
                return <span>{item}uzs</span>
            }
        },
        {
            title: 'Qarz',
            key: '',
            render:(item)=>{
                return <span>{item}uzs</span>
            }
        },
        {
            title: 'Filial',
            key: 'warehouse',
            render:(item)=>{
                return <span>{item?.name}</span>
            }
        },
        {
            // title: 'Filial',
            key: 'id',
            render:(item)=>{
                return <Link to={`/Customers/${item}`} ><Button text="Batafsil"/></Link> 
            }
        },
    ]

    return (
      <div className={s.Generations}>
        <div style={{ overflowX: "auto" }} className={s.Generations_table}>
          <Table numbers column={column} data={data} />
        </div>
      </div>
    );

}