import axios from 'axios'
const http = axios.create({
    baseURL: 'https://rizonwebappapi.pythonanywhere.com/api/'
})
http.interceptors.request.use(
    request => {
        return request;
    },
    error => {
        return Promise.reject(error);
    }
);
http.interceptors.response.use(
    response => {
        return response;
    },
    error => {
            return Promise.reject(error);
    }
);
export default http;