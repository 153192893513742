import React, { useState } from "react";
import s from './History.module.scss'
import Table from "../../components/Table/Table";
import add from '../../assets/icons/Add_Plus_Circle.svg'
import history from '../../assets/icons/Archive.svg'
import send from '../../assets/icons/Share_iOS_Export.svg'
import Button from "../../components/Button";
import { generateDate } from "../../service/dates";
import AdminSallProduct from "components/AdminSallProduct";
import images from '../../assets/icons/Arrow_Undo_Up_Left.svg'
import SallProductAdmin from "components/SallProductAdmin";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { arrayToColumns } from "service/columnGenerator";
export default function History({ datas,minishop }) {
    const {id} = useParams()
    const btn = [
        {
            text: 'Nasiya to’ldirish',
            icon: add
        },
        {
            text: 'Tarixni ko’rish',
            icon: history
        },
        {
            text: 'Mahsulot yuborish',
            icon: send
        },
    ]
    
    function createObj(info) {
        let array = []
        info?.forEach(elem => {
            array.push({
                amount: elem.amount,
                date: elem.dateTime,
                name: elem.product.name,
                summa: elem.summa,
                debt: elem.debt,
                paid: elem.paid,
                id:elem.id

            })
        });
        return array
    }
    if(!datas) return
    const column = arrayToColumns(datas);
    return (
      <div className={s.History}>
        <div className={s.History_top}>
          <h1 className={s.History_title}>
            Tanlangan oydagi mahsulot miqdori
          </h1>
          <div className={s.History_top_button}>
            <SallProductAdmin undo={true} text={"Qaytib olish"} icon={images} />
            <Link
              to={minishop ? `/MiniShop/view/${id}` : `/Branches/history/${id}`}
              style={{ textDecoration: "none" }}
            >
              <Button text={"Tarixni ko’rish"} icon={history} />
            </Link>
            <SallProductAdmin text={"Mahsulot yuborish"} icon={send} />
          </div>
        </div>
        <div className={s.History_table}>
          <Table column={column} data={datas} numbers/>
        </div>
      </div>
    );

}