import React, { useEffect, useState } from "react";
import s from './promotion.module.scss'
import Button from "components/Button";
import PromotionCard from "components/PromotionCard";
import PromotionItem from "components/PromotionItem";
import PromotionCardMijoz from "components/PromotionCardMijoz";
import { Pagination } from 'antd';
export default function PromotionInnerMijoz({ data, reFatch ,title}) {
    const [add, setAdd] = useState(false)
    const [datas, setDatas] = useState()
    const [page,setPage]=useState(1)
    useEffect(()=>{
        setDatas(data.slice((page-1)*10,page*10))
    },[data])
    const onChange =(num)=>{
        setPage(num)
        setDatas(data.slice((num-1)*10,num*10))
    }
    return (
        <div className={s.Promotion} >
            <div className={s.Promotion_header} >
                <div className={s.Promotion_header_title} >
                    <h1>Oraliq | {title}</h1>
                </div>
                <div className={s.Promotion_header_btn} >
                    {/* <Button onClick={()=>setAdd(true)} text={'Sovrin qo’shish'} /> */}
                    <p>0</p>
                    -
                    <p>{data?.length}</p>
                </div>
            </div>
            <div className={s.PromotionInner_main} >
                {
                    datas?.map((elem) => (
                        <PromotionCardMijoz elem={elem} reFatch={reFatch} />
                    ))
                }
            </div>
            {/* {
                add &&  <div className={s.Modal_Wrapper}  ><PromotionItem reFatch={reFatch} setAdd={setAdd} /></div> 
            } */}
            <Pagination defaultCurrent={1} onChange={onChange}  total={data?.length} />
        </div>
    )

}