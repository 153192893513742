import React, { useState } from "react";
import card1 from 'assets/icons/Group 19 (1).png';
import card2 from 'assets/icons/Group 19 (2).png';
import card3 from 'assets/icons/Group 19 (3).png';
import Card from "components/Card";
import HistorySalaryPayment from "../../../container/HistorySalaryPayment";
import HistoryUser from "../../../container/HistoryUser";
import {  getLastMonth, getLastYear ,getThisYear,thisMonth} from 'service/dates';
import s from './Statistics.module.scss';
import GetAll from "../../../modules/GetAll";
import calendar from '../../../assets/icons/Calendar_Event.png'
import Button from "../../../components/Button";
import Loader from "../../../components/Loader";
export default function Statistics() {
    const [filter, setFIlter] = useState(thisMonth())
    let filters = [{ name: 'O’tgan Oy', val: getLastMonth() }, { name: 'Hozirgi oy', val: thisMonth() }, { name: 'Hozirgi yil', val: getThisYear() }, { name: 'O’tgan yil', val: getLastYear() }]
    function choose(e) {
        setFIlter(e)
    }
    const [moreSalary, setMoreSalary] = useState(false)
    const [moreSalarypay, setMoreSalarypay] = useState(false)
    return (
        <GetAll name={'employees-statistics'} url={`/employees-statistics/month/${filter}`}>
            {({ items }) => {
                const card = [
                    {
                        icon: card1,
                        title: "Umumiy Savdo",
                        inner: items?.data?.total_sales_summa
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
                        val: "ball",
                    },
                    {
                        icon: card2,
                        title: "Mijozlar soni",
                        inner: items?.data?.total_users
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
                        val: "ta",
                    },
                    {
                        icon: card3,
                        title: "Umumiy ball",
                        inner: items?.data?.total_coupon
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
                        val: "ball",
                    },
                ]
                if (items.isLoading) return <Loader />
                return (
                    <div className={s.Statistics} >
                        <div className="container" >
                            <div className={s.Button_wrapper} >
                                <div className={s.filetr_part} >
                                    {
                                        filters.map((item) => (
                                            <button onClick={() => choose(item.val)} className={filter == item.val ? `${s.chosen}` : s.btns} >{item.name}</button>
                                        ))
                                    }
                                </div>
                                <Button text={'Oraliq tanlash'} icon={calendar} />
                            </div>
                            <div className={s.cards_box} >
                                {
                                    card?.map((elem, i) => (
                                        <Card item={elem} key={i} />
                                    ))
                                }
                            </div>
                            <HistorySalaryPayment setMoreSalarypay={setMoreSalarypay} moreSalarypay={moreSalarypay} data={moreSalarypay ? items?.data?.paid_users_salary : items?.data?.paid_users_salary?.slice(0,6)} />
                            <HistoryUser setMoreSalary={setMoreSalary} moreSalary={moreSalary} data={moreSalary ? items?.data?.sales_data : items?.data?.sales_data?.slice(0, 6)} />
                        </div>
                    </div>
                )
            }}
        </GetAll>

    )
}