import React, { useState } from "react";
import s from './ListofProducts.module.scss'
import Table from "../../components/Table/Table";
import plus from '../../assets/icons/Add_Plus_Circlenew.svg'
import history from '../../assets/icons/Archive.svg'
import add from '../../assets/icons/Add_Plus_Circle.svg'
import puzzle from '../../assets/icons/Puzzle.png'
import send from '../../assets/icons/Share_iOS_Export.svg'
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import { generateDate } from "service/dates";
import DiscountModal from "components/DiscountModal";
export default function ListofProducts({ data }) {
    const [open, setOpen] = useState(false)
    const btn = [
        {
            text: 'Aksiya',
            icon: puzzle
        },
        {
            text: "Mahsulot qo'shish",
            icon: add
        },
    ]
    const column = [
        {
            title: 'Nomi',
            key: 'name',
        },
        {
            title: 'Ishlab Chiqaruvchi',
            key: 'manufacturer',

        },
        {
            title: 'Ichki narx',
            key: 'price',
            render: (item) => {
                return (
                    <span>{item.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}uzs</span>
                )
            }
        },
        {
            title: 'Tashqi Narxi',
            key: 'extraPrice',
            render: (item) => {
                return (
                    <span>{item.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}uzs</span>
                )
            }
        },
        {
            title: 'Batafsil',
            key: 'id',
            render: (item) => (<Link style={{textDecoration:'none'}} to={`/Product/viewProduct/${item}`} ><Button text={'Batafsil'} /></Link>)
        },

    ]

    return (
        <div className={s.ListofProducts}>
            <div className={s.ListofProducts_top_button} >
                <Button onClick={() => setOpen(true)} text={btn[0].text} icon={btn[0].icon} />
                <Link to='/Product/AddNew' className={s.Link_style} ><Button text={btn[1].text} icon={btn[1].icon} /></Link>
            </div>
            {
                open &&
                <div className={s.modal_back} >
                    <DiscountModal setOpen={setOpen} />
                </div>
            }
            <div className={s.ListofProducts_table} >
                <Table numbers column={column} data={data} />
            </div>
        </div>
    )

}