import React from "react";
import s from './HistorySalaryPayment.module.scss'
import Table from "../../components/Table/Table";
import search from '../../assets/icons/Search_Magnifying_Glass.png'
import { elements } from "chart.js";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import { generateDate } from "../../service/dates";
export default function HistorySalaryPayment({data,setMoreSalarypay ,moreSalarypay}) {
    const column = [
        {
            title: 'Ism Familiya',
            key: 'user',
            render: (item) => (
                <span>{item.first_name + ' ' +item?.last_name}</span>
            )
        },
        {
            title: 'Sana',
            key: 'paymentDate',
            render:(item)=>{
                return(
                    <span>{generateDate(item)}</span>
                )
            }
        },
        {
            title: 'To’langan maosh',
            key: 'paid',
            render:(item)=>{
                return(
                    <span>{item.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + 'uzs'}</span>
                )
            }
        },

        {
            title: 'Mijoz ID',
            key: 'user',
            render:(item)=>{
                return(
                    <span>{item?.user_id}</span>
                )
            }
        },
    ]
    return (
        <div className={s.HistorySalaryPayment}>
            <div className={s.HistorySalaryPayment_top} >
                <h1 className={s.HistorySalaryPayment_title} >Mijozlarga maosh to’lash tarixi</h1>
                <Button onClick={()=>setMoreSalarypay(!moreSalarypay)} text={moreSalarypay ? 'Qisqacha' : 'Batafsil'} />
            </div>
            <div className={s.HistorySalaryPayment_table} >
                <Table column={column} data={data} />
            </div>
        </div>
    )

}