import React from 'react'
import './Footer.css'
import footerLogo from '../../../../assets/icons/newone.png'
import Instagam from '../../public/images/instagram.png'
import telegram from '../../public/images/telegram.png'
function Footer() {
	return (
		<div className='footer '>
			<div className="footer-cards container">
				<div className="footer-card">
					<img src={footerLogo} alt="" />
					<p>Lorem ipsum dolor sit amet consectetur. In elementum morbi amet diam vel nisl amet vel. Eu tellus cras scelerisque rhoncus malesuada cras id at. In mattis ac adipiscing aliquam massa pellentesque donec laoreet.</p>
				</div>
				<div className="footer-card-second">
					<h1>Xarita</h1>
					<li>Asosiy</li>
					<li>Biz haqimizda</li>
					<li>Mahsulotlar</li>
					<li>Sotib olish</li>
					<li>Tavsiyalar</li>
					<li>Kareyra</li>
				</div>
				<div className="footer-card-second">
					<h1>Maxfiylik siyosati</h1>
					<li>Yordam</li>
					<li>Eng ko’p so’ralgan savollar</li>
					<li>Foydalanish shartlari</li>
					<li>Sotib olish</li>
					<li>Tavsiyalar</li>
					<li>Kareyra</li>
				</div>
				<div className="footer-card-third">
					<h1>Ijtimoiy tarmoqlar</h1>
					<div className="footer-card-docs">
						<a href='https://www.instagram.com/rizonuz/' target='_blank' ><img className='scial_media_icon' src={Instagam} /></a>
						<p>Instagram</p>
					</div>
					<div className="footer-card-docs">
						<a href='https://t.me/rizonuz' target='_blank' ><img className='scial_media_icon' src={telegram} /></a>
						<p>Telegram</p>
					</div>
					<h1>Aloqa markazi</h1>
					<h1>+998 99 604 30 00</h1>
				</div>
			</div>
			<div className="footer-bottom">
				<div className='footer-bottom-border'></div>
				<p className='text-base mt-5'>© 2023 rizon.uz Barcha huquqlar himoyalangan!</p>
			</div>
		</div>
	)
}
export default Footer