import React, { useState } from "react";
import s from './home.module.scss'
import Card from "../../../components/Card";
import calendar from '../../../assets/icons/Calendar_Event.png'
import Button from "../../../components/Button";
import card1 from '../../../assets/icons/Group 19 (1).png'
import card2 from '../../../assets/icons/Group 19 (3).png'
import check from '../../../assets/icons/chack.svg'
import product from '../../../assets/imgs/Limon water 1.png'
import { getLastDay, getLastMonth, getLastYear, thisMonth } from "../../../service/dates";
import BarChart from "../../../components/BarChart";
import Discount from "../../../components/discount/Discount";
import GetAll from "modules/GetAll";
import Loader from "components/Loader";
import { generateDate } from "pages/LandingPage/service/dates";
export default function HomeMijoz() {
    const [filter, setFIlter] = useState(thisMonth())
    let filters = [{ name: 'O’tgan oy', val: getLastMonth() }, { name: 'O’tgan 24 soat', val: getLastDay() }, { name: 'Bu Oy', val: thisMonth() }, { name: 'O’tgan yil', val: getLastYear() }]
    function choose(e) {
        setFIlter(e)
    }
    function generation(e) {
        const obj = {}
        if (e) {
            Object.keys(e).forEach((item) => {
                obj[generateDate(item)] = e[item]
            })
        }
        return obj
    }
    return (
        <GetAll name={'users-main'} url={'/brand-manager-main/'} >
            {({ items }) => {

                const card = [
                    {
                        icon: card1,
                        title: 'Umumiy Savdo',
                        inner: items?.data?.total_income?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + 'uzs',
                    },
                    {
                        icon: card2,
                        title: 'Umumiy Kupon',
                        inner: items?.data?.coupon?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + 'ball',
                    },
                    {
                        icon: card2,
                        title: 'Umumiy ball',
                        inner: items?.data?.ball.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + 'ta',
                    },
                ]
                const cardChart = [
                    {
                        title: 'Mahsulot sotuv ulushi',
                        inner: '',
                        info: items?.data?.product_sales_data
                    },
                    {
                        title: 'Umumiy maosh grafigi (uzs)',
                        inner: '*Lorem ipsum dolor sit amet consectetur. Sit ante curabitur diam lectus laoreet. ',
                        info: generation(items?.data?.salary_data)
                    },
                ]
                if (items.isLoading) return <Loader />
                return (
                    <div className={s.Actions} >
                        <div className="container" >
                            
                            {/* <div className={s.Button_wrapper} >
                                <div className={s.filetr_part} >
                                    {
                                        filters.map((item) => (
                                            <button onClick={() => choose(item.val)} className={filter == item.val ? `${s.chosen}` : s.btns} >{item.name}</button>
                                        ))
                                    }
                                </div>
                                <Button text={'Oraliq tanlash'} icon={calendar} />
                            </div> */}
                            <div className={s.card_wrapper} >
                                <div className={s.cards_box} >
                                    {
                                        card?.map((elem, i) => (
                                            <Card item={elem} key={i} />
                                        ))
                                    }
                                </div>
                            </div>
                            <div className={s.charts_box} >
                                {
                                    cardChart?.map((elem, i) => (
                                        <BarChart elem={elem} />
                                    ))
                                }
                            </div>
                            {
                                items?.data?.discounts?.map((elem) => (
                                    <Discount item={elem} />
                                ))
                            }

                        </div>

                    </div>
                )
            }}
        </GetAll>


    )
}