import React from 'react'
import Header from '../components/Header/Header'
import Loader from '../components/Loader'
import Product_info from '../components/ProductInfo/Product_info'
import Products from '../components/Products/Products'
import GetAll from '../hooks/GetAll'
function Home() {
	const {data,loading,error}=GetAll({
		url:'landing-main'
	})
	if (loading) return <Loader/>
	console.log('Landing',data);
	return (
		<div>
			<Header prod={data?.product} />
			<Products popular={data?.popular} highRating={data?.high_rating}/>
			<Product_info warehouses={data?.warehouses} comments={data?.comments} new_product={data?.new_product} discounts={data?.discounts} />
		</div>
	)
}

export default Home