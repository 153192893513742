import React,{useState} from "react";
import { Outlet } from "react-router-dom";
import s from './Forgot.module.scss'
import Back from 'assets/imgs/asosiy mockup 1.png'
export default function Forgot() {
    const [token,setToken]=useState()
    return(
        <div className={s.Login} >
            <Outlet context={[token,setToken]} />
            <img className={s.login_image} src={Back} />
        </div>
    )
    
}