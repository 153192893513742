import React, { useState } from "react";
import s from './Order.module.scss'
import Card from "../../../components/Card";
import CustomerProductOrders from "components/customerProductOrders/customerProductOrders";
import card1 from '../../../assets/icons/Group 19 (1).png'
import card2 from '../../../assets/icons/Group 19 (2).png'
import card4 from '../../../assets/icons/Group 19 (4).png'
import GetAll from "modules/GetAll";
import Loader from "components/Loader";

export default function OrderMijoz() {

    return (
        <GetAll name={'users-orders'} url={'/users-orders/'} >
            {({ items }) => {
                    const cardAction = [
                        {
                            icon: card1,
                            title: 'Umumiy Buyurtmalar',
                            inner: items?.data?.total_orders +'ta',
                        },
                        {
                            icon: card4,
                            title: 'Aktiv Buyurtmalar',
                            inner: items?.data?.active_orders +'ta',
                        },
                    ]
                    if (items?.isLoading) return <Loader/>
                return (
                    <div className={s.Actions} >
                        <div className="container" >
                            <div className={s.card_wrapper} >
                                <div className={s.cards_box} >
                                    {
                                        cardAction?.map((elem, i) => (
                                            <Card item={elem} key={i} />
                                        ))
                                    }
                                </div>
                            </div>
                            {
                                items?.data?.orders?.map((elem)=>(
                                    <CustomerProductOrders item={elem} />
                                ))
                            }
                        </div>
                    </div>
                )
            }}

        </GetAll>


    )
}