import React from "react";
import s from './WarehouseTable.module.scss'
import Table from "../../components/Table/Table";
import search from '../../assets/icons/Search_Magnifying_Glass.png'
import { elements } from "chart.js";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import archve from '../../assets/icons/Archive.svg'
import icon from '../../assets/icons/Share_iOS_Export.svg'
import SallProduct from '../../components/SallProduct'
export default function WarehouseTable({ data }) {
    const column = [
        {
            title: 'Mahsulot nomi',
            key: 'product_name',
        },

        {
            title: 'Miqdori',
            key: 'amount',
        },
        {
            title: 'Nasiya',
            key: 'debt',
            render: (item) => {
                return (
                    <span>{item.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}uzs</span>
                )
            }
        },
        {
            title: 'Umumiy summa',
            key: 'summa',
            render: (item) => {
                return (
                    <span>{item.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}uzs</span>
                )
            }
        },
    ]
    return (
        <div className={s.WarehouseTable}>
            <div className={s.WarehouseTable_top} >
                <h1 className={s.WarehouseTable_title} >Dorilar </h1>
                <div className={s.WarehouseTable_top_button} >
                    <Link to={'/HistorySalesWarehouse'} style={{ textDecoration: 'none' }}> <Button text={'Tarixni ko’rish'} icon={archve} /></Link>
                    <SallProduct icon={icon} />
                </div>
            </div>
            <div className={s.WarehouseTable_table} >
                <Table column={column} data={data} />
            </div>
        </div>
    )

}