import React, { useState } from "react";
import s from "./SalaryHistory.module.scss";
import Table from "components/Table/Table";
import GetAll from "modules/GetAll";
import { useParams } from "react-router-dom";
import Loader from "components/Loader";
import { generateDate } from "service/dates";
export default function SalaryHistory() {
  const { id } = useParams()
  const column = [
    // {
    //   title: "No",
    //   key: "no",
    // },
    {
      title: " Sana",
      key: "paymentDate",
      render:(item)=>{
        return(
          <span>{generateDate(item)}</span>
        )
      }
 
    },
    {
      title: "Filial",
      key: "employee",
      render:(item)=>{
        return(
          <span>{item?.warehouse?.name}</span>
        )
      }
    },
    {
      title: "To’landi",
      key: "paid",
      render:(item)=>{
        return(
          <span>{item?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}uzs</span>
        )
      }
    },
  ];


  return (
    <GetAll url={`/employees-salary/payments/`}>
      {({ items }) => {
        if (items.isLoading) return <Loader/>
        return (
          <div className={s.SalaryHistory}>
            <div className="container">
              <div className={s.HistoryInfo}>
                <Table column={column} data={items?.data?.payments} />
              </div>
            </div>
          </div>
        )
      }}
    </GetAll>
  );
}
