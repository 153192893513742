import React, { useState } from "react";
import s from './MoneyCirculation.module.scss'
import Card from "components/Card";
import { card, cardChart } from 'assets/db'
import BarChart from 'components/BarChart'
import { Data } from 'assets/db'
import PromotionTable from "container/PromotionTable/PromotionTable";
import Button from "components/Button";
import calendar from 'assets/icons/Calendar_Event.png'
import GetAll from 'modules/GetAll'
import Loader from 'components/Loader'
import Table from "components/Table/Table";
import { Link } from "react-router-dom";
import { getLastMonth, getLastYear, thisMonth, getThisYear } from 'service/dates'
import { usePost } from "hooks";
import { DateInput } from "components/DateInput";
export default function MoneyCirculation() {
    const [filter, setFIlter] = useState(thisMonth())
    let filters = [
        { name: 'O’tgan Oy', val: getLastMonth().slice(- 2) == "00" ? `${getLastYear()}-12` : getLastMonth() },
        { name: 'Hozirgi oy', val: thisMonth() },
        { name: 'O’tgan yil', val: getLastYear() },
        { name: 'Hozirgi yil', val: getThisYear() }
    ]
    function choose(e) {
        setFIlter(e)
    }
    const columnMini = [
        {
            title: "Shaxsiy aylanmalar",
            key: 'users_score',
        },
        {
            title: 'Shaxsiy sotuv bonuslari',
            key: 'personal_bonus',
            render:(item)=>(item?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")+'uzs')
        },
        {
            title: 'Ustozlik bonuslari',
            key: 'forMentorship',
            render:(item)=>(item?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")+'uzs')
        },
        {
            title: 'Jamoaviy bonuslar',
            key: 'collective_bonus',
            render:(item)=>(item?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")+'uzs')
        },
        {
            title: 'Extra bonuslar',
            key: 'extra_bonus',
            render:(item)=>(item?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")+'uzs')
        },
        {
            title: 'Kuponlar',
            key: 'coupon',
        },
        {
            title: 'Maoshlar',
            key: 'salary',
            render:(item)=>(item?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")+'uzs')
        },
        {
            title: 'To’langanlar',
            key: 'paid',
            render:(item)=>(item?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")+'uzs')
        },
        {
            title: 'Qarz',
            key: 'debt',
            render:(item)=>(item?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")+'uzs')
        },
    ]
    const dataMini = [
        {
            name: 'Toshkent dokoni',
            phone_number: '+998998045212',
            address: 'Yunusobod 11-29-40',
            ball: '30000',
            id: 1
        },
        {
            name: 'Toshkent filali',
            phone_number: '+998998045212',
            address: 'Yunusobod 11-29-40',
            ball: '30000',
            id: 2
        },
    ]
    const columnBranch = [
      {
        title: "Id",
        key: "user_id",
      },
      {
        title: "Isim va familiya",
        key: "first_name",
        render: (item, all) => item + " " + all.last_name,
      },

      {
        title: "Shaxsiy aylanmalar",
        key: "user_tree_score",
      },
      {
        title: "Shaxsiy sotuv bonuslari",
        key: "personal_bonus",
        render: (item) =>
          item?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "uzs",
      },
      {
        title: "Ustozlik bonuslari",
        key: "forMentorship",
        render: (item) =>
          item?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "uzs",
      },
      {
        title: "Jamoaviy bonuslar",
        key: "collective_bonus_amount",
        render: (item) =>
          item?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "uzs",
      },
      {
        title: "Extra bonuslar",
        key: "extra_bonus",
        render: (item) =>
          item?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "uzs",
      },
      {
        title: "Kuponlar",
        key: "coupon",
      },
      {
        title: "Maoshlar",
        key: "salary",
        render: (item) =>
          item?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "uzs",
      },
      {
        title: "To’langanlar",
        key: "paid",
        render: (item) =>
          item?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "uzs",
      },
      {
        title: "Qarz",
        key: "debt",
        render: (item) =>
          item?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "uzs",
      },
    ];
    const dataBranch = [
        {
            name: 'Toshkent filali',
            phone_number: '+998998045212',
            address: 'Yunusobod 11-29-40',
            ball: '30000',
            id: 1
        },
        {
            name: 'Toshkent filali',
            phone_number: '+998998045212',
            address: 'Yunusobod 11-29-40',
            ball: '30000',
            id: 2
        },
    ]
    const columnProfduct = [
        {
            title: "Mahsulot nomi",
            key: 'name',
        },
        {
            title: 'Sotuv miqdori',
            key: 'amount',
        },
        {
            title: 'Summa',
            key: 'summa',
            render:(item)=>(item?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")+'uzs')
        },
        // {
        //     title: "Batafsil",
        //     key: 'id',
        //     render: (item) => (
        //         <Link className={s.inner_butn} to={`/productinner/${item}`} >
        //             <Button text='Barafsil' />
        //         </Link>
        //     )
        // },
    ]
    const dataProd = [
        {
            name: 'Ofiyat 8',
            price_inner: '50 000sum',
            price_out: '100 000sum',
            id: 1
        },
        {
            name: 'Ofiyat 8',
            price_inner: '50 000sum',
            price_out: '100 000sum',
            id: 2
        },
    ]
    const columnEmp = [
        {
            title: "Filial",
            key: 'warehouse',
        },
        {
            title: 'Isim va familiyasi',
            key: 'first_name',
            render:(item,all)=>(item+all?.last_name)
        },
        {
            title: 'Sotuv (balli)',
            key: 'sale',
        },
        {
            title: 'Oylik',
            key: 'salary',
            render:(item)=>(item?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")+'uzs')
        },
        {
            title: 'To’landi',
            key: 'paid',
            render:(item)=>(item?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")+'uzs')
        },
        {
            title: 'Qarz',
            key: 'debt',
            render:(item)=>(item?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")+'uzs')
        },
    ]
    const dataEmp = [
        {
            user_d: '423234',
            name: 'Azamkhuja Vosiljonov',
            phone_number: '+998998045212',
            id: 1
        },
        {
            user_d: '423234',
            name: 'Azamkhuja Vosiljonov',
            phone_number: '+998998045212',
            id: 2
        },
    ]
    const columnUser = [
        {
            title: "Isim va familiya",
            key: 'first_name',
            render:(item,all)=>(item+all?.last_name)
        },
        {
            title: 'Oylik',
            key: 'salary',
            render:(item)=>(item?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")+'uzs')
        },
        {
            title: 'To’landi',
            key: 'paid',
            render:(item)=>(item?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")+'uzs')
        },
        {
            title: 'Qarz',
            key: 'debt',
            render:(item)=>(item?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")+'uzs')
        },
        // {
        //     title: "Ball",
        //     key: 'id',
        // },
        // {
        //     title: 'Tiklash',
        //     key: 'id',
        //     render: (item) => (
        //         <Link className={s.inner_butn} to={`/MedicalConsultant/${item}`} >
        //             <Button text='Tiklash' />
        //         </Link>
        //     )

        // },
    ]
    const dataUser = [
        {
            name: '423234',
            date: '12-09-2023',
            name_prod: 'Ofiyat 5',
            num: '3',
            id: 1
        },
        {
            name: '423234',
            date: '12-09-2023',
            name_prod: 'Ofiyat 5',
            num: '3',
            id: 2
        },
    ]
    return (
        <GetAll name={'main_page'} url={`/manager-finance/month/${filter}/`} >
            {({ items }) => {
                const cardChart = [
                    {
                        title: 'Daromad grafigi (uzs)',
                        inner: '*Lorem ipsum dolor sit amet consectetur. Sit ante curabitur diam lectus laoreet. ',
                        info: items?.data?.month_sales_data
                    },
                    {
                        title: 'Mijozlar grafigi (dona)',
                        inner: '*Lorem ipsum dolor sit amet consectetur. Sit ante curabitur diam lectus laoreet. ',
                        info: items?.data?.month_came_users
                    },
                    {
                        title: 'Mahsulot sotuv ulushi',
                        inner: '',
                        info: items?.data?.month_product_sales_data
                    },
                    {
                        title: 'Umumiy maosh grafigi (uzs)',
                        inner: '*Lorem ipsum dolor sit amet consectetur. Sit ante curabitur diam lectus laoreet. ',
                        info: items?.data?.salary_payer_warehouses
                    },
                ]
                const card = [
                    {
                        title: 'Jami tushum',
                        inner: items?.data?.head_info.total_income.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
                        val: "uzs"
                    },
                    {
                        title: 'Oylik',
                        inner: items?.data?.head_info.total_salary.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
                        val: 'uzs'
                    },
                    // {
                    //     title: 'To’landi',
                    //     inner: items?.data?.head_info.total_paid.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
                    //     val: 'uzs'
                    // },
                    // {
                    //     title: 'Qarz',
                    //     inner: items?.data?.head_info.total_debt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
                    //     val: 'uzs'
                    // },
                    // {
                    //     title: 'Kupon',
                    //     inner: items?.data?.head_info.total_coupon.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
                    //     val: 'uzs'
                    // },
                    // {
                    //     title: 'Qoldi',
                    //     inner: items?.data?.head_info.total_residual.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
                    //     val: 'uzs'
                    // },
                    {
                        title: 'Foyda',
                        inner: items?.data?.head_info.total_benefit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
                        val: 'uzs'
                    },
                ]
                if (items.isLoading) return <Loader />
                return (
                    <div className={s.Home} >
                        <div className="container" >
                            <div className={s.Button_wrapper} >
                                <div className={s.filetr_part} >
                                    {
                                        filters.map((item) => (
                                            <button onClick={() => choose(item.val)} className={filter == item.val ? `${s.chosen}` : s.btns} >{item.name}</button>
                                        ))
                                    }
                                </div>
                                <DateInput change={choose} />
                            </div>
                            <div className={s.cards_box} >
                                {
                                    card?.map((elem, i) => (
                                        <Card item={elem} key={i} />
                                    ))
                                }
                            </div>
                            <div className={s.Archive_table_wrapper} >
                                <h1 className={s.Archive_table_wrapper_title} >Umumiy jadval</h1>
                                <Table data={[items?.data?.users_info]} column={columnMini} />
                            </div>
                            <div className={s.Archive_table_wrappers} >
                                <h1 className={s.Archive_table_wrapper_title} >Xodimlar jadvali</h1>
                                <Table data={items?.data?.employees_info?.employees} column={columnEmp} numbers/>
                            </div>
                            <div className={s.Archive_table_wrappers} >
                                <h1 className={s.Archive_table_wrapper_title} >Konsultantlar jadvali</h1>
                                <Table column={columnUser} data={items?.data?.medics_info?.medics} numbers/>
                            </div>
                            <div className={s.Archive_table_wrapper} >
                                <h1 className={s.Archive_table_wrapper_title} >Effektiv start</h1>
                                <Table data={items?.data?.effective_starts_info} column={columnBranch} numbers/>
                            </div>
                            <div className={s.Archive_table_wrappers} >
                                <h1 className={s.Archive_table_wrapper_title} >Sotuvlar jadvali</h1>
                                <Table data={items?.data?.products_info} column={columnProfduct} numbers/>
                            </div>

                        </div>
                    </div>
                )
            }}
        </GetAll>
    )
}