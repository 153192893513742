import axios from 'axios'
import { getToken } from '../storage/token';
import { useNavigate } from 'react-router-dom';
const http = axios.create({
    baseURL: 'https://rizonwebappapi.pythonanywhere.com/api/'
})
http.interceptors.request.use(
    request => {
        request.headers["Authorization"] = `Bearer ${getToken()}`;
        return request;
    },
    error => {
        if (error.response.status === 401) {
            window.location.href = '/login';
            window.localStorage.removeItem('token');
        }
        return Promise.reject(error);
    }
);
http.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response.status === 401) {
            window.location.href = '/login';
            window.localStorage.removeItem('token');
        }
        return Promise.reject(error);
    }
);
export default http;