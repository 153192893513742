import React from "react";
import s from './Warehouse.module.scss'
import WarehouseCard from "../../../components/WarehouseCard";
import WarehouseTable from "../../../container/WarehouseTable";
import GetAll from "../../../modules/GetAll";
import Loader from "../../../components/Loader";
export default function Warehouse() {
    return (
        <GetAll name={'employees-warehouse'} url={'/employees-warehouse'} >
            {({ items }) => {
                if ( items.isLoading) return <Loader/>
                return (
                    <div className={s.Warehouse} >
                        <div className="container">
                            <div className={s.Warehouse_top} >
                                {
                                    items?.data?.products?.map((elem,i)=>(
                                        <WarehouseCard key={i} item={elem} />
                                    ))
                                }
                            </div>
                            <h1 className={s.Warehouse_title} >Mahsulot tarqatish </h1>
                            <WarehouseTable data={items?.data?.warehouse_products_info?.slice(0,15)} />
                        </div>
                    </div>
                )
            }}
        </GetAll>

    )

}