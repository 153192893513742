import s from './Product.module.scss'
import CustomProduct from 'components/customerProduct/CustomProduct'
import Discount from 'components/discount/Discount'
import GetAll from 'modules/GetAll';
import CustomerProductview from 'components/customerProductview/customerProductview';
import Loader from 'components/Loader';

const Product = () => {
  return (
    <GetAll name={'product_medics'} url={`/medics-products/`}>
      {
        ({items})=>{
          if (items.isLoading) return <Loader/>
          return (
            <div className={s.Product}>
              <div className="container">
                {
                  items?.data?.products.map(product=>{
                    return <CustomerProductview item={product}/>;
                  })
                }
                {
                  items?.data?.discounts?.map((elem)=>(
                    <Discount item={elem} />
                  ))
                }
              </div>
            </div>
          );
        }
      }
    </GetAll>
  );
}

export default Product