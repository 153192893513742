import React from "react";
import s from "./ViewProduct.module.scss";
import send from "../../assets/icons/send.svg";
import edit from "../../assets/icons/Note_Edit.svg";
import file_edit from "../../assets/icons/File_Edit.svg";
import product_img from "../../assets/imgs/Argenta kalloid 1.png";
import Button from "../Button";
export default function ViewProductImages({ done,archive, state, setState, medik }) {
  return (
    <div className={s.container}>
      <div className="container">
        <div className={s.addProduct}>
          <h1 className={s.addProduct_title}>
            Foto
            {
              medik ? null :
                <span>
                  <div className={s.change_image_wrapper}>
                    <div className={s.change_image} >
                      <label htmlFor="image" >Rasm o'zgartrish</label>
                      <input onChange={(e) => setState(e.target.files[0])} id='image' hidden type='file' />
                    </div>
                  </div>
                </span>
            }

          </h1>
          <div className={s.product_img}>
            {
              done ? <img src={state?.photo_link} className={s.product_img} alt="" /> : <img src={URL.createObjectURL(state?.photo_link)} className={s.product_img} alt="" /> 
            }
  
            {
              archive && <img src={'https://rizonwebappapi.pythonanywhere.com/'+state?.photo_link} className={s.product_img} alt="" />
            }
          </div>
        </div>
      </div>
    </div>
  );
}
