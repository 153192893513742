import React from "react";
import s from './AddProduct.module.scss'
import calendar from "../../assets/icons/send.svg";
import Button from "../Button";
export default function AddProduct({ setState, state, postData, medik, archive }) {
  return (
    <div className={s.container}>
      <div className="container">
        <div className={s.addProduct}>
          <h1 className={s.addProduct_title}> {medik ? 'Mahsulot' : 'Mahsulot qo’shish'} </h1>
          <form action="">
            <div className={s.productInfo}>
              <label htmlFor="productName">
                Mahsulot to’liq nomi
                <input onChange={(e) => setState({ ...state, name: e.target.value })} value={state?.name} type="text" id="productName" />
              </label>
              <label htmlFor="create">
                Ishlab chiqaruvchi
                <input onChange={(e) => setState({ ...state, manufacturer: e.target.value })} value={state?.manufacturer} type="text" id="create" />
              </label>
              <label htmlFor="">
                Tipi
                <input onChange={(e) => setState({ ...state, product_type: e.target.value })} value={state?.product_type} type="text" id="" />
              </label>
              <label htmlFor="">
                Mahsulot ichki narxi
                <input onChange={(e) => setState({ ...state, price: e.target.value })} value={state?.price} type="text" />
              </label>
              <label htmlFor="">
                Mahsulot tashqi narxi
                <input onChange={(e) => setState({ ...state, extraPrice:  e.target.value })} value={state?.extraPrice} type="text" />
              </label>
              <label htmlFor="">
                Yaroqlilik muddati
                <input onChange={(e) => setState({ ...state, expiration_date: e.target.value })} value={state?.expiration_date} type="text" />
              </label>
              {
                archive ?
                  <>
                    <label htmlFor="">
                      Jami sotuv
                      <input onChange={(e) => setState({ ...state, price: e.target.value })} value={state?.amount} type="text" />
                    </label>
                    <label htmlFor="">
                      Umumiy ball
                      <input onChange={(e) => setState({ ...state, expiration_date: e.target.value })} value={state?.ball} type="text" />
                    </label>
                  </>
                  :
                  null
              }
            </div>
            <div>
              <p>
                *Lorem ipsum dolor sit amet consectetur. Sit ante curabitur
                diam lectus laoreet. Integer tellus ullamcorper sed sagittis
                venenatis.
              </p>
            </div>
            <div className={s.addProduct_detail}>
              <h1 className={s.addProduct__title}>Batafsil</h1>
              <textarea onChange={(e) => setState({ ...state, about: e.target.value })} value={state?.about} name="" id="" cols="30" rows="10"></textarea>
            </div>
            {
              medik ?
                null
                :
                <div className={s.send}>
                  {
                    state?.photo_link ? null : <label className={s.uploadImg} htmlFor="addProduct_file">
                      Rasm yuklash
                      <img src={calendar} alt="" />
                      <input onChange={(e) => setState({ ...state, photo_link: e.target.files[0] })} type="file" id="addProduct_file" />
                    </label>
                  }

                  <Button onClick={(e) => state.name && state.price && state.photo_link ? postData(e) : null} disabled={state.name && state.price && state.photo_link ? false : true} text={"Saqlash"} />
                </div>
            }

          </form>
        </div>

      </div>
    </div>
  );
}