// Admin pages
import Actions from '../pages/Actions'
import Branches from '../pages/Branches'
import Customers from '../pages/Customers'
import MedicalConsultant from '../pages/MedicalConsultant'
import MoneyCirculation from '../pages/MoneyCirculation'
import Product from '../pages/Product'
import Settings from '../pages/Settings'
import Statistics from '../pages/Statistics'
import Promotion from '../pages/Promotion'
import MiniShop from '../pages/MiniShop'
import Home from '../pages/Home'
import Archive from 'pages/Archive'
import InnerPage from '../pages/Branches/InnerPage'
import InnerPageMed from '../pages/MedicalConsultant/InnerPageMed'
import HistorySalary from 'pages/Customers/InnerPageCust/HistorySalary'
import Employeeinner from '../pages/Branches/Employeeinner'
import AddProducts from '../pages/Product/AddProducts'
import InnerTableProduct from 'pages/Product/InnerTableProduct'
import InnerPageCust from '../pages/Customers/InnerPageCust'
import HistorySale from '../pages/Customers/InnerPageCust/HistorySale'
import ViewProductsAdmin from 'pages/Branches/ViewProductsAdmin'
import HistorySalaryEmp from 'pages/Branches/HistorySalaryEmp'
import HistorySalaryMed from 'pages/MedicalConsultant/HistorySalaryMed'
import AddBrach from 'pages/Branches/AddBrach'
import Branchinner from 'pages/Archive/Branch'
import Productinner from 'pages/Archive/Product'
import Employeinner from 'pages/Archive/Employe'
import MiniSHopArchive from 'pages/Archive/MiniShop'
import InnerMinishop from 'pages/MiniShop/InnerPage'
import ViewInnerPageProd from 'pages/MiniShop/ViewProductsAdmin'
import BrandManager from 'pages/BrandManager'
import InnerPageCustBrand from 'pages/BrandManager/InnerPageCust'
import HistorySaleBrand from '../pages/BrandManager/InnerPageCust/HistorySale'
import AddBrachminishop from 'pages/MiniShop/AddBrach'
import ViewProduct from 'pages/Product/VewProduct'
import SallHistory from 'pages/Branches/SallHistory/SallHistory'
import SallHistoryadminminishop from 'pages/MiniShop/SallHistory'
import AdminAccountNumbers from 'pages/AccountNumbers'

// manager
import Actionsmanager from '../pages/Manager/Actions'
import Branchesmanager from '../pages/Manager/Branches'
import Customersmanager from '../pages/Manager/Customers'
import MedicalConsultantmanager from '../pages/Manager/MedicalConsultant'
import MoneyCirculationmanager from '../pages/Manager/MoneyCirculation'
import Productmanager from '../pages/Manager/Product'
import Settingsmanager from '../pages/Manager/Settings'
import Statisticsmanager from '../pages/Manager/Statistics'
import Promotionmanager from '../pages/Manager/Promotion'
import MiniShopmanager from '../pages/Manager/MiniShop'
import Homemanager from '../pages/Manager/Home'
import Archivemanager from 'pages/Manager/Archive'
import InnerPagemanager from '../pages/Manager/Branches/InnerPage'
import InnerPageMedmanager from '../pages/Manager/MedicalConsultant/InnerPageMed'
import HistorySalarymanager from 'pages/Manager/Customers/InnerPageCust/HistorySalary'
import Employeeinnermanager from '../pages/Manager/Branches/Employeeinner'
import AddProductsmanager from '../pages/Manager/Product/AddProducts'
import InnerTableProductmanager from 'pages/Manager/Product/InnerTableProduct'
import InnerPageCustmanager from '../pages/Manager/Customers/InnerPageCust'
import HistorySalemanager from '../pages/Manager/Customers/InnerPageCust/HistorySale'
import ViewProductsAdminmanager from 'pages/Manager/Branches/ViewProductsAdmin'
import HistorySalaryEmpmanager from 'pages/Manager/Branches/HistorySalaryEmp'
import HistorySalaryMedmanager from 'pages/Manager/MedicalConsultant/HistorySalaryMed'
import AddBrachmanager from 'pages/Manager/Branches/AddBrach'
import Branchinnermanager from 'pages/Manager/Archive/Branch'
import Productinnermanager from 'pages/Manager/Archive/Product'
import Employeinnermanager from 'pages/Manager/Archive/Employe'
import MiniSHopArchivemanager from 'pages/Manager/Archive/MiniShop'
import InnerMinishopmanager from 'pages/Manager/MiniShop/InnerPage'
import ViewInnerPageProdmanager from 'pages/Manager/MiniShop/ViewProductsAdmin'
import BrandManagermanager from 'pages/Manager/BrandManager'
import InnerPageCustBrandmanager from 'pages/Manager/BrandManager/InnerPageCust'
import HistorySaleBrandmanager from '../pages/Manager/BrandManager/InnerPageCust/HistorySale'
import AddBrachmanagerminishop from 'pages/Manager/MiniShop/AddBrach'
import SallHistorymanager from 'pages/Manager/Branches/SallHistory/SallHistory'
import ViewProductmanager from 'pages/Manager/Product/VewProduct'
import SallHistorymanagerminishop from 'pages/Manager/MiniShop/SallHistory'
import AccountNumbers from 'pages/Manager/AccountNumbers'
import UsersTree from 'pages/Manager/UsersTree'
import UserTreeManager from 'pages/Manager/Customers/InnerPageCust/UserTree'

// user Pages
import HomeMijoz from '../pages/mijozPage/home/home'
import ProductMijoz from '../pages/mijozPage/products/Products'
import OrderMijoz from '../pages/mijozPage/order/Order'
import Notes from '../pages/mijozPage/notes/Notes'
import Profile from '../pages/mijozPage/profile/Profile'
import History from '../pages/mijozPage/shophistory/History'
import Transfer from '../pages/mijozPage/cuponTransfer/Transfer'
import SalaryHistory from '../pages/mijozPage/salaryHistory/SalaryHistory'
import PromotionMijoz from 'pages/mijozPage/Promotion'
import HistoryPromotion from 'pages/mijozPage/Promotion/History'
import Actionsuser from 'pages/mijozPage/Actions'
import AccountNumber  from 'pages/mijozPage/accountNumber'
// Brand manager
import HomeMijozBrand from '../pages/BrandManagerPage/home/home'
import ProductMijozBrand from '../pages/BrandManagerPage/products/Products'
import OrderMijozBrand from '../pages/BrandManagerPage/order/Order'
import NotesBrand from '../pages/BrandManagerPage/notes/Notes'
import ProfileBrand from '../pages/BrandManagerPage/profile/Profile'
import HistoryBrand from '../pages/BrandManagerPage/shophistory/History'
import TransferBrand from '../pages/BrandManagerPage/cuponTransfer/Transfer'
import SalaryHistoryBrand from '../pages/BrandManagerPage/salaryHistory/SalaryHistory'
import PromotionMijozBrand from 'pages/BrandManagerPage/Promotion'
import HistoryPromotionBrand from 'pages/BrandManagerPage/Promotion/History'
// emplyee pages
import Orders from '../pages/xodimlar/Orders'
import Warehouse from '../pages/xodimlar/Warehouse'
import ViewHistory from '../pages/xodimlar/product/ViewHistory'
import HistorySalesWarehouse from '../pages/xodimlar/Warehouse/HistorySalesWarehouse'
import HomeXodim from '../pages/xodimlar/home'
import ProductXodim from '../pages/xodimlar/product'
import ProfileXodim from '../pages/xodimlar/profile'
import CustomersEmployee from '../pages/xodimlar/Customers/CustomersEmployee'
import InnerPageCustXodim from '../pages/xodimlar/Customers/InnerPageCust'
import HistorySaleXodim from '../pages/xodimlar/Customers/InnerPageCust/HistorySale'
import AddProductsXodim from '../pages/xodimlar/product/AddProducts'
import StatisticsEmployee from '../pages/xodimlar/Statistics'
import HistorySalaryXodim from 'pages/xodimlar/Customers/InnerPageCust/HistorySalary'
import Actionsemp from 'pages/xodimlar/Actions'
import SalaryHistoryemp from 'pages/xodimlar/salaryHistory/SalaryHistory'
import EmployeeAcoountNumbers from 'pages/xodimlar/EmployeeAcoountNumbers'
// Medik
import HomeMedik from '../pages/medik/Home'
import ProductMedik from '../pages/medik/Product'
import StatisticsMedik from '../pages/medik/Statistics'
import ProfileMedik from '../pages/medik/Profile'
import ProfileSalaryHistoryXodim from 'pages/medik/Profile/SalaryHistory'
import InnerPageProdView from 'pages/medik/Product/InnerPageProdView'


export const RoutingData = {
         admin: [
           {
             name: "Asosiy Panel",
             path: "/",
             component: <Home />,
           },
           {
             name: "Aksiyalar",
             path: "/Actions",
             component: <Actions />,
           },
           {
             name: "Filiallar",
             path: "/Branches",
             component: <Branches />,
           },
           {
             name: "Asosiy Panel",
             path: "/Branches/:id",
             component: <InnerPage />,
           },
           {
             name: "Filial",
             path: "/BranchAdd",
             component: <AddBrach />,
           },
           {
             name: "Asosiy Panel",
             path: "/Branches/history/:id",
             component: <ViewProductsAdmin />,
           },
           {
             name: "Filial",
             path: "/sallhistory/:id",
             component: <SallHistory />,
           },
           {
             name: "Asosiy Panel",
             path: "/Branches/Salary/:id",
             component: <HistorySalaryEmp />,
           },
           {
             name: "Asosiy Panel",
             path: "/Branches/:id/:empoyId",
             component: <Employeeinner />,
           },
           {
             name: "Mijozlar",
             path: "/Customers",
             component: <Customers />,
           },
           {
             name: "Mijozlar",
             path: "/Customers/:id",
             component: <InnerPageCust />,
           },
           {
             name: "",
             path: "/history/sale/:id",
             component: <HistorySale />,
           },
           {
             name: "",
             path: "/salaryhistory/:id",
             component: <HistorySalary />,
           },
           {
             name: "Med konsultant",
             path: "/MedicalConsultant",
             component: <MedicalConsultant />,
           },
           {
             name: "Med konsultant",
             path: "/MedicalConsultant/:id",
             component: <InnerPageMed />,
           },
           {
             name: "Med konsultant",
             path: "/Medic/salary/:id",
             component: <HistorySalaryMed />,
           },
           {
             path: "/MoneyCirculation",
             component: <MoneyCirculation />,
           },
           {
             name: "Mahsulot",
             path: "/Product",
             component: <Product />,
           },
           {
             name: "Mahsulot",
             path: "/Product/viewProduct/:id",
             component: <ViewProduct />,
           },
           {
             name: "Mahsulot",
             path: "/Product/AddNew",
             component: <AddProducts />,
           },
           {
             name: "Mahsulot",
             path: "/Product/history/admin",
             component: <InnerTableProduct />,
           },
           // {
           //     path:'/Settings',
           //     component:<Settings/>
           // },

           {
             name: "Statistika",
             path: "/Statistics",
             component: <Statistics />,
           },
           {
             path: "/Promotion",
             component: <Promotion />,
           },
           {
             path: "/MiniShop",
             component: <MiniShop />,
           },
           {
             path: "arxiv",
             component: <Archive />,
           },
           {
             path: "/branchinner/:id",
             component: <Branchinner />,
           },
           {
             path: "/productinner/:id",
             component: <Productinner />,
           },
           {
             path: "/employeinner/:id",
             component: <Employeinner />,
           },
           {
             path: "/minishoparchive/:id",
             component: <MiniSHopArchive />,
           },
           {
             path: "/MiniShop/:id",
             component: <InnerMinishop />,
           },
           {
             path: "/MiniShop/view/:id",
             component: <ViewInnerPageProd />,
           },
           {
             path: "/MiniShop/sallhistory/:id",
             component: <SallHistoryadminminishop />,
           },
           {
             path: "/addminishop",
             component: <AddBrachminishop />,
           },
           {
             path: "/brandmanager",
             component: <BrandManager />,
           },
           {
             path: "/brandmanager/:id",
             component: <InnerPageCustBrand />,
           },
           {
             path: "brandmanager/sale/:id",
             component: <HistorySaleBrand />,
           },
           {
            path:"/settings",
            component: <Settings/>,
           },
           {
            path:'account-numbers',
            component: <AdminAccountNumbers/>
           }
         ],
         manager: [
           {
             name: "Asosiy Panel",
             path: "/",
             component: <Homemanager />,
           },
           {
             path: "/addminishop",
             component: <AddBrachmanagerminishop />,
           },
           {
             name: "Aksiyalar",
             path: "/Actions",
             component: <Actionsmanager />,
           },
           {
             name: "Filiallar",
             path: "/Branches",
             component: <Branchesmanager />,
           },
           {
             name: "Filial",
             path: "/sallhistory/:id",
             component: <SallHistorymanager />,
           },
           {
             name: "Asosiy Panel",
             path: "/Branches/:id",
             component: <InnerPagemanager />,
           },
           {
             name: "Filial",
             path: "/BranchAdd",
             component: <AddBrachmanager />,
           },
           {
             name: "Asosiy Panel",
             path: "/Branches/history/:id",
             component: <ViewProductsAdminmanager />,
           },
           {
             name: "Asosiy Panel",
             path: "/Branches/Salary/:id",
             component: <HistorySalaryEmpmanager />,
           },
           {
             name: "Asosiy Panel",
             path: "/Branches/:id/:empoyId",
             component: <Employeeinnermanager />,
           },
           {
             name: "Mijozlar",
             path: "/Customers",
             component: <Customersmanager />,
           },
           {
             name: "Mijozlar",
             path: "/Customers/:id",
             component: <InnerPageCustmanager />,
           },
           {
             name: "",
             path: "/history/sale/:id",
             component: <HistorySalemanager />,
           },
           {
             name: "",
             path: "/salaryhistory/:id",
             component: <HistorySalarymanager />,
           },
           {
             name: "Med konsultant",
             path: "/MedicalConsultant",
             component: <MedicalConsultantmanager />,
           },
           {
             name: "Med konsultant",
             path: "/MedicalConsultant/:id",
             component: <InnerPageMedmanager />,
           },
           {
             name: "Med konsultant",
             path: "/Medic/salary/:id",
             component: <HistorySalaryMedmanager />,
           },
           {
             path: "/MoneyCirculation",
             component: <MoneyCirculationmanager />,
           },
           {
             name: "Mahsulot",
             path: "/Product",
             component: <Productmanager />,
           },
           {
             name: "Mahsulot",
             path: "/Product/AddNew",
             component: <AddProductsmanager />,
           },
           {
             name: "Mahsulot",
             path: "/Product/viewProduct/:id",
             component: <ViewProductmanager />,
           },
           {
             name: "Mahsulot",
             path: "/Product/history/admin",
             component: <InnerTableProductmanager />,
           },
           // {
           //     path:'/Settings',
           //     component:<Settingmanagers/>
           // },

           {
             name: "Statistika",
             path: "/Statistics",
             component: <Statisticsmanager />,
           },
           {
             path: "/Promotion",
             component: <Promotionmanager />,
           },
           {
             path: "/MiniShop",
             component: <MiniShopmanager />,
           },
           {
             path: "/MiniShop/sallhistory/:id",
             component: <SallHistorymanagerminishop />,
           },
           {
             path: "arxiv",
             component: <Archivemanager />,
           },
           {
             path: "/branchinner/:id",
             component: <Branchinnermanager />,
           },
           {
             path: "/productinner/:id",
             component: <Productinnermanager />,
           },
           {
             path: "/employeinner/:id",
             component: <Employeinnermanager />,
           },
           {
             path: "/minishoparchive/:id",
             component: <MiniSHopArchivemanager />,
           },
           {
             path: "/MiniShop/:id",
             component: <InnerMinishopmanager />,
           },
           {
             path: "/MiniShop/view/:id",
             component: <ViewInnerPageProdmanager />,
           },
           {
             path: "/brandmanager",
             component: <BrandManagermanager />,
           },
           {
             path: "/brandmanager/:id",
             component: <InnerPageCustBrandmanager />,
           },
           {
             path: "brandmanager/sale/:id",
             component: <HistorySaleBrandmanager />,
           },
           {
             name: "Aksiyalar",
             path: "/Actions",
             component: <Actionsmanager />,
           },
           {
             name: "Hisob-raqamlar",
             path: "/account-numbers",
             component: <AccountNumbers />,
           },
           {
            path:"/users-tree",
            component:<UsersTree />,
           },
           {
            path:"/user-tree/:id",
            component:<UserTreeManager/>,
           }
         ],
         employee: [
           {
             name: "Asosiy Panel",
             path: "/",
             component: <HomeXodim />,
           },
           {
             name: "Mahsulotlar",
             path: "/Product",
             component: <ProductXodim />,
           },
           {
             name: "Mahsulotlar",
             path: "/Product/:id",
             component: <AddProductsXodim />,
           },
           {
             name: "Mahsulotlar",
             path: "/Product/history",
             component: <ViewHistory />,
           },
           {
             name: "Mijozlar",
             path: "/Customers",
             component: <CustomersEmployee />,
           },
           {
             name: "Mijozlar",
             path: "/Customers/:id",
             component: <InnerPageCustXodim />,
           },
           {
             name: "Mijozlar",
             path: "/Customers/history/:id",
             component: <HistorySaleXodim />,
           },
           {
             name: "Statistika",
             path: "/StatisticsEmployee",
             component: <StatisticsEmployee />,
           },
           {
             name: "Buyurtmalar",
             path: "/Orders",
             component: <Orders />,
           },
           {
             name: "Ombor",
             path: "/Warehouse",
             component: <Warehouse />,
           },
           {
             name: "Ombor",
             path: "/HistorySalesWarehouse",
             component: <HistorySalesWarehouse />,
           },
           {
             name: "Profil",
             path: "/Profile",
             component: <ProfileXodim />,
           },
           {
             name: "Asosiy Panel",
             path: "/Customers/salaryhistory/:id",
             component: <HistorySalaryXodim />,
           },
           {
             name: "Aksiya",
             path: "/Actions",
             component: <Actionsemp />,
           },
           {
             path: "/Profile/history/salary/:id",
             component: <SalaryHistoryemp />,
           },
           {
            name:"Hisob-raqamlar",
            path:'/account-numbers',
            component:<EmployeeAcoountNumbers/>,
           }
         ],
         ordinary_user: [
           {
             name: "Asosiy Panel",
             path: "/",
             component: <HomeMijoz />,
           },
           {
             name: "Mahsulot",
             path: "/Product",
             component: <ProductMijoz />,
           },
           {
             name: "Buyurtmalar",
             path: "/orders",
             component: <OrderMijoz />,
           },
           {
             name: "Profil",
             path: "/profile",
             component: <Profile />,
           },
           {
             name: "Harid tarixi",
             path: "/history",
             component: <History />,
           },
           {
             name: "Maosh tarixi",
             path: "/salary/:id",
             component: <SalaryHistory />,
           },
           {
             name: "Kupon transfer",
             path: "/transfer",
             component: <Transfer />,
           },
           {
             name: "Notification",
             path: "/notifications",
             component: <Notes />,
           },
           {
             name: "Promotion",
             path: "/promotion",
             component: <PromotionMijoz />,
           },
           {
             name: "Promotion",
             path: "/HistoryPromotion",
             component: <HistoryPromotion />,
           },
           {
             name: "Aksiyalar",
             path: "/Actions",
             component: <Actionsuser />,
           },
           {
             name: "Hisobraqam",
             path: "/account-number",
             component: <AccountNumber />,
           },
         ],
         medic: [
           {
             name: "Asosiy Panel",
             path: "/",
             component: <HomeMedik />,
           },
           {
             name: "Mahsulot",
             path: "/product",
             component: <ProductMedik />,
           },
           {
             name: "Statistika",
             path: "/Statistics",
             component: <StatisticsMedik />,
           },
           {
             name: "Profil",
             path: "/Profile",
             component: <ProfileMedik />,
           },
           {
             name: "Profil/Maosh tarixi",
             path: "/Profile/Salary-history",
             component: <ProfileSalaryHistoryXodim />,
           },
           {
             name: "Profil",
             path: "/InnerPageProdview/:id",
             component: <InnerPageProdView />,
           },
         ],
         brand_manager: [
           {
             name: "Asosiy Panel",
             path: "/",
             component: <HomeMijozBrand />,
           },
           {
             name: "Mahsulot",
             path: "/Product",
             component: <ProductMijozBrand />,
           },
           {
             name: "Buyurtmalar",
             path: "/orders",
             component: <OrderMijozBrand />,
           },
           {
             name: "Profil",
             path: "/profile",
             component: <ProfileBrand />,
           },
           {
             name: "Harid tarixi",
             path: "/history",
             component: <HistoryBrand />,
           },
           {
             name: "Maosh tarixi",
             path: "/salary/:id",
             component: <SalaryHistoryBrand />,
           },
           {
             name: "Kupon transfer",
             path: "/transfer",
             component: <TransferBrand />,
           },
           {
             path: "/notifications",
             component: <NotesBrand />,
           },
           {
             path: "/promotion",
             component: <PromotionMijozBrand />,
           },
           {
             path: "/HistoryPromotion",
             component: <HistoryPromotionBrand />,
           },
         ],
       };