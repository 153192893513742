import React, { useState } from "react";
import s from './InnerPageProdView.module.scss'
import ViewProduct from "components/viewProduct/ViewProduct";
import AddProduct from "components/addProduct/AddProduct";
import { useGetAll, usePost } from "hooks";
import { toast } from 'react-toastify'
import {  useNavigate, useParams } from "react-router-dom";
import Loader from "components/Loader";
export default function InnerPageProdView() {
    const [state, setState] = useState({})
    const {id} =useParams()
    const {data,isLoading} = useGetAll({
        url:`/products/${id}/`
    })
    if (isLoading) return <Loader/>
    return (
        <div className={s.AddProducts}>
            <div className="container" >
                <AddProduct medik={true} setState={setState} state={data} />
                {
                    data?.photo_link ?
                        <ViewProduct medik={true} done={true} setState={setState} state={data} />
                        :
                        null
                }
            </div>
        </div>
    )

}