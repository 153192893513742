import { useState } from 'react';
import s from './Statistics.module.scss'
import { getLastMonth, getLastYear, getThisYear, thisMonth } from 'service/dates';
import card1 from "../../../assets/icons/Group 19 (1).png";
import { DateInput } from 'components/DateInput';
import Card from 'components/Card';
import ConnectedProducts from 'container/ConnectedProducts'
import GetAll from 'modules/GetAll';
import Loader from 'components/Loader';
import BarChartBig from 'components/BarChartBig';
const Statistics = () => {
  const [filter, setFIlter] = useState(thisMonth());
  let filters = [
    { name: "O’tgan Oy", val: getLastMonth() },
    { name: "Hozirgi oy", val: thisMonth() },
    { name: "O’tgan yil", val: getLastYear() },
    { name: "Hozirgi yil", val: getThisYear() },
  ];
  function choose(e) {
    setFIlter(e);
  }
  function GetData(data) {
    let obj = {}
    Object.keys(data)?.forEach(elem => {
       obj[elem] = {sales_amount:data[elem].product_sale_amount}
    });
    return obj
  }

  return (
    <GetAll name={"medics_statistics"} url={`/medics-statistics/month/${filter}`}>
      {({ items }) => {
        // console.log(items);
        const card = [
          {
            icon: card1,
            title: "Umumiy Savdo",
            inner: items?.data?.total_income
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
            val: "ball",
          },
        ];
        if(items?.isLoading) return <Loader/>
        return (
          <div className={s.Statistics}>
            <div className="container">
              <div className={s.Button_wrapper}>
                <div className={s.filetr_part}>
                  {filters.map((item) => (
                    <button
                      onClick={() => choose(item.val)}
                      className={filter == item.val ? `${s.chosen}` : s.btns}
                    >
                      {item.name}
                    </button>
                  ))}
                </div>
                <DateInput />
              </div>
              <div className={s.cards_box}>
                {card?.map((elem, i) => (
                  <Card item={elem} key={i} />
                ))}
              </div>
              <BarChartBig elem={GetData(items?.data?.product_sales_data)} />
              <div className={s.charts_box}>
                <h1>Biriktirilgan mahsulotlar ro’yxati</h1>
                <ConnectedProducts items={items}/>
              </div>
            </div>
          </div>
        );
      }}
    </GetAll>
  );
}

export default Statistics