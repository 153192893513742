import asosiy from '../assets/icons/Gift.png'
import filial from '../assets/icons/Building_04.png'
import med from '../assets/icons/First_Aid.png'
import mahsulot from '../assets/icons/Shopping_Bag_02.png'
import user from '../assets/icons/Users_Group.png'
import pul from '../assets/icons/Chart_Pie.png'
import statistic from '../assets/icons/Chart_Bar_Vertical_01.png'
import puzzle from '../assets/icons/Puzzle.png'
import compass from '../assets/icons/Compass.png'
import House from '../assets/icons/House_02.png'
import card1 from '../assets/icons/Group 19 (1).png'
import card2 from '../assets/icons/Group 19 (2).png'
import card3 from '../assets/icons/Group 19 (3).png'
import shop from '../assets/icons/Shopping_Cart_01.png'
import build from '../assets/icons/Building_04.png'
import profile from '../assets/icons/profileIcon.png'

export const menuItem = {
         admin: [
           {
             name: "Asosiy",
             path: "/",
             icon: House,
           },
           {
             name: "Filiallar",
             path: "/Branches",
             icon: filial,
           },
           {
             name: "Med konsultant",
             path: "MedicalConsultant",
             icon: med,
           },
           {
             name: "Brend menejer",
             path: "brandmanager",
             icon: user,
           },
           {
             name: "Mahsulot",
             path: "Product",
             icon: mahsulot,
           },
           {
             name: "Mijozlar",
             path: "/Customers",
             icon: user,
           },
           {
             name: "Pul aylanmasi",
             path: "/MoneyCirculation",
             icon: pul,
           },
           {
             name: "Statistika",
             path: "/Statistics",
             icon: statistic,
           },
           {
             name: "Aksiyalar",
             path: "/Actions",
             icon: puzzle,
           },
           {
             name: "Promotion",
             path: "/Promotion",
             icon: asosiy,
           },
           {
             name: "Mini Do’konlar",
             path: "/MiniShop",
             icon: compass,
           },
           {
             name: "Arxiv",
             path: "/arxiv",
             icon: mahsulot,
           },
         ],
         manager: [
           {
             name: "Asosiy",
             path: "/",
             icon: House,
           },
           {
             name: "Filiallar",
             path: "/Branches",
             icon: filial,
           },
           {
             name: "Med konsultant",
             path: "MedicalConsultant",
             icon: med,
           },
           {
             name: "Brend menejer",
             path: "brandmanager",
             icon: user,
           },
           {
             name: "Mahsulot",
             path: "Product",
             icon: mahsulot,
           },
           {
             name: "Mijozlar",
             path: "/Customers",
             icon: user,
           },
           {
             name: "Pul aylanmasi",
             path: "/MoneyCirculation",
             icon: pul,
           },
           {
             name: "Statistika",
             path: "/Statistics",
             icon: statistic,
           },
           {
             name: "Aksiyalar",
             path: "/Actions",
             icon: puzzle,
           },
           {
             name: "Promotion",
             path: "/account-numbers",
             icon: asosiy,
           },
           {
             name: "Mini Do’konlar",
             path: "/MiniShop",
             icon: compass,
           },
           {
             name: "Arxiv",
             path: "/arxiv",
             icon: mahsulot,
           },
         ],
         employee: [
           {
             name: "Asosiy",
             path: "/",
             icon: House,
           },
           {
             name: "Mahsulot",
             path: "Product",
             icon: mahsulot,
           },
           {
             name: "Mijozlar",
             path: "/Customers",
             icon: user,
           },
           {
             name: "Pul aylanmasi",
             path: "/MoneyCirculation",
             icon: pul,
           },
           {
             name: "Statistika",
             path: "/StatisticsEmployee",
             icon: statistic,
           },
           {
             name: "Buyurtma",
             path: "/Orders",
             icon: shop,
           },
           {
             name: "Ombor",
             path: "/Warehouse",
             icon: build,
           },
           {
             name: "Aksiyalar",
             path: "/Actions",
             icon: puzzle,
           },
         ],
         ordinary_user: [
           {
             name: "Asosiy",
             path: "/",
             icon: House,
           },
           {
             name: "Mahsulot",
             path: "/Product",
             icon: mahsulot,
           },
           {
             name: "Buyurtmalar",
             path: "/orders",
             icon: shop,
           },
           {
             name: "Aksiyalar",
             path: "/Actions",
             icon: puzzle,
           },
           {
             name: "Promotion",
             path: "/promotion",
             icon: asosiy,
           },
           // {
           //     name:'profile',
           //     path:'/profile',
           //     icon: House
           // },
           // {
           //     name:'history',
           //     path:'/history',
           //     icon: House
           // },
           // {
           //     name:'transfer',
           //     path:'/transfer',
           //     icon: House
           // },
           // {
           //     name:'salary',
           //     path:'/salary',
           //     icon: House
           // },
         ],
         medic: [
           {
             name: "Asosiy",
             path: "/",
             icon: House,
           },
           {
             name: "Mahsulot",
             path: "/Product",
             icon: mahsulot,
           },
           {
             name: "Statistika",
             path: "/Statistics",
             icon: statistic,
           },
         ],
         brand_manager: [
           {
             name: "Asosiy",
             path: "/",
             icon: House,
           },
           {
             name: "Mahsulot",
             path: "/Product",
             icon: mahsulot,
           },
           {
             name: "Buyurtmalar",
             path: "/orders",
             icon: shop,
           },
           {
             name: "Aksiyalar",
             path: "/Actions",
             icon: puzzle,
           },
           {
             name: "Promotion",
             path: "/promotion",
             icon: asosiy,
           },
           // {
           //     name:'profile',
           //     path:'/profile',
           //     icon: House
           // },
           // {
           //     name:'history',
           //     path:'/history',
           //     icon: House
           // },
           // {
           //     name:'transfer',
           //     path:'/transfer',
           //     icon: House
           // },
           // {
           //     name:'salary',
           //     path:'/salary',
           //     icon: House
           // },
         ],
       };

export const helpers = {
  'admin': [
    {
      name: "Sozlamalar",
      path: "/settings",
      icon: puzzle,
    },
    {
      name: "Qo’llab quvvatlash",
      path: "/",
      icon: asosiy,
    },
    {
      name: "Big Leap Team",
      path: "/",
      icon: compass,
    },
  ],
  'manager': [
    {
      name: "Sozlamalar",
      path: "/",
      icon: puzzle,
    },
    {
      name: "Qo’llab quvvatlash",
      path: "/",
      icon: asosiy,
    },
    {
      name: "Big Leap Team",
      path: "/",
      icon: compass,
    },
  ],
  'employee': [
    {
      name: "Profil",
      path: "/Profile",
      icon: profile,
    },
    {
      name: "Sozlamalar",
      path: "/",
      icon: puzzle,
    },
    {
      name: "Qo’llab quvvatlash",
      path: "/",
      icon: asosiy,
    },
    {
      name: "Big Leap Team",
      path: "/",
      icon: compass,
    },
  ],
  'ordinary_user': [
    {
      name: "Profil",
      path: "/Profile",
      icon: profile,
    },
    {
      name: "Sozlamalar",
      path: "/",
      icon: puzzle,
    },
    {
      name: "Qo’llab quvvatlash",
      path: "/",
      icon: asosiy,
    },
    {
      name: "Big Leap Team",
      path: "/",
      icon: compass,
    },
    
  ],
  'brand_manager': [
    {
      name: "Profil",
      path: "/Profile",
      icon: profile,
    },
    {
      name: "Sozlamalar",
      path: "/",
      icon: puzzle,
    },
    {
      name: "Qo’llab quvvatlash",
      path: "/",
      icon: asosiy,
    },
    {
      name: "Big Leap Team",
      path: "/",
      icon: compass,
    },
    
  ],
  'medic': [
    {
      name: "Profil",
      path: "/Profile",
      icon: profile,
    },
    {
      name: "Sozlamalar",
      path: "/",
      icon: puzzle,
    },
    {
      name: "Qo’llab quvvatlash",
      path: "/",
      icon: asosiy,
    },
    {
      name: "Big Leap Team",
      path: "/",
      icon: compass,
    },
  ],
};



export const card = [
  {
    icon: card1,
    title: 'Umumiy Savdo',
    inner: '80 000 000uzs',
  },
  {
    icon: card2,
    title: 'Mijozlar soni',
    inner: '591ta',
  },
  {
    icon: card3,
    title: 'Umumiy Kupon',
    inner: '80 000 000uzs',
  },
  {
    icon: card3,
    title: 'Umumiy ball',
    inner: '73 580ta',
  },
]
export const cardChart = [
  {
    icon: card1,
    title: 'Daromad grafigi (uzs)',
    inner: '*Lorem ipsum dolor sit amet consectetur. Sit ante curabitur diam lectus laoreet. ',
  },
  {
    icon: card2,
    title: 'Mijozlar grafigi (dona)',
    inner: '*Lorem ipsum dolor sit amet consectetur. Sit ante curabitur diam lectus laoreet. ',
  },
  {
    icon: card3,
    title: 'Mahsulot sotuv ulushi',
    inner: '',
  },
  {
    icon: card3,
    title: 'Umumiy maosh grafigi (uzs)',
    inner: '*Lorem ipsum dolor sit amet consectetur. Sit ante curabitur diam lectus laoreet. ',
  },
]
export const cardAction = [
  {
    icon: card1,
    title: 'Umumiy Savdo',
    inner: '80 000 000uzs',
  },
  {
    icon: card2,
    title: 'Mijozlar soni',
    inner: '591ta',
  },
]
export const Validation = ['address', 'first_name', 'last_name', 'passport', 'password', 'phone_number', 'phoneNumTwo', 'photo', 'username', 'dateOfBirth']

export const Data = [
  {
    id: 1,
    year: 2016,
    userGain: 80000,
    userLost: 823
  },
  {
    id: 2,
    year: 2017,
    userGain: 45677,
    userLost: 345
  },
  {
    id: 3,
    year: 2018,
    userGain: 78888,
    userLost: 555
  },
  {
    id: 4,
    year: 2019,
    userGain: 90000,
    userLost: 4555
  },
  {
    id: 5,
    year: 2020,
    userGain: 4300,
    userLost: 234
  }
];
export const product = [
  'Ofiyat 6',
  'Ofiyat 2',
  'Ofiyat 2',
  'Ofiyat 2',
  'Ofiyat 2',
]
export const errors = {
  'first_name': 'Ism',
  'last_name': 'Familiya',
  'username': 'Foydalanuvchi nomi',
  'phone_number': 'Telefon raqam',
  'address': 'Manzil',
  'phoneNumTwo': 'Telefon raqam 2',
  'passport': 'Pasport seriyasi',
  'password': "Ko'd",
}