import React, { useEffect, useState } from "react";
import Header from "../container/Header/Header";
import Dashboard from "../container/Dashboard/Dashboard";
import { RoutingData } from './config'
import { Route, useNavigate, Routes } from "react-router-dom";
import Login from "pages/Login";
import { getToken } from "service/storage/token";
import Registration from 'pages/Registration'
import Step1 from 'pages/Registration/Step1'
import Step2 from 'pages/Registration/Step2'
import Step3 from 'pages/Registration/Step3'
import Step4 from 'pages/Registration/Step4'
import Forgot from "pages/Forgot";
import Step1f from "pages/Forgot/Step1";
import Step2f from "pages/Forgot/Step2";
import Step3f from "pages/Forgot/Step3";
import Home from "pages/LandingPage/pages/Home";
import About from "pages/LandingPage/pages/About";
import Mahsulotlar from "pages/LandingPage/pages/Mahsulotlar";
import Batafsil from "pages/LandingPage/pages/Batafsil";
import Navbar from "pages/LandingPage/components/Navbar/Navbar";
import Footer from "pages/LandingPage/components/Footer/Footer";
const Routers = () => {
  const [dashboard, setDashboard] = useState(false)
  const [login, setLogin] = useState('landing')
  const navigate = useNavigate()
  useEffect(() => {
    if (!getToken()) {
      navigate('/')
      setLogin('landing')
    }
  }, [])
  return (
    <div className={getToken() ? "app_wrapper_top" : ''}>
      {getToken() ? (
        <>
          <Dashboard  {...{ dashboard, setDashboard, type: window.localStorage.getItem('role') }} />
          <div className="app_wrapper">
             <Header setLogin={setLogin} {...{ setDashboard }} />
            <Routes>
              {RoutingData[window.localStorage.getItem('role')]?.map((elem, i) => (
                <Route
                  key={i}
                  exact
                  path={elem.path}
                  element={elem.component}
                />
              ))}
            </Routes>
          </div>
        </>
      ) : (
        < >
          {
            login == 'landing' ?
              <>
                <Navbar setLogin={setLogin} />
                <Routes>
                  <Route path="/" element={<Home></Home>} />
                  <Route path="/bizhaqimizda" element={<About />} />
                  <Route path="/mahsulotlar" element={<Mahsulotlar />} />
                  <Route path="/product/:id" element={<Batafsil />} />
                </Routes>
                <Footer />
              </>
              :
              <Routes>
                <Route exact path="/Login" element={<Login />} />
                <Route exact path="/Registration" element={<Registration />} >
                  <Route exact path="Step1" element={<Step1 />} />
                  <Route exact path="Step2" element={<Step2 />} />
                  <Route exact path="Step3" element={<Step3 />} />
                  <Route exact path="Step4" element={<Step4 />} />
                </Route>
                <Route exact path="/ForgotPassword" element={<Forgot />} >
                  <Route exact path="Step1" element={<Step1f />} />
                  <Route exact path="Step2" element={<Step2f />} />
                  <Route exact path="Step3" element={<Step3f />} />
                </Route>
              </Routes>
          }

        </>
      )}
    </div>
  );

}
export default Routers