import React, { useState } from "react";
import s from './Customers.module.scss'
import Card from "components/Card";
import { card } from 'assets/db'
import Button from "components/Button";
import calendar from 'assets/icons/Calendar_Event.png'
import Generations from "container/Generations";
import pdf from 'assets/icons/Folder_Download.svg'
import Loader from 'components/Loader'
import card1 from 'assets/icons/Group 19 (1).png'
import card2 from 'assets/icons/Group 19 (2).png'
import card3 from 'assets/icons/Group 19 (3).png'
import { getLastDay, getLastMonth, getLastYear, thisMonth, getThisDay, getThisYear } from 'service/dates'
import GetAll from "modules/GetAll";
import { DateInput } from "components/DateInput";
import Changeshajara from "components/Changeshajara";
import { Link } from "react-router-dom";
export default function Customers() {
    const [tabel, setTable] = useState(false)
    const [pagenation, setPagenation] = useState(10)
    const [filter, setFIlter] = useState(thisMonth())
    let filters = [
      { name: 'O’tgan Oy', val: getLastMonth().slice(- 2) == "00" ? `${getLastYear()}-12` : getLastMonth() },
      { name: 'Hozirgi oy', val: thisMonth() },
      { name: 'O’tgan yil', val: getLastYear() },
      { name: 'Hozirgi yil', val: getThisYear() }
    ]
    function choose(e) {
        setFIlter(e)
    }

    let a = 7
    return (
        <GetAll name={'costumeers_page'} url={`manager-users/month/${filter}`} >
            {({ items }) => {
                const card = [
                    {
                        icon: card1,
                        title: 'Umumiy Savdo',
                        inner: items?.data?.total_sales_summa.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
                        val: "ball"
                    },
                    {
                        icon: card2,
                        title: 'Mijozlar soni',
                        inner: items?.data?.total_users,
                        val: 'ta'
                    },
                    {
                        icon: card3,
                        title: 'Umumiy Kupon',
                        inner: items?.data?.total_coupon,
                        val: 'Kupon'
                    },
                    // {
                    //     icon: card3,
                    //     title: 'Umumiy ball',
                    //     inner: items?.data?.total_ball,
                    //     val: 'ball'
                    // },
                ]
                if (items.isLoading) return <Loader />
                return (
                  <div className={s.Customers}>
                    <div className="container">
                      <div className={s.Button_wrapper}>
                        <div className={s.filetr_part}>
                          {filters.map((item) => (
                            <button
                              onClick={() => choose(item.val)}
                              className={
                                filter == item.val ? `${s.chosen}` : s.btns
                              }
                            >
                              {item.name}
                            </button>
                          ))}
                        </div>
                        <DateInput change={choose} />
                      </div>
                      <div className={s.cards_box}>
                        {card?.map((elem, i) => (
                          <Card item={elem} key={i} />
                        ))}
                      </div>
                      <div className={s.Button_wrapper_table}>
                        {/* <Changeshajara/> */}
                        <select
                          onChange={(e) => setPagenation(e.target.value)}
                          className={s.Button_wrapper_table_select}
                        >
                          <option value={10}>1-10</option>
                          <option value={30}>1-30</option>
                          <option value={50}>1-50</option>
                          <option value={100}>1-50</option>
                          <option value={0}>Barchasi</option>
                        </select>
                        <Link
                          style={{
                            textDecoration: "none",
                            marginRight: "20px",
                          }}
                          to={"/users-tree"}
                        >
                          <Button text="Daraxt ko'rinishida" />
                        </Link>
                        <Link
                          style={{
                            textDecoration: "none",
                            marginRight: "20px",
                          }}
                          to={"/account-numbers"}
                        >
                          <Button text="Hisob-raqamlar" />
                        </Link>
                        {/* <a
                          className={s.pdf}
                          target={"_blank"}
                          href={
                            "https://rizonwebapp.pythonanywhere.com" +
                            items?.data?.users_excel_data
                          }
                          style={{ textDecoration: "none" }}
                        >
                          Pdf yuklash
                        </a> */}
                        <Button onClick={() => setTable(true)} text="Ko'proq" />
                      </div>
                      <div
                        onClick={() => setTable(false)}
                        className={tabel ? s.table_wrapper : ""}
                      >
                        {pagenation == 0 ? (
                          <Generations data={items?.data?.user_tree} />
                        ) : (
                          <Generations
                            data={items?.data?.user_tree?.slice(0, pagenation)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                );
            }}
        </GetAll>


    )
}