import React from "react";
import s from './Card.module.scss'
import icons from '../../assets/icons/Group 19.png'
import PercentPart from "../PercentPart";
export default function Card({item,key, percentPart = true}) {
    return(
        <div key={key} className={s.card} >
            <div className={s.card_left} >
                <div className={s.card_left_top} >
                    <img className={s.card_left_top_img} src={item.icon} />
                    <p className={s.card_left_top_text} >{item.title}</p>
                </div>
                <h2 className={s.card_left_price} >{item.inner}<p>{item.val}</p></h2>
            </div>
        </div>
    )
    
}