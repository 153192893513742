import React, { useState } from 'react'
import './Navbar.css'
import logo from '../../../../assets/icons/111.png'
import icon from '../../public/images/icon.png'
import { useNavigate,Link } from 'react-router-dom'
import hamburger from '../../../../assets/icons/humburger.png'
import close from '../../../../assets/icons/close.png'

function Navbar({setLogin}) {
	const navigate = useNavigate()
	function nextPage() {
		setLogin('login')
		navigate('/login')
	}
	const [nav, setNav] = useState(false)
	return (
    <div className="navbar container2">
      <div className="navbar_left">
        <Link to="/">
          <img src={logo} alt="" />
        </Link>
      </div>
      <div className="nav-humburger" onClick={() => setNav(true)}>
        <img src={hamburger} alt="" />
      </div>
      <div className={`navbar_right ${nav && "active"}`}>
        <div className={`nav-close `} onClick={() => setNav(false)}>
          <img src={close} alt="" />
        </div>
        <ul>
          <Link to="/" className="li">
            Asosiy
          </Link>
          <Link to="/bizhaqimizda" className="li">
            Biz haqimizda
          </Link>
          <Link to="/mahsulotlar" className="li">
            Mahsulotlar
          </Link>
        </ul>
        <button className="Navbar_link_to_login" onClick={() => nextPage()}>
          Kirish <img src={icon} alt="" />
        </button>
      </div>
    </div>
  );
}

export default Navbar