import React, { useState } from "react";
import Employee from "../../container/Employee/Employee";
import s from './Archive.module.scss'
import GetAll from "../../modules/GetAll";
import { useParams,Link } from "react-router-dom";
import Loader from "../../components/Loader";
import Table from "components/Table/Table";
import { usePost } from "hooks";
import {toast} from 'react-toastify'
import { useNavigate } from "react-router-dom";
export default function Employe({ }) {
    const navigate = useNavigate()
    const { id } = useParams()
    const [salary, setSalary] = useState(false)
    const columnUser = [
        {
            title: "Xodim ID",
            key: 'employee',
            render:(item)=>(item?.id)
        },
        {
            title: 'Mahsulot',
            key: 'product',
            render:(item)=>(item?.name)
            // render:(item,all)=>(generateDate(item))

        },
        {
            title: 'Miqdor',
            key: 'amount',
            // render:(item)=>(item?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "))
        },
        {
            title: 'Summa',
            key: 'summa',
            render:(item)=>(item?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")+'uzs')

        },
        {
            title: "Fialial",
            key: 'warehouse',
            render:(item)=>(item?.name)
        },
    ]
    const {mutate,isLoading}=usePost()
    function recovery(e) {
        debugger
        const formdata = new FormData()
        formdata.append('deleted',false)
        mutate({
            url:`admin-archive/employee/${id}/`,
            method:'put',
            data:formdata,
            onSuccess:success,
            onError:error
        })
        
    }
    function success(params) {
        navigate('/arxiv')
        toast.success("Xodim tiklandi!", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    function error(params) {
        toast.error("Xodim tiklanmadi!", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    return (
        <GetAll name="branch_empoyee_inner" url={`/admin-archive/employee/${id}/`} >
            {({ items }) => {
                if (items?.isLoading) return <Loader />
                return (
                    <div className={s.Employeeinner} >
                        <div className="container" >
                            <Employee recovery={recovery} data={items?.data} archive={true} setSalary={setSalary} />
                        </div>
                        {/* {
                            salary ? <SalaryPay setSalary={setSalary} /> : null
                        } */}
                        <div className={s.Archive_table_wrapper} >
                            <h1 className={s.Archive_table_wrapper_title} >Xodim</h1>
                            <Table column={columnUser} data={items?.data?.sales} />
                        </div>
                    </div>
                )
            }}
        </GetAll>

    )

}