import React,{useEffect, useState} from "react";
import s from './Consultants.module.scss'
import Table from "../../components/Table/Table";
import search from '../../assets/icons/Search_Magnifying_Glass.png'
import { elements } from "chart.js";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import AddMedModal from "components/AddMedModal";
export default function Consultants({data,refatch}) {
    const [state,setState]=useState()
    const [add,setAdd]=useState(false)
    useEffect(()=>{
        setState(data)
    },[data])
    const column = [
        {
            title: 'ID',
            key: 'user_id',
        },

        {
            title: 'Ism familiya',
            key: 'first_name',
        },
        {
            title: 'Biriktirilgan mahsulot',
            key: 'med_products',
            render: (item) => (
                <div className={s.product_wrapper} >
                    {
                        item.map((elem) => (
                            <p className={s.product} >{`${elem?.product?.name}`}</p>
                        ))
                    }
                </div>
            )
        },
        {
            title: 'Status',
            key: 'salary',
        },
        {
            title: 'Oylik',
            key: 'salary',
        },
        {
            title: "Ko'proq",
            key: 'id',
            render: (item) => (
                <Link className={s.inner_butn} to={`/MedicalConsultant/${item}`} >
                    <Button text='To’liq' />
                </Link>
            )
        },
    ]
    function Search(e) {
        let arr = []
        data?.forEach((elem) => {
          if (Object.values(elem).join(" ").toLowerCase().includes(e.target.value.toLowerCase())) {
            arr.push(elem)
          }
        })
        if (e.target.value.length > 1) setState(arr)
        else setState(data)
      }
    return (
        <div className={s.Consultants}>
            <div className={s.Consultants_top} >
                <h1 className={s.Consultants_title} >Konsultantlar ro’yxati</h1>
                <Button onClick={()=>setAdd(true)} text={"Med Konsultantlar qo'shish"} />
                {
                    add && <AddMedModal refatch={refatch} setAdd={setAdd} />
                }
                <div className={s.Consultants_top_button} >
                    <input onChange={(e)=>Search(e)} type={'text'} placeholder='Qidirish' />
                    <img src={search} />
                </div>
            </div>
            <div className={s.Consultants_table} >
                <Table column={column} data={state} />
            </div>
        </div>
    )

}