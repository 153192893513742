import React from "react";
import s from './HistorySale.module.scss'
import Table from "components/Table/Table";
import search from 'assets/icons/Search_Magnifying_Glass.png'
import { elements } from "chart.js";
import { Link, useParams } from "react-router-dom";
import Button from "components/Button";
import GetAll from "modules/GetAll";
import Loader from "components/Loader";
export default function HistorySalaryMed() {
    const { id } = useParams()
    const column = [
        {
            title:"F.I.Sh.",
            render:(val, data) => {
                return `${data?.medic.user.first_name} ${data?.medic.user.last_name}`
            }
        },
        {
            title: 'Sana',
            key: 'date',
        },

        // {
        //     title: 'Maosh',
        //     key: 'salary',
        // },
        // {
        //     title: 'Kechiktirilgan',
        //     key: 'late',
        // },
        {
            title: 'To’langan maosh',
            key: 'paid',
            render:(item)=>{
                return(
                    <span>{item?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}uzs</span>
                )
            }
        },
        // {
        //     title: 'Maosh to’lash',
        //     key: 'pay',
        // },
        // {
        //     title: 'Mijoz ID',
        //     key: 'medic',
        //     render:(item)=>{
        //         return(
        //             <span>{item?.user_id}</span>
        //         )
        //     }
        // },
    ]
    return (
        <GetAll name={'history-user'} url={`/medics-salary/payments/${id}`} >
            {({ items }) => {
                if (items.isLoading) return <Loader/>
                // console.log(items?.data);
                return (
                    <div className={s.HistorySale}>
                        <div className="container" >
                            <div className={s.HistorySale_table} >
                                <Table column={column} data={items?.data?.payments} numbers/>
                            </div>
                        </div>
                    </div>
                )
            }}
        </GetAll>
    )
}