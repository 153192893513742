import React from "react";
import card from "../public/images/card.png";
import Card from "./Card/Card";

const Products = ({data}) => {

  return (
    <div className="product_medicen_card">
      {data?.map((item) => {
        return (
          <Card
            id={item.id}
            title={item.name}
            img={'https://rizonwebappapi.pythonanywhere.com/'+item.photo_link}
            price={item.extraPrice}
          />
        );
      })}
    </div>
  );
};

export default Products;
