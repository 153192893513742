import React from "react";
import s from './PromotionTable.module.scss'
import Table from "../../components/Table/Table";
import plus from '../../assets/icons/Add_Plus_Circlenew.svg'
import { generateDate } from "service/dates";
import Button from "components/Button";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { usePost } from "hooks";
import { http } from "service";
export default function PromotionTable({data}) {
    const queryClient = useQueryClient();
    // const deleteAction = usePost({
    //   method: "delete",
    //   onSuccess: () => {
    //     toast.success("Successfull");
    //     queryClient.invalidateQueries("/manager-discounts/");
    //   },
    //   onError: () => toast.error("Error"),
    // });
    const deleteAction = (id)=>{
        http["delete"](`v2/discounts-destroy/${id}/`)
          .then((data) => {
                  toast.success("Successfull");
                  queryClient.invalidateQueries("/manager-discounts/");
                })
          .catch((err) => toast.error("Error"));
    }
    // console.log(deleteAction.mutate);
    const column = [
      {
        title: "Nomi",
        key: "product",
        render: (item) => {
          return <span>{item?.name}</span>;
        },
      },
      {
        title: "Boshlanish sanasi",
        key: "startDate",
        render: (item) => {
          return <span>{generateDate(item)}</span>;
        },
      },
      {
        title: "Tugash sanasi",
        key: "endDate",
        render: (item) => {
          return <span>{generateDate(item)}</span>;
        },
      },
      {
        title: "Aksiya",
        key: "amount",
        render: (item, all) => <span>{item + "+" + all?.discount}</span>,
      },
      {
        title: "Sotilganlar soni",
        key: "sold",
        render: (item) => {
          return <span>{item}ta</span>;
        },
      },
      {
        // title: "O'chirish",
        render: (val, data) => {
        //   console.log("data", data);
          return (
            <Button
              text={deleteAction.isLoading ? "Loading" :"O'chirish"}
                onClick={() => deleteAction(data?.id)}
            />
          );
        },
      },
    ];
    return (
        <div className={s.PromotionTable}>
            <h1 className={s.PromotionTable_title} >Aksiyalar</h1>
            <div className={s.PromotionTable_table} >
                <Table column={column} data={data} numbers/>
            </div>
        </div>
    )

}