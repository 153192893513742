import React from "react";
import "./Product_info.css";

import card from "../../public/images/card.png";
import logo1 from "../../public/images/h1.png";
import logo2 from "../../public/images/h2.png";
import logo3 from "../../public/images/h3.png";
import logo4 from "../../public/images/h4.png";
import Card from "../Card/Card";
import Product_card from "./Product_card";
import Comments from "../Comments";
import Products from "../Products";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function Product_info({ discounts, comments, warehouses, new_product }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="product_info container2">
      {
        discounts?.map((elem) => (
          <Product_card warehouses={warehouses} data={elem} />
        ))
      }
      <div className="product_info_main">
        <div className="product_medicen_cards">
          <h1>Yangi tarkiblar</h1>
          {/* <p>
            100 000uzs dan yuqori bo’lgan haridlar uchun yetkazib berish bepul!
          </p> */}
          <Products data={new_product} />
        </div>
        <div className="product_review">
          <h1>Mijozlar fikri</h1>
          <Slider {...settings}>
            {comments?.map((item) => (
              <Comments item={item} />
            ))}
          </Slider>
          {/* <button className="product_review_btn">Barchasi</button> */}
        </div>
        <div className="product_hamkorlar">
          <h1>Hamkorlar</h1>
          <Slider {...settings}>
            <img className="slider_images" src={logo1} alt="" />
            <img className="slider_images" src={logo2} alt="" />
            <img className="slider_images" src={logo3} alt="" />
            <img className="slider_images" src={logo4} alt="" />
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default Product_info;
