import GetAll from "modules/GetAll";
import s from "./accountNumber.module.scss";
import Loader from "components/Loader";
import card1 from "../../../assets/icons/Group 19 (1).png";
import card2 from "../../../assets/icons/Group 19 (2).png";
import card3 from "../../../assets/icons/Group 19 (3).png";
import Card from "components/Card";
import React from "react";
import Table from "components/Table/Table";

const AccountNumber = () => {
  const columnAccountNumber = [
    {
      title:"Status",
      key:"status",
    },
    {
      title:"Bonus turi",
      key:"bonus_type",
    },
    {
      title:"Miqdor",
      key:"amount",
      render:(item)=> <span style={item < 0 ? {color:"red"} : item > 0 ? {color:"green"}:null}>{item}</span>
    },
    {
      title:"Izoh",
      key:"comment",
    },
    {
      title:"Oy",
      key:"month"
    }
  ]
  return (
    <GetAll name={"users-bonus-accounts/"} url={"users-bonus-accounts/"}>
      {({ items }) => {
        // console.log(items.data);

        const card = [
          {
            icon: card1,
            title: "Cashback",
            inner: items?.data?.Cashback.toString().replace(
              /\B(?=(\d{3})+(?!\d))/g,
              " "
            ),
            val: "uzs",
          },
          {
            icon: card2,
            title: "Voucher",
            inner: items?.data?.Voucher.toString().replace(
              /\B(?=(\d{3})+(?!\d))/g,
              " "
            ),
            val: "uzs",
          },
          {
            icon: card3,
            title: "Umra",
            inner: items?.data?.Umra.toString().replace(
              /\B(?=(\d{3})+(?!\d))/g,
              " "
            ),
            val: "uzs",
          },
          {
            icon: card3,
            title: "Travel",
            inner: items?.data?.Travel.toString().replace(
              /\B(?=(\d{3})+(?!\d))/g,
              " "
            ),
            val: "uzs",
          },
          {
            icon: card3,
            title: "Avto Bonus",
            inner: items?.data?.Avtobonus.toString().replace(
              /\B(?=(\d{3})+(?!\d))/g,
              " "
            ),
            val: "uzs",
          },
        ];

        if (items?.isLoading) return <Loader />;

        return (
          <div className={s.AccountNumber}>
            <div className="container">
              <div className={s.cards_box}>
                {card?.map((elem, i) => (
                  <React.Fragment key={i}>
                    <Card item={elem} />
                  </React.Fragment>
                ))}
              </div>
              <div className={s.Account_number_table}>
                <h1 className={s.Account_number_table_title}>
                  Hisobraqam jadval
                </h1>
                <Table column={columnAccountNumber} data={items?.data?.history}/>
              </div>
            </div>
          </div>
        );
      }}
    </GetAll>
  );
};

export default AccountNumber;
