import React, { useState } from "react";
import s from './PromotionCard.module.scss'
import { usePost } from "hooks";
import { toast } from 'react-toastify'
import { Button, Input, Modal } from 'antd';
import PromotionItemAdd from "components/PromotionItemAdd";
export default function PromotionCard({ elem, reFatch }) {
    const { mutate, isLoading } = usePost()
    const [add, setAdd] = useState(false)
    const [amount,setAmount]=useState()
    function pause() {
        const formdata = new FormData()
        formdata.append('pause', !elem.pause)
        mutate({
            url: `/admin-promotions/${elem.id}/pause/`,
            method: 'put',
            data: formdata,
            onSuccess: success,
        })
    }
    function deletes() {
        mutate({
            url: `/admin-promotions/${elem.id}/`,
            method: 'delete',
            onSuccess: success,
        })
    }
    function success(e) {
        if (e.data.massage) {
            toast.success(e.data.massage, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
        reFatch()
        setIsModalOpen(false);
    }
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        const formdata = new FormData()
        formdata.append('amount', amount)
        formdata.append('promotion', elem.id)
        mutate({
            url: '/admin-promotions/amount/',
            method: 'put',
            data: formdata,
            onSuccess: success,
        })
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <div className={s.PromotionCard} >
            <img className={s.photos} src={'https://rizonwebappapi.pythonanywhere.com/' + elem.photo} />
            <h1 className={s.PromotionCard_title} >{elem.name}</h1>
            <div className={s.PromotionCard_text_wrapper} >
                <p>Kupon: {elem.coupon}</p>
                <p>Qoldiq: {elem.amount}ta</p>
            </div>
            <div className={s.PromotionCard_btn_wrapper} >
                <buton onClick={showModal} className={isLoading ? `${s.PromotionCard_btn_wrapper_item} load` : s.PromotionCard_btn_wrapper_item} >Qo’shish</buton>
                <buton onClick={() => isLoading ? null : pause()} className={isLoading ? `${s.PromotionCard_btn_wrapper_item} load` : s.PromotionCard_btn_wrapper_item} style={elem.pause ? { color: 'green' } : { color: 'rgba(244, 190, 55, 1)' }}>Pauza</buton>
                <buton onClick={() => isLoading ? null : deletes()} className={isLoading ? `${s.PromotionCard_btn_wrapper_item} load` : s.PromotionCard_btn_wrapper_item} >O’chirish</buton>
            </div>
            <button onClick={() => setAdd(true)} className={s.PromotionCard_main_btn} >Tahrirlash</button>
            {
                add && <div className={s.Modal_Wrapper} ><PromotionItemAdd reFatch={reFatch} setAdd={setAdd} data={elem} /></div>
            }
            <Modal width={300} title="Miqdorini yozing" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <Input onChange={(e)=>setAmount(e.target.value)} placeholder="Miqdori" type={'number'} />
            </Modal>
        </div>
    )
}
