import React, { useState } from "react";
import s from "./Product.module.scss";
import Button from "../Button";
import ShoppingCart from "../../assets/icons/Shopping_Cart_01.png";
import productImg from "../../assets/imgs/Argenta kalloid 1.png";
import canada from "../../assets/icons/canada.png";
import icon from '../../assets/icons/Shopping_Cart_01.png'
import { useGetAll, usePost } from "hooks";
import { toast } from 'react-toastify'
import Loader from "components/Loader";
import GetAll from "modules/GetAll";
function CustomerProductPromotion({ item, product, refatch,x, medik, setOrder }) {
  const [state, setState] = useState({})
  function colculate(e) {
    setState({ ...state, amount: e, total_sum: item.price * e })
  }
  const { mutate, isLoading } = usePost()
  function postOprder(params) {
    const formdata = new FormData
    formdata.append('promotion', item.id)
    formdata.append('amount', state.amount)
    mutate({
      url: '/users-promotions-buy/',
      data: formdata,
      method: 'post',
      onSuccess: success,
      onError: error
    })
  }
  function success(params) {
    if (params.data.success == false) {
      toast.error(params.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }else{
      toast.success("Muvoffaqiyatli harid qilindi!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setOrder(false)
    refatch()
  }
  function error(err) {
    toast.error(err.response.data.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }

  return (
    <GetAll name={'promotion_buy'} url={`/users-promotions/${item.id}`} >
      {({ items }) => {
        if (items.isLoading) return <Loader/>
        return (
          <div className={s.Product}>
            {
              isLoading ?
                <div className={s.loader_back} >
                  <Loader />
                </div>
                :
                null
            }
            <div className={s.product_img}>
              <img
                src={`https://rizonwebapp.pythonanywhere.com${item?.photo}`}
                alt=""
              />
            </div>
            {
              x ?
                <img onClick={() => setOrder(false)} className={s.cencel} src={x} />
                : null
            }
            <div className={s.product_info}>
              <img
                className={s.mob_img}
                src={`https://rizonwebapp.pythonanywhere.com${item?.photo}`}
                alt=""
              />
              <h2>{item?.name}</h2>
              <div className={s.product_form}>
                <label htmlFor="">
                  <h3>Soni</h3>
                  <input type="number" onChange={(e) => colculate(e.target.value)} />
                </label>
              </div>
              <Button onClick={state?.amount  ? () => postOprder() : null} disabled={state?.amount  ? false : true} text={"Harid qilish"} icon={ShoppingCart} />
            </div>
          </div>
        )
      }}

    </GetAll>

  );
}

export default CustomerProductPromotion;
