
import { useState } from "react";
import s from "./Product.module.scss";
import Button from "components/Button";
import calendar from 'assets/icons/Calendar_Event.png'
import Card from "components/Card";
import { card } from 'assets/db'
import ProductDistribution from 'container/ProductDistribution'
import ListofProducts from "container/ListofProducts";
import GetAll from "modules/GetAll";
import card1 from 'assets/icons/Group 19 (1).png'
import card2 from 'assets/icons/Group 19 (2).png'
import card3 from 'assets/icons/Group 19 (3).png'
import Loader from "components/Loader";
import BarChartBig from "components/BarChartBig";
export default function Product() {
  const [filter, setFIlter] = useState('O’tgan oy')
  let filters = ['O’tgan oy', 'Hozirgi oy', 'O’tgan yil', 'Hozirgi yil']
  function choose(e) {
    setFIlter(e)
  }
  function GetData(data) {
    let obj = {}
    data?.forEach(elem => {
       obj[elem.product.name] = {sales_amount:elem.sale_amount}
    });
    return obj
  }
  return (
    <GetAll name={'product-page'} url={'/manager-products'} >
      {({ items }) => {

        if (items.isLoading) return <Loader />
        return (
          <div className={s.Product} >
            <div className="container" >
              {/* <div className={s.Button_wrapper} >
                <div className={s.filetr_part} >
                  {
                    filters.map((item) => (
                      <button onClick={() => choose(item)} className={filter == item ? `${s.chosen}` : s.btns} >{item}</button>
                    ))
                  }
                </div>
                <Button text={'Oraliq tanlash'} icon={calendar} />
              </div> */}
              {/* <div className={s.cards_box} >
                {
                  card?.map((elem, i) => (
                    <Card item={elem} key={i} />
                  ))
                }
              </div> */}
              
              <h1 className={s.Table_title} >Mahsulotlar savdo ulushi </h1>
              <BarChartBig elem={GetData(items?.data?.product_sales_data)} />
              <h1 className={s.Table_title} >Mahsulot tarqatish </h1>
              <ProductDistribution  data={items?.data?.product_distribution?.slice(0, 6)} />
              <h1 style={{ marginTop: 45 }} className={s.Table_title} >Mahsulotlar ro’yxati</h1>
              <ListofProducts data={items?.data?.products} />
            </div>
          </div>
        )
      }}
    </GetAll>


  );
}
