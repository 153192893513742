import Button from "../../../../../components/Button";
import Input from "../../../../../components/Input";
import s from './styles.module.scss'
import { Link } from "react-router-dom";
import userImage from "../../../../../assets/icons/woman2.png";

const Index = ({ data }) => {

  return (
    <div className={s.User_info_wrapper}>
      <div className={s.User_info_wrapper__inner_top}>
        <div className={s.Username_wrapper}>
          <div className={s.Image_wrapper}>
            {
              !data?.photo ?
                <img src={userImage} alt="user" />
                :
                <img
                src={`https://rizonwebappapi.pythonanywhere.com/${data?.photo}`}
                alt="userImage"
              />
            }
          </div>
          <div>
            <h1 className={s.Username}>
              {data?.first_name} {data?.last_name}
            </h1>
            {data?.status?.map((stat, i) => {
              return <p key={i}>{stat}</p>;
            })}
          </div>
        </div>
        <div className={s.Salary}>
          <div className={s.Salary_wrapper}>
            <div>
              <h1>{data?.coupon}</h1>
              <p>Umumiy kupon</p>
            </div>
            <div>
              <h1>14 000 000</h1>
              <p>Umumiy daromad</p>
            </div>
          </div>
          <div className={s.Button_wrapper}>
            <Link to={`history/salary/${data?.id}`} style={{textDecoration:'none'}} ><Button text="Maosh tarixi" /></Link>
          </div>
        </div>
      </div>
      <div className={s.User_info_wrapper__inner_bottom}>
        <h1>Ma’lumotlar</h1>
        <form className={s.Number}>
          <div className={s.labels}>
            <label htmlFor="">
              <h3>Telefon raqam</h3>
              <input type="text" disabled value={data?.phone_number} />
            </label>
            <label htmlFor="">
              <h3>Telefon raqam 2</h3>
              <input type="text" disabled value={data?.phoneNumTwo} />
            </label>
            <label htmlFor="">
              <h3>Tug’ilgan sana</h3>
              <input type="date" disabled value={data?.dateOfBirth} />
            </label>
            <label htmlFor="">
              <h3>Pasport seriyasi</h3>
              <input type="text" disabled value={data?.passport} />
            </label>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Index

