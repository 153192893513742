import s from './Transfer.module.scss'
import { useState } from 'react';
import Button from "../../../components/Button";
import card from "../../../assets/icons/card.png";
import { usePost } from 'hooks';
import { toast } from 'react-toastify'
import Loader from 'components/Loader';
export default function Info({ data }) {
    const [state, setState] = useState({ first_name: '', last_name: '' })
    const { mutate, isLoading } = usePost()
    const [validate, setValidate] = useState(false)
    function get() {
        if (state?.amount < 1000) {
            setValidate(true)
        } else {
            mutate({
                url: `users/${state.getter_id}/user_id/`,
                method: 'get',
                onSuccess: success,
                onError: error,
            })
        }
    }
    function success(data) {
        setState({ ...state, ...data?.data })
    }
    console.log(state);
    function error(params) {
        toast.error("Foydalanuvchi topilmadi!", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    function postData() {
        const fromdata = new FormData()
        fromdata.append('comment', state?.comment)
        fromdata.append('used', state?.amount)
        fromdata.append('receiver', state?.id)
        mutate({
            url: `/coupons/transfers/`,
            method: 'post',
            data: fromdata,
            onSuccess: successpost,
            onError: errorpost,
        })
    }
    function successpost(e) {
        if (!e.data?.success) {
            toast.error(e.data?.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }else{
            toast.success("Kupon yuborildi!", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }
    function errorpost(params) {
        toast.error("Kupon yuborilmadi!", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    return (
        <div className={s.TransferInfo}>
            {
                isLoading ?
                    <div className={s.loader_back} >
                        <Loader />
                    </div>
                    :
                    null
            }
            <h1> ID orqali mijozga kupon yuborish</h1>
            <div className={s.transferData}>
                <div>
                    <div>
                        <h2>{data?.first_name} {data?.last_name}</h2>
                        <h4>Yuboruvchi</h4>
                    </div>
                    <div>
                        <h3>Kupon</h3>
                        <h1>{data?.coupon}</h1>
                    </div>
                </div>
                <div className={s.form}>
                    <div className={s.labels}>
                        <div className={s.get_form} >
                            <label htmlFor="">
                                <h3>Kupon miqdori</h3>
                                <input onChange={(e) => setState({ ...state, amount: e.target.value }) + setValidate(e.target.value > 999 ? false : true )} className={s.inputs_cupon} type="number" />
                               {validate && <p className={s.validates} >Kupon miqdori kamida 1000 bo'lishi kerak</p>}  
                            </label>
                            <label htmlFor="">
                                <h3>Qabul qiluvchi ID</h3>
                                <input onChange={(e) => setState({ ...state, getter_id: e.target.value })} className={s.inputs_cupon} type="text" />
                            </label>
                            <Button onClick={() => state?.amount && state?.getter_id ? get() : null} disabled={state?.amount && state?.getter_id ? false : true} text="Qabul qiluvchni olish" />
                        </div>
                        <div className={s.get_form} >
                            <label htmlFor="">
                                <h3>Qabul qiluvchi ism va familiyasi</h3>
                                <input className={s.name_user} type="text" value={state?.first_name + ' ' + state?.last_name} />
                            </label>
                            <label htmlFor="">
                                <h3>Izoh</h3>
                                <input className={s.inputs_cupon} onChange={(e) => setState({ ...state, comment: e.target.value })} type="text" value={state?.comment} />
                            </label>
                        </div>
                    </div>
                    <Button onClick={() => state?.amount && state?.getter_id ? postData() : null} text="O’tkazish" icon={card} />
                </div>
            </div>
        </div>
    )

}