import React, { useState } from "react";
import s from './AddEmpModal.module.scss'
import usePost from "../../hooks/usePost";
import Loader from "../Loader";
import { toast, ToastContainer } from 'react-toastify'
import Input from "../Input";
import Button from "../Button";
import plus from '../../assets/icons/Add_Plus_Circle2.svg'
import Passport from "../Passport";
import { Validation,errors } from "../../assets/db";
import cencel from '../../assets/icons/cancel-button.png'
import Textarea from "components/Textarea";
export default function AddMedModal({ setAdd, refatch }) {
    const [state, setState] = useState()
    const [validate, setValidate] = useState([])
    function submitVal(e) {
        if (validate?.includes(e.target.name)) {
            validate.splice(validate.indexOf(e.target.name), 1)
        }
        if (e.target.name == 'dateOfBirth') {
            setState({ ...state, [e.target.name]: e.target.value })
        } else {
            setState({ ...state, [e.target.name]: e.target.value })
        }
    }
    const { mutate, isLoading } = usePost()
    function getPost() {
        const formdata = new FormData
        let arr = []
        if (state) {
            Validation.forEach((item) => {
                if (!Object.keys(state).includes(item)) {
                    arr.push(item)
                } else if (Object.keys(state).includes(item) && !state[item]) {
                    arr.push(item)
                }
            })
            setValidate(arr)
            if (arr?.length == 0) {
                Object.keys(state).forEach((elem) => {
                    formdata.append(elem, state[elem])
                })
                mutate({
                    url: `/medics/`,
                    data: formdata,
                    onError: error,
                    onSuccess: succes
                })
            }
        }
    }
    function error(e) {
        console.log(e);
        toast.error(`${Object.keys(e.response.data).map(el => errors[el]+',')} mavjud!`, {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    function succes() {
        setAdd(false)
        toast.success("Med konsultant qo'shildi!", {
            position: toast.POSITION.TOP_RIGHT,
        });
        refatch()
    }
    return (
        <div className={s.AddEmpModal_wrapper}>
            {
                isLoading && <div className={s.loader_wrapper} >
                    <Loader />
                </div>
            }
            <div className={s.AddEmpModal} >
                <img onClick={() => setAdd(false)} className={s.cencel_btn} src={cencel} />
                <div className={s.Employee_top} >
                    <div className={s.Employee_left} >
                        {
                            state?.photo ?
                                <div className={s.change_image_wrapper}>
                                    <img className={s.image} src={URL.createObjectURL(state.photo)} />
                                    <div className={s.change_image} >
                                        <label htmlFor="image" >Rasm o'zgartrish</label>
                                        <input onChange={(e) => setState({ ...state, photo: e.target.files[0] })} id='image' hidden type='file' />
                                    </div>
                                </div>

                                :
                                <div className={s.upload_image} >
                                    <label style={validate?.some((el) => el == 'photo') ? { border: '2px solid red' } : null} htmlFor="image" ><img className={s.icon} src={plus} /></label>
                                    <input onChange={(e) => setState({ ...state, photo: e.target.files[0] })} id='image' hidden type='file' />
                                </div>
                        }
                    </div>
                    <div className={s.Employee_right} >
                        <div className={s.Employee_right_top} >
                            <h2 className={s.Employee_left_username} >{state?.first_name} {state?.last_name}</h2>
                            {/* <p className={s.Employee_left_vendor} >Sotuvchi</p> */}
                        </div>
                    </div>
                </div>
                <form className={s.input_form} >
                    <Input submitVal={submitVal} validate={validate} value={state?.first_name} name='first_name' type='text' label='Ism' />
                    <Input submitVal={submitVal} validate={validate} value={state?.last_name} name='last_name' type='text' label='Familiya' />
                    <Input submitVal={submitVal} validate={validate} value={state?.username} name='username' type='text' label='Foydalanuvchi nomi' />
                    <Input submitVal={submitVal} validate={validate} value={state?.phone_number} name='phone_number' type='text' label='Telefon raqam' />
                    <Input submitVal={submitVal} validate={validate} value={state?.phoneNumTwo} name='phoneNumTwo' type='text' label='Telefon raqam 2 ' />
                    <Input submitVal={submitVal} validate={validate} value={state?.address} name='address' type='text' label='Manzil' />
                    <Passport submitVal={submitVal} validate={validate} setState={setState} state={state} value={state?.passport} name='passport' />
                    <Input submitVal={submitVal} validate={validate} value={state?.dateOfBirth} name='dateOfBirth' type='date' label="Tug'ilgan sana" />
                    <Input submitVal={submitVal} validate={validate} value={state?.password} name='password' type='text' label="Ko'd" />
                    <Input submitVal={submitVal} name='degree' value={state?.degree} type='text' label='Ma’lumoti' />
                    <Input submitVal={submitVal} name='share_of_persales_percent' value={state?.share_of_persales_percent} type='number' label='Sotuv ulushi' />
                    <Input submitVal={submitVal} name='university' value={state?.university} type='text' label='Tamomlagan oliygohi' />
                    <Textarea submitVal={submitVal} name='bio' value={state?.bio} />
                </form>
                <div className={s.sub_btns} >
                    <Button type='submit' onClick={() => getPost()} text={'Saqlash'} />
                </div>
                <ToastContainer />
            </div>
        </div>

    )
}