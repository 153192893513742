import React from "react";
import s from './Input.module.scss'
export default function Input({ submitVal,value, disabled,name, max,visiblity ,validate,type,label}) {
    return (
        <div className={s.input_wrapper} >
            <label htmlFor="name" className={s.label} >
                {label}
            </label>
            <input style={validate?.some((el)=> el==name) ? {border:'1px solid red'} : null}  maxLength={max} disabled={disabled} value={value} id="name" name={name} className={s.inputs} type={type} onChange={(e)=>submitVal(e)} />
        </div>
    )
}