import React, { useEffect, useState } from "react";
import s from './Employeemore.module.scss'
import Input from "../../components/Input";
import Button from "../../components/Button";
import Inputdate from "../../components/Inputdate";
import { Select } from "antd";
import Passport from "../../components/Passport";
import Textarea from "../../components/Textarea";
import note from '../../assets/icons/Note_Edit2.svg'
import { getDate } from '../../service/dates'
import { usePost } from "hooks";
import { toast } from 'react-toastify'
import { Link } from "react-router-dom";
import Loader from "components/Loader";
import useGetAll from "hooks/useGetAll";
import SalaryPayMedik from "components/SalaryPayMedik";
import deletes from '../../assets/icons/delete.png'
import x from '../../assets/icons/cancel-button.png'
import useImage from '../../assets/icons/woman2.png'
export default function Employeemore({ data, item, refetch }) {
    const [info, setInfo] = useState(item)
    const [salary, setSalary] = useState(false)
    const [openProd, setOpenProd] = useState(false)
    const [selectProd, setSelectProd] = useState()
    const { Option } = Select
    const [disabled, setDiabled] = useState(true)
    const [val, setVal] = useState({ year: '', month: '', day: '' })
    function submitVal(e) {
        setInfo({ ...info, [e.target.name]: e.target.value })
    }
    const { isLoading: loading, data: product } = useGetAll({
        url: '/products/',
        method: 'get'
    })
    const { mutate, isLoading } = usePost()
    function postdata() {
        delete info.dateOfBirth
        delete info.photo
        const formdata = new FormData()
        Object.keys(info).forEach((item) => {
            formdata.append(item, info[item])
        })
        formdata.append('dateOfBirth', val.year + '-' + val.month + '-' + val.day)
        mutate({
            url: `/medics/${info.id}/`,
            method: 'put',
            data: formdata,
            onSuccess: success,
            onError: error,
        })
    }
    function deleteMed() {
        if (window.confirm('Ushbu xodimni ishdan olishga aminmisiz?')) {
            mutate({
                url: `/medics/${info.id}/`,
                method: 'delete',
                onSuccess: successDelete,
                onError: errorDelete,
            })
        }
    }
    function successDelete(data) {
        window.history.back()
        toast.success("Xodim muvofaqqiyatli ishdan olindi !", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    function errorDelete(data) {
        toast.error("Xodim ishdan olinmadi qaytadan urunib ko'ring!", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    function success() {
        toast.success("Xodim muvoffaqiyatli o'zgartrildi!", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    function error() {
        toast.error("Xodim o'zgartrilmadi!", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    function deleteProduct(e) {
        const formdata = new FormData()
        formdata.append('medic', info.id)
        formdata.append('product', e)
        mutate({
            url: `/medics/products/${info.id}/product/${e}`,
            method: 'delete',
            onSuccess: suc,
        })
    }
    function addProduct(e) {
        const formdata = new FormData()
        formdata.append('medic', info.id)
        formdata.append('product', selectProd)
        mutate({
            url: `/medic-product/`,
            method: 'post',
            data: formdata,
            onSuccess: succ,
        })
    }
    function suc() {
        refetch()
    }
    function succ(e) {
        if (e.data.product && e.data.product == 'med product with this product already exists.') {
            toast.error("Bu mahsulot biriktrilgan med kansultant bor!", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
        setSelectProd()
        setOpenProd(false)
        refetch()
    }
    return (
        <div className={s.Employee} >
            {
                isLoading &&
                <div className={s.loader_back} >
                    <Loader />
                </div>
            }
            <button onClick={() => setDiabled(false)} className={s.edite_btun} >Tahrirlash<img src={note} /> </button>
            <div className={s.Employee_top} >
                <div className={s.Employee_left} >
                    {
                        !info?.photo ?
                            <img src={useImage} alt="user" />
                            :
                            <img
                                src={`https://rizonwebappapi.pythonanywhere.com/${info?.photo}`}
                                alt="userImage"
                            />
                    }
                    <div className={s.Employee_left_right} >
                        <div className={s.Employee_right_top_left} >
                            <input disabled={disabled} className={s.sum} style={{ width: '100px' }} onChange={submitVal} name="share_of_sales_percent" value={info?.share_of_sales_percent} />
                            <p className={s.ball} >Sotuvdan ulushi (%)</p>
                        </div>
                        <div className={s.Employee_right_top_right} >
                            <h2 className={s.sum} >{data?.total_salary?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</h2>
                            <p className={s.ball} >Haqdorligi</p>
                        </div>
                    </div>
                </div>
                <div className={s.Employee_right} >
                    <div className={s.Employee_right_top} >
                        <h2 className={s.Employee_left_username} >{item?.first_name} {item?.last_name}</h2>
                        <p className={s.Employee_left_vendor} >Sotuvchi</p>
                    </div>
                    <div className={s.Employee_right_bottom} >
                        <Link to={`/Medic/salary/${info.id}`} style={{ textDecoration: 'none' }} ><Button text={'Maosh tarixi'} /></Link>
                        <Button onClick={() => setSalary(true)} text={'Maosh to’lash'} />
                    </div>
                </div>
            </div>
            <h1 className={s.edite_btn}  >Ish boshlangan sanasi: </h1>
            <h1 className={s.edite_btn} style={{ marginBottom: 40 }}  >{getDate(item?.date)} </h1>
            <h1 className={s.edite_btn}  >Biriktirilgan mahsulot</h1>
            <div className={s.produts} >
                {
                    data?.medic_products.map((elem, i) => (
                        <button className={s.produts_single} >{elem.product.name}{!disabled && <img onClick={() => deleteProduct(elem.product.id)} className={s.delete_btn} src={deletes} />}</button>
                    ))
                }
                {
                    !disabled && <Button onClick={() => setOpenProd(true)} text={"Mahsulot qo'shish"} />
                }
            </div>
            <h2 className={s.edite_btn} style={{ marginTop: 25 }} >Tahrirlash</h2>
            <form className={s.input_form} >
                <Input submitVal={submitVal} disabled={disabled} type='text' name='first_name' value={info?.first_name} label='Ism' />
                <Input submitVal={submitVal} disabled={disabled} type='text' name='last_name' value={info?.last_name} label='Familiya' />
                <Input submitVal={submitVal} disabled={disabled} max={13} type='text' name='phoneNumTwo' value={info?.phoneNumTwo} label='Telefon raqam 2 ' />
                <Input submitVal={submitVal} disabled={disabled} max={13} type='text' name='phone_number' value={info?.phone_number} label='Telefon raqam' />
                <Input submitVal={submitVal} disabled={disabled} type='text' name='address' value={info?.address} label='Manzil' />
                <Inputdate val={val} setVal={setVal} disabled={disabled} name='dateOfBirth' value={info?.dateOfBirth} />
                <Passport disabled={disabled} name='passport' value={info?.passport} />
                <Input submitVal={submitVal} disabled={disabled} name='degree' value={info?.degree} type='text' label='Ma’lumoti' />
                <Input submitVal={submitVal} disabled={disabled} name='university' value={info?.university} type='text' label='Tamomlagan oliygohi' />
                <Textarea submitVal={submitVal} disabled={disabled} name='bio' value={info?.bio} />
            </form>
            <div className={s.sub_btns} >
                <Button onClick={() => deleteMed()} color='#FF0000' text={'Ishdan olish'} />
                <Button onClick={() => disabled ? null : postdata()} disabled={disabled} text={'Saqlash'} />
            </div>
            {
                salary && <SalaryPayMedik setSalary={setSalary} />
            }
            {
                openProd && <div className={s.openProd_wrapper} >
                    <div className={s.openProd} >
                        <img onClick={() => setOpenProd(false)} className={s.cancel_btn} src={x} />
                        <select onChange={(e) => setSelectProd(e.target.value)} >
                            <option >Mahsulot</option>
                            {
                                product.map((elem, i) => (
                                    <option value={elem.id} >{elem.name}</option>
                                ))
                            }
                        </select>
                        <Button disabled={selectProd ? false : true} onClick={() => selectProd ? addProduct() : null} text={"Qo'shish"} />
                    </div>
                </div>
            }

        </div>
    )
}