import s from './Dashboard.module.scss'
import logo from '../../assets/icons/newone.png'
import { NavLink, useNavigate } from 'react-router-dom'
import { menuItem, helpers } from '../../assets/db'
import CloseIcon from '../../assets/icons/close.png'
import profile from 'assets/icons/profileIcon.png'
import Logo from 'assets/icons/logo'
export default function Dashboard({ type, dashboard, setDashboard }) {

    const navigate = useNavigate()

    return (
        <>
            <div className={`${s.Dashboard} ${dashboard ? "" : s.close}`}>
                <div className={s.Black_back_cover} id='cover' onClick={(e) => {
                    if (e.target.id === 'cover') {
                        setDashboard(false)
                    }
                }}></div>
                <div className={s.Images_wrapper} onClick={() => setDashboard(false)}>
                    <div className={s.Close_icon_wrapper}>
                        <img src={CloseIcon} alt="" />
                    </div>
                    <img className={s.Dashboard_logo} src={logo} />
                </div>
                <div className={s.Dashboard_menu_first}>
                    <h1 className={s.Dashboard_menu_text}>Menu</h1>
                    <ul className={s.Dashboard_menu_item_wrapper}>
                        {menuItem[type]?.map((elem, i) => (
                            <NavLink key={i} className={s.Dashboard_menu_item} to={elem.path}>
                                {" "}
                                <img src={elem.icon} /> {elem.name}
                            </NavLink>
                        ))}
                    </ul>
                </div>
                <div className={s.Dashboard_menu}>
                    <h1 className={s.Dashboard_menu_text}>Yordam</h1>
                    <ul className={s.Dashboard_menu_item_wrapper}>
                        {helpers[type]?.map((elem, i) => (
                            <li key={i} className={s.Dashboard_menu_item} onClick={() => navigate(elem?.path)}>
                                <img src={elem.icon} /> {elem.name}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className={s.menu_mobile} >
                <ul className={s.Dashboard_menu_item_wrapper}>
                    {menuItem[type]?.map((elem, i) => (
                        <NavLink key={i} className={s.Dashboard_menu_item} to={elem.path}>
                            {" "}
                            <img src={elem.icon} />
                        </NavLink>
                    ))}
                    <NavLink className={s.Dashboard_menu_item} to={'/Profile'}>
                        {" "}
                        <img src={profile} />
                    </NavLink>
                </ul>
            </div>
        </>

    );
}