import Button from "components/Button";
import Loader from "components/Loader";
import { useGetAll, usePost } from "hooks";
import React, { useState } from "react";
import s from './DiscountModal.module.scss'
import {toast} from 'react-toastify'
import x from '././../../assets/icons/cancel-button.png'
export default function DiscountModal({setOpen}) {
    const [state,setstate]=useState({})
    const {data,isLoading}=useGetAll({
        url:'/products/',
    })
    const {mutate}=usePost()
    function PostData() {
        const formdata = new FormData()
        Object.keys(state).forEach((item)=>{
            formdata.append(item,state[item])
        })
        mutate({
            url:'products/discount/create/',
            method:'post',
            data:formdata,
            onSuccess:succes,
            onError:error
        })
    }
    function succes(params) {
        setOpen(false)
        toast.success("Aksiya muvoffaqiyatli yaratildi!", {
            position: toast.POSITION.TOP_RIGHT,
        });
        
    }
    function error(params) {
        toast.error("Aksiya yaratilmadi!", {
            position: toast.POSITION.TOP_RIGHT,
        });
        
    }
    if ( isLoading) return <Loader/>
    return (
        <div className={s.DiscountModal} >
            <img onClick={()=>setOpen(false)} className={s.cencel} src={x} />
            <h1 className={s.DiscountModal_title} >Aksiya belgilash</h1>
            <div className={s.form} >
                <label>
                    <p>Mahsulot to’liq nomi</p>
                    <select onChange={(e)=>setstate({...state,product:e.target.value})} >
                    <option>Mahsulot</option>
                        {
                            data?.map((elem)=>(
                                <option value={elem.id} >{elem.name}</option>
                            ))
                        }
                    </select>
                </label>
                <div className={s.form_input} >
                    <label>
                        <p>Minimum harid soni</p>
                        <input onChange={(e)=>setstate({...state,amount:e.target.value})} type={'number'} />
                    </label>
                    <label>
                        <p>Bonus</p>
                        <input onChange={(e)=>setstate({...state,discount:e.target.value})} type={'number'} />
                    </label>
                    <label>
                        <p>Boshlanish sanasi</p>
                        <input onChange={(e)=>setstate({...state,startDate:e.target.value})} type={'date'} />
                    </label>
                    <label>
                        <p>Tugash sanasi</p>
                        <input onChange={(e)=>setstate({...state,endDate:e.target.value})} type={'date'} />
                    </label>
                </div>
            </div>
            <Button onClick={()=> state?.amount && state?.product && state?.endDate && state?.startDate && state?.discount ? PostData() : null } disabled={state?.amount && state?.product && state?.endDate && state?.startDate && state?.discount ? false : true} text={'Belgilash'} />
        </div>
    )

}