import React from "react";
import s from './ViewHistory.module.scss'
import Table from "../../../../components/Table/Table";
import search from '../../../../assets/icons/Search_Magnifying_Glass.png'
import { elements } from "chart.js";
import { Link } from "react-router-dom";
import Button from "../../../../components/Button";
import GetAll from "../../../../modules/GetAll";
import Loader from "../../../../components/Loader";
import {generateDate} from '../../../../service/dates'
export default function ViewHistory() {
    const column = [
        {
            title: 'Shartnoma raqami',
            key: 'id',
        },

        {
            title: 'Sana',
            key: 'dateTime',
            render: (item) => {
                return (
                    <span>{generateDate(item)}</span>
                )
            }
        },
        {
            title: 'Dori nomi',
            key: 'product',
            render: (item) => {
                return (
                    <span>{item?.name}</span>
                )
            }
        },
        {
            title: 'Umumiy Miqdori',
            key: 'amount',
        },
        {
            title: 'Summa',
            key: 'summa',
            render: (item) => {
                return (
                    <span>{item.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + 'uzs'}</span>
                )
            }
        },
    ]
    const data = [
        {
            date: '11/11/2022',
            salary: '7 300 000uzs',
            late: '2 000 000uzs',
            paying: '5 300 000uzs',
            pay: 1,
            id: 2
        },
        {
            date: '11/11/2022',
            salary: '7 300 000uzs',
            late: '2 000 000uzs',
            paying: '5 300 000uzs',
            pay: 1,
            id: 2
        },
        {
            date: '11/11/2022',
            salary: '7 300 000uzs',
            late: '2 000 000uzs',
            paying: '5 300 000uzs',
            pay: 1,
            id: 2
        },
        {
            date: '11/11/2022',
            salary: '7 300 000uzs',
            late: '2 000 000uzs',
            paying: '5 300 000uzs',
            pay: 1,
            id: 2
        },
    ]
    return (
        <GetAll name='history-product' url={'/employees-products/'} >
            {({ items }) => {
                if (items.isLoading) return  <Loader/>
                return (
                    <div className={s.ViewHistory}>
                        <div className="container" >
                            <div className={s.ViewHistory_table} >
                                <Table column={column} data={items?.data?.sales_history} />
                            </div>
                        </div>
                    </div>
                )
            }}
        </GetAll>

    )
}