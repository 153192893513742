import React, { useState } from "react";
import s from './InnerPageCust.module.scss'
import User from "container/User";
import UserTabel from "container/UserTabel";
import GetAll from "modules/GetAll";
import { DateInput } from "components/DateInput";
import { getLastDay, getLastMonth, getLastYear, thisMonth, getThisDay, getThisYear } from 'service/dates'
import { useParams } from "react-router-dom";
import Loader from "components/Loader";
import Input from "components/Input";
export default function InnerPageCust() {
    const [filter, setFIlter] = useState(thisMonth())
    const { id } = useParams()
    let filters = [
      { name: 'O’tgan Oy', val: getLastMonth().slice(- 2) == "00" ? `${getLastYear()}-12` : getLastMonth() },
      { name: 'Hozirgi oy', val: thisMonth() },
      { name: 'O’tgan yil', val: getLastYear() },
      { name: 'Hozirgi yil', val: getThisYear() }
    ]
    function choose(e) {
        setFIlter(e)
    }
    return (
        <GetAll url={`/manager-users/${id}/month/${filter}`} >
            {({ items }) => {

                if (items.isLoading) return <Loader/>
                console.log(items?.data);
                return (
                  <div className={s.InnerPageCust}>
                    <div className={s.Button_wrapper}>
                      <div className={s.filetr_part}>
                        {filters.map((item) => (
                          <button
                            onClick={() => choose(item.val)}
                            className={
                              filter == item.val ? `${s.chosen}` : s.btns
                            }
                          >
                            {item.name}
                          </button>
                        ))}
                      </div>
                      <DateInput change={choose} />
                    </div>
                    <User data={items?.data?.user} />
                    <div className={s.extra_info}>
                      {/* <div> */}
                      <Input
                        disabled={true}
                        value={items?.data?.user?.user_status}
                        type="text"
                        label="Status"
                      />
                      <Input
                        disabled={true}
                        value={items?.data?.user?.user_score}
                        type="text"
                        label="Shaxsiy aylanma"
                      />
                      <Input
                        disabled={true}
                        value={items?.data?.user?.personal_bonus}
                        type="text"
                        label="Shaxsiy bonus"
                      />
                      <Input
                        disabled={true}
                        value={items?.data?.user?.user_tree_score}
                        type="text"
                        label="Jamoaviy aylanma"
                      />
                      {/* </div>
                      <div> */}
                      <Input
                        disabled={true}
                        value={items?.data?.user?.collective_bonus_amount}
                        type="text"
                        label="Jamoaviy bonus"
                      />
                      <Input
                        disabled={true}
                        value={items?.data?.user?.forMentorship}
                        type="text"
                        label="Ustozlik bonusi"
                      />
                      <Input
                        disabled={true}
                        value={items?.data?.user?.salary}
                        type="text"
                        label="Maosh"
                      />
                      <Input
                        disabled={true}
                        value={items?.data?.user?.paid}
                        type="text"
                        label="To'landi"
                      />
                      <Input
                        disabled={true}
                        value={items?.data?.user?.debt}
                        type="text"
                        label="Qarzdorlik"
                      />
                      {/* </div> */}
                    </div>
                    {/* {items?.data?.user_tree
                      ? Object.keys(items?.data?.user_tree).map((elem) => (
                          <>
                            <h1 className={s.InnerPageCust_title}>
                              {elem} Shajara
                            </h1>
                            <UserTabel data={items?.data?.user_tree[elem]} />
                          </>
                        ))
                      : null} */}
                  </div>
                );
            }}

        </GetAll>

    )
}