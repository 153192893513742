import React from "react";
import Chart from "chart.js/auto";
import { Bar, Pie } from "react-chartjs-2";
import s from './BarChart.module.scss'
import { Link } from "react-router-dom";
const BarChart = ({ elem }) => {
  const labels = Object.keys(elem?.info);
  const colorArray = [];
  function generateUniqueColor(length) {
    let arr = []
    for (let i = 0; i < length; i++) {
      let red, green, blue, alpha;
      let isUnique = false;
      while (!isUnique) {
        red = Math.floor(Math.random() * 256);
        green = Math.floor(Math.random() * 256);
        blue = Math.floor(Math.random() * 256);
        alpha = Math.random();
        var color = 'rgba(' + red + ',' + green + ',' + blue + ',' + alpha + ')';
        if (!colorArray.includes(color)) {
          colorArray.push(color);
          isUnique = true;
        }
      }
      arr.push(color)
    }
    return arr;
  }
  const data = {
    labels: labels,
    datasets: [
      {
        data: elem.title == 'Mahsulot sotuv ulushi' ? Object.values(elem.info).map(el => el.product_sale_amount) : Object.values(elem.info),
      },
    ],
  };
  let config2 = {
    indexAxis: 'y',
    type: 'line',
    data: {
      labels: labels,
      datasets: [
        {
          data: elem.title == 'Mahsulot sotuv ulushi' ? Object.values(elem.info).map(el => el.product_sale_amount) : Object.values(elem.info),
        },
      ],
    },
    options: {
      indexAxis: 'y',
      plugins: {
          legend: {
              display: false
          }
      }
  }
}
  const config = {
    type: 'pie',
    data: data,
    options: {
      responsive: true,
      plugins: {
        legend: {
          position: 'bottom',
        },
        title: {
          display: false,
        }
      }
    },
  };
  return (
    <div className={s.Barchart_wrapper} >
      {
        elem.id ?
          <Link className={s.btn_more} to={`/Branches/${elem.id}`} >To’liq</Link>
          :
          null
      }
      <h1 className={s.Barchart_wrapper_title} >{elem.title}</h1>
      <p className={s.Barchart_wrapper_text} >{elem.inner} </p>
      {
        elem.title == 'Mahsulot sotuv ulushi' ?
          <Pie {...config} />
          :
          <Bar height={'132px'} {...config2} />
      }
      {/* <p className={s.Barchart_wrapper_text_two} >Lorem ipsum dolor sit amet consectetur. Sit ante curabitur diam lectus laoreet. Integer tellus ullamcorper sed sagittis venenatis.</p> */}
    </div>
  );
};

export default BarChart;
