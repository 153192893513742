import React from "react";
import card from '../public/images/card.png'

const Data = () => {
  const info = [
    { img: card, price: "119 000 uzs", title: "Argeta 100ml", id: 1 },
    { img: card, price: "129 000 uzs", title: "Argeta 130ml", id: 2 },
    { img: card, price: "139 000 uzs", title: "Argeta 110ml", id: 3 },
    { img: card, price: "149 000 uzs", title: "Argeta 140ml", id: 4 },
    { img: card, price: "159 000 uzs", title: "Argeta 200ml", id: 5 },
  ];

  return { info };
};

export default Data;
