import React, { useState } from "react";
import s from './MiniShop.module.scss';
import Card from "components/Card";
import { card } from 'assets/db'
import BarChart from 'components/BarChart'
import { Data } from 'assets/db'
import Button from "components/Button";
import calendar from 'assets/icons/Calendar_Event.png'
import GetAll from "modules/GetAll";
import Loader from "components/Loader";
import card1 from 'assets/icons/Group 19 (1).png'
import card2 from 'assets/icons/Group 19 (2).png'
import card3 from 'assets/icons/Group 19 (3).png'
import { Link } from "react-router-dom";
import LineChartBig from "components/LineChartBig";
import TabelMiniShop from "components/TabelMiniShop";
import { DateInput } from "components/DateInput";
import { getLastMonth, getLastYear, thisMonth, getThisYear, formatDate } from 'service/dates'
export default function MiniShop() {
    const [filter, setFIlter] = useState(thisMonth())
    let filters = [
        { name: 'O’tgan Oy', val: getLastMonth().slice(- 2) == "00" ? `${getLastYear()}-12` : getLastMonth() },
        { name: 'Hozirgi oy', val: thisMonth() },
        { name: 'O’tgan yil', val: getLastYear() },
        { name: 'Hozirgi yil', val: getThisYear() }
    ]
    function choose(e) {
        setFIlter(e)
    }
    function top() {
        window.scrollTo(0, 0)
    }
    return (
        <GetAll name={'Branches_page'} url={`/manager-mini-warehouses/month/${filter}/`} >
            {({ items }) => {
                function getData(data) {
                    if (data && data?.data?.warehouses) {
                        let array = []
                        Object.keys(data?.data?.warehouses).map((item) => {
                            array.push({
                                title: item,
                                inner: '*Lorem ipsum dolor sit amet consectetur. Sit ante curabitur diam lectus laoreet. ',
                                info: formatDate(items?.data?.warehouses[item].sales_by_date),
                                id: items?.data?.warehouses[item].warehouse?.id
                            })
                        })
                        return array
                    }
                }
                const card = [
                    {
                        icon: card1,
                        title: 'Umumiy Savdo',
                        inner: items?.data?.total_sales_summa.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
                        val: "ball"
                    },
                    {
                        icon: card2,
                        title: 'Mijozlar soni',
                        inner: items?.data?.total_users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
                        val: 'ta'
                    },
                    {
                        icon: card3,
                        title: 'Umumiy Kupon',
                        inner: items?.data?.total_coupon.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
                        val: 'ball'
                    },
                    // {
                    //     icon: card3,
                    //     title: 'Umumiy ball',
                    //     inner: items?.data?.total_ball.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
                    //     val: 'ta'
                    // },
                ]
                if (items.isLoading) return <Loader />
                return (
                    <div onLoad={top} className={s.Branches} >
                        <div className="container" >
                            <div className={s.Button_wrapper} >
                                <div className={s.filetr_part} >
                                    {
                                        filters.map((item) => (
                                            <button onClick={() => choose(item.val)} className={filter == item.val ? `${s.chosen}` : s.btns} >{item.name}</button>
                                        ))
                                    }
                                </div>
                                <DateInput change={choose} />
                            </div>
                            <div className={s.cards_box} >
                                {
                                    card?.map((elem, i) => (
                                        <Card item={elem} key={i} />
                                    ))
                                }
                            </div>
                            <div className={s.charts_box} >
                                <h3 className={s.cards_box_title} >Mini do'konlar savdo ulushi</h3> 
                                <div className={s.charts_box_inner} >
                                    <LineChartBig elem={items?.data?.warehouses} />
                                </div>
                            </div>
                           <Link to={'/addminishop'} style={{textDecoration:'none',}} ><Button text={"Mini do'kon qo'shish"} /></Link> 
                            <div style={{marginTop:'20px'}} className={s.charts_box} >
                                {/* <h3 className={s.cards_box_title} >Fililallar savdo aylanmalari (uzs) <Link to={'/BranchAdd'} style={{textDecoration:'none'}} ><Button text={"Filial qo'shish"} /></Link>  </h3>  */}
                                <TabelMiniShop data={items?.data?.warehouses_info} />
                            </div>
                        </div>
                    </div>
                )
            }}
        </GetAll>


    )
}