
import { useState } from "react";
import s from "../Product.module.scss";
import ProductDistribution from '../../../container/ProductDistribution'
import GetAll from "modules/GetAll";
import Loader from "components/Loader";
export default function InnerTableProduct() {
  return (
    <GetAll name={'product-page'} url={'/admin-products'} >
      {({ items }) => {
        if (items.isLoading) return <Loader/>
        return (
          <div className={s.Product} >
            <div className="container" >
              <h1 className={s.Table_title} >Mahsulot tarqatish </h1>
              <ProductDistribution history={true} data={items?.data?.product_distribution} />
            </div>
          </div>
        )
      }}
    </GetAll>
  );
}
