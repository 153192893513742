import React, { useState } from "react";
import s from './SallProduct.module.scss'
import Button from "../Button";
import Input from "../Input";
import send from '../../assets/icons/Share_iOS_Export.svg'
import usePost from "../../hooks/usePost";
import { useGetAll } from "../../hooks";
import Loader from "../Loader";
import x from '../../assets/icons/cancel-button.png'
import { toast } from 'react-toastify'
export default function Changeshajara({ icon,refatch }) {
    const [state, setState] = useState()
    const [offerer, setOfferer] = useState({
        first_name: '',
        last_name: ''
    })
    const [invited, setInvited] = useState({
        first_name: '',
        last_name: ''
    })
    const [modal, setModal] = useState(false)
    function submitVal(e) {
        setState({ ...state, [e.target.name]: e.target.value })
    }
    const { mutate, isLoading } = usePost()
    function GetDataOffer(e) {
        mutate({
            url: `users/${state?.offerer}/user_id/`,
            method: 'get',
            onSuccess: success,
            onError: onErrorr
        })
    }
    function onErrorr(e) {
        toast.error("Taklif qiluvchi topilmadi!", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    function success(data) {
        setOfferer(data.data)
        GetDataInvitor()
    }
    function GetDataInvitor(e) {
        mutate({
            url: `users/${state?.invited}/user_id/`,
            method: 'get',
            onSuccess: successin,
            onError: onErrorrin
        })
    }
    function onErrorrin(e) {
        toast.error("Qabul qiluvchi topilmadi!", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    function successin(data) {
        setInvited(data.data)
    }
    // post data
    function postData() {
        const formdata = new FormData
        formdata.append('offerer', state.offerer)
        formdata.append('invited', state.invited)
        mutate({
            url: `/admin-change-user-tree/`,
            method: 'put',
            data: formdata,
            onSuccess: successpost,
            onError: onErrorrpost
        })
    }
    function onErrorrpost(e) {
        toast.error("Shajara o'zgartrilmadi!", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    function successpost(data) {
        toast.success("Shajara o'zgartrildi!", {
            position: toast.POSITION.TOP_RIGHT,
        });
        setModal(false)
        setInvited({
            first_name: '',
            last_name: ''
        })
        setOfferer({
            first_name: '',
            last_name: ''
        })
        setState()
        // refatch()
    }
    return (
        <div className={s.SallProduct} >
            {
                isLoading ?
                    <div className={s.loaders_back} >
                        <Loader />
                    </div>
                    :
                    null
            }
            {
                modal ?
                    <div className={s.SallProduct_modal_back} >
                        <div className={s.SallProduct_modal}>
                            <img onClick={() => setModal(false)} className={s.SallProduct_modal_back_cencel} src={x} />
                            <div className={s.SallProduct_modal_inner} >
                                <Input submitVal={submitVal} disabled={false} name='offerer' type='text' label='Taklif qiluvchi mijoz ID' />
                                <Input submitVal={submitVal} disabled={false} name='invited' type='text' label='Qabul qiluvchi mijoz ID' />
                                <Input submitVal={submitVal} disabled={true} value={offerer?.first_name + ' ' + offerer?.last_name} name='first_name' type='text' label='Taklif qiluvchi Familiya Ism' />
                                <Input disabled={true} value={invited?.first_name + ' ' + invited?.last_name} name='first_name' type='text' label='Qabul qiluvchi Familiya Ism' />
                                <Button onClick={() => state?.offerer && state?.invited ? GetDataOffer() : null} disabled={state?.offerer && state?.invited ? false : true} text={'Mijozlarni qidirish'}  />
                            </div>
                            <div className={s.SallProduct_modal_bottom} >
                                <Button onClick={() => offerer?.first_name && invited?.first_name ? postData() : null} disabled={offerer?.first_name && invited?.first_name ? false : true} text={"Shajara o'zgartrish"}     />
                            </div>
                        </div>
                    </div>
                    :
                    null
            }
            <Button onClick={() => setModal(true)} text={"Shajara o'zgartrish"} icon={icon} />
        </div>
    )
}