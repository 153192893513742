import React from "react";
import s from './ProductDistribution.module.scss'
import Table from "../../components/Table/Table";
import add from '../../assets/icons/Add_Plus_Circle.svg'
import history from '../../assets/icons/Archive.svg'
import send from '../../assets/icons/Share_iOS_Export.svg'
import { Link } from "react-router-dom";
import { generateDate } from '../../service/dates'
import Button from "../../components/Button";
import SallProduct from '../../components/SallProduct'
import SallProductAdmin from "components/SallProductAdmin";
import SallProductminiShop from "components/SallProductminiShop";
import { color } from "framer-motion";
export default function ProductDistribution({ employee, history, data }) {
    console.log('data', data);
    const column = [
      {
        title: "Sana",
        key: "dateTime",
        render: (item) => {
          return <span>{generateDate(item)}</span>;
        },
      },
      {
        title: "Dori nomi",
        key: "product",
        render: (item) => {
          return <span>{item.name}</span>;
        },
      },
      {
        title: "Miqdori",
        key: "amount",
        render: (value, data) => {
          // console.log('val',value);
          return (
            <span style={{ color: value > 0 ? "green" : "red" }}>{value}</span>
          );
        },
      },
      {
        title: "Filial",
        key: "warehouse",
        render: (item) => {
          return <span>{item.name}</span>;
        },
      },
      // {
      //     title: 'Miqdori',
      //     key: 'amount',
      // },
      {
        title: "Umumiy summa",
        key: "summa",
        render: (item) => {
          return (
            <span>
              {item.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "uzs"}
            </span>
          );
        },
      },
    ];
    const column2 = [
        {
            title: 'Sana',
            key: 'dateTime',
            render: (item) => {
                return (
                    <span>{generateDate(item)}</span>
                )
            }
        },
        {
            title: 'Dori nomi',
            key: 'product',
            render: (item) => {
                return (
                    <span>{item.name}</span>
                )
            }
        },
        {
            title: 'Miqdori',
            key: 'amount',
            render:(value, data)=>{
                // console.log('val',value);
                return (
                  <span style={{ color: value > 0 ? "green" : "red" }}>
                    {value}
                  </span>
                );
            }
        },
        {
            title: 'Id',
            render:(el, data)=><span>{data.user.user_id}</span>
        },
        {
            title: 'Umumiy summa',
            key: 'summa',
            render: (item) => {
                return (
                    <span>{item.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + 'uzs'}</span>
                )
            }
        },
    ]

    return (
        <div className={s.ProductDistribution}>
            <div className={s.ProductDistribution_top} >
                <h1 className={s.ProductDistribution_title} >Tarixi</h1>
                {
                    history ?
                        null :
                        <div className={s.ProductDistribution_top_button} >
                            {
                                employee ?
                                    <Link to={'/Product/history'} style={{ textDecoration: 'none' }} ><Button text={'Tarixni ko’rish'} icon={history} /></Link>
                                    :
                                    <Link to={'/Product/history/admin'} style={{ textDecoration: 'none' }} ><Button text={'Tarixni ko’rish'} icon={history} /></Link>
                            }
                            {
                                employee ?
                                    <>
                                        <SallProduct icon={send} />
                                        <SallProductminiShop icon={send} />
                                    </>
                                    :
                                    <SallProductAdmin icon={send} />
                            }
                        </div>

                }

            </div>
            <div className={s.ProductDistribution_table} >
                <Table numbers column={employee ? column2 : column} data={data} />
            </div>
        </div>
    )

}