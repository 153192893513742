import React from 'react'
import s from "./Note.module.scss"
import check from "../../assets/icons/chack.png";

function Note({item}) {
  return (
    <div className="container">
      <div className={s.Note}>
        <h2>
          {item?.title} <img src={check} alt="" />
        </h2>
        <h1>
          {item?.message}
        </h1>
        <h3>11.04.2023</h3>
      </div>
    </div>
  );
}

export default Note