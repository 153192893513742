import React, { useState } from "react";
import s from './PromotionItem.module.scss'
import Input from "components/Input";
import plus from '../../assets/icons/Add_Plus_Circle2.svg'
import Button from "components/Button";
import { toast } from 'react-toastify'
import { usePost } from "hooks";
import x from '../../assets/icons/cancel-button.png'
import Loader from "components/Loader";
export default function PromotionItem({setAdd,reFatch}) {
    const [state, setState] = useState()
    function submitVal(e) {
        setState({ ...state, [e.target.name]: e.target.value })
    }
    const { mutate, isLoading } = usePost()
    function postData() {
        const formdata = new FormData()
        Object.keys(state).forEach((elem) => {
            formdata.append(elem, state[elem])
        })
        mutate({
            url: '/admin-promotions/',
            method: 'post',
            data: formdata,
            onSuccess: success,
            onError: error
        })
    }
    function success(params) {
        toast.success("Mahsulot qo'shildi", {
            position: toast.POSITION.TOP_RIGHT,
        });
        setAdd(false)
        reFatch()
    }
    function error(params) {
        toast.error("Mahsulot qo'shilmadi", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    return (
        <div className={s.PromotionItem} >
            {
                isLoading && <div className={s.loader_wrapper} >
                    <Loader />
                </div>
            }
            <img onClick={()=>setAdd(false)} className={s.cencel} src={x} />
            <h1 className={s.PromotionItem_title} >Sovrin qo’shish</h1>
            <div className={s.PromotionItem_input_wrapper} >
                <Input submitVal={submitVal} label="Mahsulot to’liq nomi" value={state?.name} name="name" />
                <Input submitVal={submitVal} label="Kupon" value={state?.price} name="coupon" />
                <Input submitVal={submitVal} label="Mahsulot soni" value={state?.amount} name="amount" />
            </div>
            <div className={s.Employee_left} >
                {
                    state?.photo ?
                        <div className={s.change_image_wrapper}>
                            <img className={s.image} src={URL.createObjectURL(state.photo)} />
                            <div className={s.change_image} >
                                <label htmlFor="image" >Rasm o'zgartrish</label>
                                <input onChange={(e) => setState({ ...state, photo: e.target.files[0] })} id='image' hidden type='file' />
                            </div>
                        </div>
                        :
                        <div className={s.upload_image} >
                            <label htmlFor="image" ><img className={s.icon} src={plus} /></label>
                            <input onChange={(e) => setState({ ...state, photo: e.target.files[0] })} id='image' hidden type='file' />
                        </div>
                }
            </div>
            <Button onClick={() => postData()} text={"Qo'shish"} />
        </div>
    )
}