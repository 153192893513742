import { useState } from "react";
import http from "../service/http";
const usePost = () => {
  const [isLoading, setIsLoading] = useState(false);
  const mutate = async ({
    method = "post",
    url,
    data,
    onSuccess = () => {},
    onError = () => {},
  }) => {
    setIsLoading(true);
    await http[method](url,data)
      .then((res) => {
        setIsLoading(false);
        onSuccess(res)
      })
      .catch((err) => {
        setIsLoading(false);
        onError(err);
      });
  };
  return { mutate, isLoading,};
};
export default usePost;
