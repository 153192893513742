import { useGetAll } from "../../hooks";
import React ,{useEffect}from "react";

const GetAll = ({ children, name, url, dataKey, params, queryParams, onSuccess = () => { }, onError = () => { } }) => {
	const data = useGetAll({ name, url, params, onSuccess, onError, queryParams });
	useEffect(() => {
		if (data?.data?.sale_link) {
		window.localStorage.setItem('link', data?.data?.sale_link)
		}
	}, [data?.data])
	return <div>{children({ items: data })}</div>;
};
export default GetAll;
