import React, { Profiler } from "react";
import { useParams } from "react-router-dom";
import Data from "../components/Data";
import styles from "./Batafsil.module.css";
import image from "../public/images/bg1.png";
import Comments from "../components/Comments";
import Products from "../components/Products";
import GetAll from "../hooks/GetAll";
import BuyProd from "../components/BuyProd";
import Loader from "components/Loader";
const Batafsil = () => {
  const { id } = useParams();
  const { info } = Data();
  const CardInfo = info.filter((item) => {
    return item.id == id;
  });

  const { img, price, title } = CardInfo;
  const { data, loading } = GetAll({
    url: `/landing-products/${id}`
  })
  console.log(data);
  if (loading) return <Loader/>
  return (
    <div>
      <div className={styles.top} >
      <div className={`${styles.header} container2`}>
          <div className={styles.left}>
            <h1 className={styles.title}>{data?.product?.name}</h1>
            <p className={styles.description}>{data?.product?.about}</p>
            <p className={styles.price}>Narxi: {data?.product?.extraPrice}</p>
            <BuyProd warehouses={data?.warehouses} id={data?.product?.id}/>
          </div>
          <div className={styles.right}>
            <img src={'https://rizonwebappapi.pythonanywhere.com/'+data?.product?.photo_link}  />
          </div>
      </div>
      </div>


      <div className={`${styles.dflex} container2 `}>
        <div>
          <h1>
            Turi: <br /> {data?.product?.product_type}
          </h1>
        </div>
        <div>
          <h1>
            Yaroqlilik muddati: <br />
            {data?.product?.expiration_date}
          </h1>
        </div>
        <div>
          <h1>
            Reyting: <br />
            <span className={styles.stars}>{new Array(data?.product?.rate).fill('hello').map((el)=>(
              <>★</>
            ))}</span>
          </h1>
        </div>
      </div>

      <div className={`${styles.infos} container2 `}>
        <h1 className={styles.titles}>Tarkibi</h1>
        <p
          className={styles.description}
          // style={{
          //   lineHeight: " 40px",
          // }}
        >{data?.product?.about}</p>
      </div>

      <div className="container2">
        <h1 className={styles.titles} style={{ textAlign: "center" }}>
          Tavsiya qiladi!
        </h1>
        {
          data?.comments.map((el, i)=>{
            return <Comments item={el} />
          })
        }
        <div className={styles.parentBtn}>
          <button className={styles.btn_all}>Barchasi</button>
        </div>
      </div>
      <div className="container2">
        <h1
          className={styles.titles}
          // style={{ textAlign: "center", margin: "50px 0" }}
        >
          O’xshash dorilar
        </h1>
        <Products data={data?.like_products}/>
      </div>
    </div>
  );
};

export default Batafsil;
