import React, { useState } from "react";
import s from './Actions.module.scss'
import Card from "../../components/Card";
import { cardAction } from '../../assets/db'
import PromotionTable from "../../container/PromotionTable/PromotionTable";
import ListCostumers from "../../container/ListCostumers";
import GetAll from "modules/GetAll";
import card1 from '../../assets/icons/Group 19 (1).png'
import card2 from '../../assets/icons/Group 19 (2).png'
import card3 from '../../assets/icons/Group 19 (3).png'
import Loader from "components/Loader";
export default function Actions() {
    return (
        <GetAll url={'/admin-discounts/'}>
            {({ items }) => {
                const card = [
                    {
                        icon: card1,
                        title: 'Umumiy Savdo',
                        inner: items?.data?.total_sales_summa.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
                        val: "ball"
                    },
                    {
                        icon: card2,
                        title: 'Mijozlar soni',
                        inner: items?.data?.total_users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
                        val: 'ta'
                    },
                    {
                        icon: card3,
                        title: 'Umumiy Kupon',
                        inner: items?.data?.total_coupon.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
                        val: 'ball'
                    },
                    {
                        icon: card3,
                        title: 'Umumiy ball',
                        inner: items?.data?.total_ball.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "),
                        val: 'ta'
                    },
                ]
                if (items.isLoading) return <Loader/>
                return (
                    <div className={s.Actions} >
                        <div className="container" >
                            <div className={s.card_wrapper} >
                                <div className={s.cards_box} >
                                    {
                                        card?.map((elem, i) => (
                                            <Card item={elem} key={i} />
                                        ))
                                    }
                                </div>
                                <div className={s.setting_up} >Mijozlar uchun aksiya belgilash</div>
                            </div>
                            <h1 className={s.promotionTable_title} >Mahsulotlar ro’yxati</h1>
                            <PromotionTable data={items?.data?.dicounts} />
                            {/* <h1 className={s.promotionTable_title} >Mijozlar ro’yxati</h1>
                            <ListCostumers /> */}
                        </div>
                    </div>
                )
            }}
        </GetAll>


    )
}