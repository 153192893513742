import React, { useState, useEffect } from "react";
import http from "../service/http";
export default function GetAll({ url }) {
    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()
    async function getdata() {
        setLoading(true)
        await http.get(url)
        .then((res)=>{
            setData(res.data)
            setLoading(false)
        })
        .catch((err)=>{
            setLoading(false)
            setError(err)
        })
    }
    useEffect(()=>{
        getdata()
    },[url])
    return {data,loading,error,reFatch:getdata}
}