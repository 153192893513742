import React, { useState } from "react";
import "./Card.css";
import flag from "../../public/images/flag.png";
import LogoutIcon from "@mui/icons-material/Logout";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { Link } from "react-router-dom";
import Modal from "components/Modal";

function Card({ id, img, title, price, onClick}) {
  return (
    <div className="products_card" key={id} onClick={onClick}>
      <div className="products_card_img">
        <div className="bg"></div>
        <div className="bg-text">
        </div>
        <Link to={`/product/${id}`} >
          <button className="bg-btn">Batafsil</button>
        </Link>
        <div className="bg-icons">
          {/* <LogoutIcon className="icon" /> */}
          {/* <FavoriteBorderIcon className="icon" /> */}
          {/* <ShoppingCartOutlinedIcon className="icon" /> */}
        </div>
        <img src={img} alt="" className="medicen_img" />
      </div>
      <div className="products_card_text_main">
        <div className="products_card_text">
          <h1>{title}</h1>
          <p>{price}</p>
        </div>
        <div className="bg-stars">
          <p>★ ★ ★ ★ ✰</p>
        </div>
      </div>
    </div>
  );
}

export default Card;
