import React, { useState } from "react";
import s from './VewProduct.module.scss'
import AddProduct from "components/addProduct/AddProduct";
import { useGetAll, usePost } from "hooks";
import { toast } from 'react-toastify'
import {  useNavigate, useParams } from "react-router-dom";
import Loader from "components/Loader";
import Button from "components/Button";
import ViewProductImages from "components/ViewProductImages/ViewProduct";
export default function VewProduct() {
    const [state, setState] = useState({})
    const [image,setImage]=useState(true)
    const {id}=useParams()
    const { mutate, isLoading } = usePost()
    const { isLoading:loading}=useGetAll({
        url:`/products/${id}`,
        onSuccess:setState
    })
    function changeImage(e) {
        setState({...state,photo_link:e})
        setImage(false)
    }
    const navigate = useNavigate()
    function postData(e) {
        e.preventDefault()
        const formdata = new FormData
        delete state?.date
        Object.keys(state).forEach((elem) => {
            formdata.append(elem, state[elem])
        })
        mutate({
            url:`/products/${id}/`,
            method: 'put',
            data: formdata,
            onSuccess: success,
            onError: error
        })
    }
    function success(params) {
        setImage(true)
        toast.success("Mahsulot muvoffaqiyatli o'zgartrildi!", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    function error(params) {
        toast.error("Mahsulot o'zgartrilmadi!", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    function deleteProd(e) {
        mutate({
            url: `/products/${id}/`,
            method: 'delete',
            onSuccess: delsuccess,
            onError: delerror
        })

    }
    function delsuccess(params) {
        navigate('/Product')
        toast.success("Mahsulot o'chirildi!", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    function delerror(params) {
        toast.error("Mahsulot o'chirilmadi!", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    if (isLoading) return <Loader/>
    if (loading) return <Loader/>
    return (
        <div className={s.AddProducts}>
            <div className="container" >
                <AddProduct postData={postData} setState={setState} state={state} />
                {
                    state?.photo_link ?
                        <ViewProductImages  done={image} setState={changeImage} state={state} />
                        :
                        null
                }
                <Button onClick={deleteProd} text={"Mahsulotni  o'chirish"} />
            </div>
        </div>
    )

}