import React, { useState } from "react";
import s from './SallProduct.module.scss'
import Button from "../Button";
import Input from "../Input";
import send from '../../assets/icons/Share_iOS_Export.svg'
import usePost from "../../hooks/usePost";
import { useGetAll } from "../../hooks";
import Loader from "../Loader";
import x from '../../assets/icons/cancel-button.png'
import { toast } from 'react-toastify'
export default function SallProduct({ icon }) {
    const [state, setState] = useState({
        first_name:'',
        last_name:''
    })
    const [modal, setModal] = useState(false)
    const [disabled, setdisabled] = useState(true)
    const { data } = useGetAll({
        url: '/products/',
    })
    function submitVal(e) {
        setState({ ...state, [e.target.name]: e.target.value })
    }
    const { mutate, isLoading } = usePost()
    function GetData(e) {
        if (e.target.value.length == 9) {
            mutate({
                url: `users/${e.target.value}/user_id/`,
                method: 'get',
                onSuccess: success,
                onError: onErrorr
            })
        }
    }
    function onErrorr(e) {
        toast.error("Qabul qiluvchi topilmadi!", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    function success(data) {
        setState(data.data)
        setdisabled(false)
    }
    function getSum(e) {
        let sum = data?.filter(el => el.id == e.target.value)[0].price
        setState({ ...state, total_sum: sum, total_price: sum, amount: 1 ,product_id:e.target.value})
    }
    function colculate(e) {
        if (e.target.value > 0) {
            setState({ ...state, total_price: state.total_sum * Number(e.target.value), amount: e.target.value })
        } else {
            setState({ ...state, total_price: state.total_sum, amount: e.target.value })
        }
    }

    function postData() {
        const formdata = new FormData
        formdata.append('user', state.id)
        formdata.append('product', state.product_id)
        formdata.append('amount', state.amount)
        mutate({
            url: `/warehouses/sale/products/`,
            method: 'post',
            data:formdata,
            onSuccess: successpost,
            onError: onErrorrpost
        })
    }
    function onErrorrpost(e) {
        toast.error("Mahsulot sotilmadi!", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    function successpost(data) {
        setModal(false)
        toast.success("Mahsulot muvoffaqiyatli sotildi!", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    return (
        <div className={s.SallProduct} >
            {
                isLoading ?
                    <div className={s.loaders_back} >
                        <Loader />
                    </div>
                    :
                    null
            }
            {
                modal ?
                    <div className={s.SallProduct_modal_back} >
                        <div className={s.SallProduct_modal}>
                            <img onClick={()=>setModal(false)} className={s.SallProduct_modal_back_cencel} src={x} />
                            <div className={s.SallProduct_modal_inner} >
                                <Input submitVal={GetData} disabled={false} name='id' type='text' label='Qabul qiluvchi mijoz ID' />
                                <Input submitVal={submitVal} disabled={true} value={state?.first_name + ' ' + state?.last_name} name='first_name' type='text' label='Familiya Ism' />
                                <Input  disabled={true} value={state?.phone_number } name='phone_number' type='text' label='Telefon raqam' />
                                <select onChange={(e) => getSum(e)} disabled={disabled} >
                                    <option>Tanla</option>
                                    {
                                        data?.map((elem) => (
                                            <option value={elem.id} >{elem.name}</option>
                                        ))
                                    }
                                </select>
                                <Input submitVal={colculate} disabled={disabled} value={state?.amount} name='amount' type='number' label='Umumiy miqdori' />
                            </div>
                            <div className={s.SallProduct_modal_bottom} >
                                <h1 className={s.SallProduct_modal_bottom_title} ><p>Summa:</p>{state?.total_price}uzs</h1>
                                <Button onClick={()=>state?.total_price ? postData() : null} disabled={state?.total_price ? false : true} text={'Mahsulot sotish'} icon={send} />
                            </div>
                        </div>
                    </div>
                    :
                    null
            }
            <Button onClick={() => setModal(true)} text={'Mahsulot sotish'} icon={icon} />
        </div>
    )
}