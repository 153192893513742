import React, { useState } from 'react'
import check from "../../assets/icons/chack.svg";
import product from "../../assets/imgs/Limon water 1.png";
import Button from "../Button";
import s from "./Discount.module.scss"
import CustomProduct from 'components/customerProduct/CustomProduct';
import cencel from '../../assets/icons/cancel-button.png'
function Discount({ item }) {
  const [order, setOrder] = useState(false)
  return (
    <div className={s.discount}>
      <div className={s.Discount_inner} >
        <img className={s.images2} src={'https://rizonwebappapi.pythonanywhere.com/' + item?.photo_link} alt="" />
        <h3>{item?.discount}</h3>
        <h2>{item?.name}</h2>
        <p>
         {item?.lifetime}
        </p>
        <ul>
          {
            item?.about.split('.').map((elem) => (
              <li>
                {" "}
                <img src={check} alt="" /> {elem}
              </li>
            ))
          }
        </ul>
        <Button onClick={() => setOrder(true)} text={"Sotib olish!"} />
      </div>
      <div>
        <img className={s.images} src={'https://rizonwebappapi.pythonanywhere.com/' + item?.photo_link} alt="" />
      </div>
      {
        order ?
          <div className={s.order_back} >
            <CustomProduct setOrder={setOrder} x={cencel} item={item} />
          </div>
          :
          null
      }

    </div>
  );
}

export default Discount