import React from "react";
import s from './BigCard.module.scss'
import ellips from '../../assets/icons/Ellipse 6.png'
import PercentPart from "../PercentPart";
import phone from '../../assets/icons/Vector.png'
import user from '../../assets/icons/woman2.png'
import { Link } from "react-router-dom";
export default function BigCard({ number, key, elem }) {
    return (
        <Link key={key} className={s.BigCard} style={{ textDecoration: 'none', marginRight: 63, marginTop: 30 }} to={`${elem?.employee?.id}`} >
            <div  >
                <div className={s.BigCard_top} >
                    <div className={s.BigCard_top_left} >
                        {
                            !elem?.employee?.photo ?
                                <img src={user} />
                                :
                                <img src={`https://rizonwebapp.pythonanywhere.com${elem?.employee?.photo}`} />

                        }
                        <div className={s.BigCard_top_left_inner} >
                            <h5 className={s.BigCard_top_left_inner_name} >{elem?.employee?.first_name + ' ' + elem?.employee?.last_name}</h5>
                            <p className={s.BigCard_top_left_inner_profession} >{elem?.status}</p>
                        </div>
                    </div>
                </div>
                <div className={s.BigCard_bottom} >
                    {/* <h3 className={s.BigCard_bottom_title}>{elem.coupon}ball</h3> */}
                    <h3 className={s.BigCard_bottom_title}>{elem?.coupon}ball</h3>
                    <div className={s.BigCard_bottom_box} >
                        {
                            number?.map((elem) => (
                                <p className={s.BigCard_bottom_text} > <img src={phone} />{elem}</p>
                            ))
                        }
                    </div>
                </div>
            </div>
        </Link>

    )

}