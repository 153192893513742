import React from "react";
import s from './Promotion.module.scss'
import Promotioninner from "components/PromotionInner";
import GetAll from "modules/GetAll";
import Loader from "components/Loader";
export default function Promotion() {
    return(
        <GetAll name={'admin-promotions'} url={'/admin-promotions/'} >
            {({items})=>{
                console.log(items);
                if (items.isLoading) return <Loader/>
                return(
                    <div className={s.Promotion} >
                    <div className="container" >
                        <Promotioninner title={'Junior'} reFatch={items.refetch} data={items?.data?.small_interval} />
                        <Promotioninner title={'Middle'} reFatch={items.refetch} data={items?.data?.middle_interval} />
                        <Promotioninner title={'Senior'} reFatch={items.refetch} data={items?.data?.large_interval} />
                    </div>
                </div>
                )
            }}
        </GetAll>


    )
    
}