import React,{useEffect, useState} from "react";
import s from './TabelMiniShop.module.scss'
import Table from "../../components/Table/Table";
import search from '../../assets/icons/Search_Magnifying_Glass.png'
import { elements } from "chart.js";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import AddMedModal from "components/AddMedModal";
export default function TabelMiniShop({data}) {
 
    const column = [
        {
            title: "Do'kon nomi",
            key: 'name',
        },
        {
            title: 'Telefon raqami',
            key: 'phone',
        },
        {
            title: 'Manzil',
            key: 'address',
        },
        {
            title: 'Umumiy Ball',
            key: 'ball',
        },
        {
            title: "Batafsil",
            key: 'id',
            render: (item) => (
                <Link className={s.inner_butn} to={`/Branches/${item}`} >
                    <Button text='Barafsil' />
                </Link>
            )
        },
    ]

    return (
        <div className={s.Consultants}>
            <div className={s.Consultants_table} >
                <Table column={column} data={data} numbers/>
            </div>
        </div>
    )

}