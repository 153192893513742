import React, { useState } from "react";
import s from './PromotionCard.module.scss'
import { usePost } from "hooks";
import { toast } from 'react-toastify'
import PromotionItemAdd from "components/PromotionItemAdd";
import cencel from 'assets/icons/cancel-button.png'
import CustomerProductPromotion from "components/customerProductPromotion/CustomerProductPromotion";
export default function PromotionCardMijoz({ elem, refatch }) {
    const { mutate, isLoading } = usePost()
    const [order,setOrder]=useState(false)
    return (
      <div className={s.PromotionCard}>
        <img
          className={s.photos}
          src={"https://rizonwebappapi.pythonanywhere.com/" + elem.photo}
        />
        <h1 className={s.PromotionCard_title}>{elem.name}</h1>
        <div className={s.PromotionCard_text_wrapper}>
          <p>Kupon: {elem.coupon}</p>
        </div>
        <button
          onClick={() => setOrder(true)}
          className={s.PromotionCard_main_btn}
        >
          Harid qilish
        </button>
        {order && (
          <div className={s.order_back}>
            <CustomerProductPromotion
              refatch={refatch}
              setOrder={setOrder}
              x={cencel}
              item={elem}
            />
          </div>
        )}
      </div>
    );
}
