import React from 'react'
import './Product_info.css'
import medicen from '../../public/images/medicen.png'
import BuyProd from '../../components/BuyProd'
function Product_card({data,warehouses}) {
	function generateData(e) {
		let array = e?.split('.')
		array.pop()
		return array
	}
	return (
		<div className="product_info_main">
			<div className="product_info_card">
				<div className="product_info_text">
					<h2>{data?.discount}</h2>
					<h1>{data?.name}</h1>
					{/* <p>Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur.</p> */}
					<div className="list">
						{
							generateData(data?.about).map((item)=>(
								<p><b>✓</b> {item}.</p>
							))
						}
					</div>
					<BuyProd id={data.id} warehouses={warehouses} />
				</div>
				<div className="product_info_img">
					<img src={'https://rizonwebappapi.pythonanywhere.com/'+data?.photo_link} alt="" />
				</div>
			</div>
		</div>
	)
}

export default Product_card