import React,{useState,useEffect} from "react";
import s from './Inputdate.module.scss'
export default function Inputdate({value,disabled,val,setVal}) {
    useEffect(()=>{
        if (value) {
            setVal({ year:value?.split('').slice(0,4).join(''), month:value?.split('').slice(5,7).join(''),day:value?.split('').slice(8,10).join('') })
        }
    },[value])
    function getDay(e) {
            setVal({ ...val, day: e })
    }
    function getMonth(e) {
            setVal({ ...val, month: e })
    }
    function getYear(e) {
            setVal({ ...val, year: e })
    }
    // function getNum(e) {
    //     let nums = [1,2,3,4,5,6,7,8,9,0]
    //     if (!isNaN(e) ) {
    //         setVal({ ...val, num: e })
    //         if (validate?.includes('passport')) {
    //             validate.splice(validate.indexOf('passport'),1)
    //         }
    //         setState({...state,passport:val.serial+e})
    //     }
    // }
    return (
        <div className={s.Inputdate} >
            <h2 className={s.Inputdate_title} >Tug’ilgan sana</h2>
            <div className={s.Inputdate_inner} >
                <input disabled={disabled} value={val?.day} onChange={(e)=>getDay(e.target.value)} className={s.day} type={'number'} placeholder='dd'  />
                <input disabled={disabled} value={val?.month} onChange={(e)=>getMonth(e.target.value)} className={s.month} type={'number'} placeholder='mm'  />
                <input disabled={disabled} value={val?.year} onChange={(e)=>getYear(e.target.value)} className={s.year} type={'number'} placeholder='yyyy'  />
            </div>
        </div>
    )
}