import React, { useEffect, useState } from "react";
import "./Products.css";
import card from "../../public/images/card.png";
import Card from "../Card/Card";
import Modal from "components/Modal";
import { useNavigate } from "react-router-dom";

function Products({ popular, highRating }) {
  const [tab, setTab] = useState(1);
  const [info, setInfo] = useState(popular);
  const [more, setMore] = useState(false);
  const navigate = useNavigate()
  function FIlters(e) {
    setTab(e);
    if (e == 1) {
      setInfo(popular);
    } else {
      setInfo(highRating);
    }
  }
  useEffect(() => {
    FIlters(1);
  }, [popular]);
  // console.log(popular);
  function showMore() {}
  return (
    <div className="products container2">
      <h1>Mahsulotlar</h1>
      {/* <p><b>100 000uzs</b> dan yuqori bo’lgan haridlar uchun yetkazib berish bepul!</p> */}
      <div className="products_select">
        <div className="products_select_text">
          <h1 onClick={() => FIlters(1)}>Ommabop</h1>
          <h1 onClick={() => FIlters(2)}>Reytingi yuqori</h1>
        </div>
        <div className="products_select_spacing">
          <div className={tab == 1 ? "activ" : ""}></div>
          <div className={tab == 2 ? "activ" : ""}></div>
        </div>
      </div>
      {more ? (
        <div className="products_cards">
          {info?.map((item, i) => {
            // console.log(item);
            return (
              <Card
                onClick={()=>navigate(`/product/${item?.id}`)}
                key={i}
                id={item.id}
                title={item.name}
                img={
                  "https://rizonwebappapi.pythonanywhere.com/" + item.photo_link
                }
                price={item.extraPrice}
              />
            );
          })}
        </div>
      ) : (
        <div className="products_cards">
          {info?.slice(0, 10).map((item, i) => {
            return (
              <Card
                onClick={() => navigate(`/product/${item?.id}`)}
                key={i}
                id={item.id}
                title={item.name}
                img={
                  "https://rizonwebappapi.pythonanywhere.com/" + item.photo_link
                }
                price={item.extraPrice}
              />
            );
          })}
        </div>
      )}
      <button onClick={() => setMore(!more)} className="product_btn">
        {more ? "Kamroq" : "Barchasi"}
      </button>
    </div>
  );
}

export default Products;
