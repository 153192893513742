import React, { useState } from "react";
import s from './AddProducts.module.scss'
import ViewProduct from 'components/viewProduct/ViewProduct'
import AddProduct from "components/addProduct/AddProduct";
import { usePost } from "hooks";
import { toast } from 'react-toastify'
import {  useNavigate } from "react-router-dom";
import Loader from "components/Loader";
export default function AddProducts() {
    const [state, setState] = useState({})
    const { mutate, isLoading } = usePost()
    const navigate = useNavigate()
    function postData(e) {
        const formdata = new FormData
        Object.keys(state).forEach((elem) => {
            formdata.append(elem, state[elem])
        })
        mutate({
            url:'/products/',
            method: 'post',
            data: formdata,
            onSuccess: success,
            onError: error
        })
        navigate('/Product')
    }
    function success(params) {
        toast.success("Mahsulot muvoffaqiyatli qo'shildi!", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    function error(params) {
        toast.error("Mahsulot qo'shilmadi!", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    if (isLoading) return <Loader/>
    return (
        <div className={s.AddProducts}>
            <div className="container" >
                <AddProduct postData={postData} setState={setState} state={state} />
                {
                    state?.photo_link ?
                        <ViewProduct setState={setState} state={state} />
                        :
                        null
                }
            </div>
        </div>
    )

}