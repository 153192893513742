import Input from "../../../../../components/Input";
import BranchImage from "../../../../../assets/imgs/branch_photo.png";
import s from './styles.module.scss'

const Index = ({data}) => {
  console.log(data);
  return (
    <>
      <div className={s.Branch_info}>
        <h1>Filial haqida ma’lumot</h1>
        <form action="">
          <div className={s.Branch_info_wrapper}>
            <label htmlFor="">
              <h3>Manzil</h3>
              <input type="text" disabled value={data?.address} />
            </label>
            <label htmlFor="">
              <h3>Nomi</h3>
              <input type="text" disabled value={data?.name} />
            </label>
            <label htmlFor="">
              <h3>Tel raqami</h3>
              <input type="text" disabled value={data?.phone} />
            </label>
          </div>
        </form>
        <div className={s.Branch_info_more}>
          <h2>Batafsil</h2>
          <div className={s.Branch_info_more_wrapper}>
            <p>{data?.about}</p>
          </div>
        </div>
      </div>
      <div className={s.Branch_info}>
        <h1>Photo</h1>
        <div className={s.Photo_wrapper}>
          <img
            src={`https://rizonwebappapi.pythonanywhere.com/${data?.photo}`}
            alt="Branch_photo"
          />
        </div>
      </div>
    </>
  );
}

export default Index