import React, { useState } from "react";
import s from "./Profile.module.scss";

import Table from "../../../components/Table/Table";

import GetAll from "modules/GetAll";
import { getLastMonth, getLastYear, thisMonth, getThisYear } from "../../../service/dates";
import Loader from "components/Loader";
import { DateInput } from "components/DateInput";
import UserTabel from "container/UserTabel";
import Info from "./Info";
export default function Profile() {
  const [filter, setFIlter] = useState(thisMonth())
  let filters = [
    {
      name: "O’tgan Oy",
      val:
        getLastMonth().slice(-2) == "00"
          ? `${getLastYear()}-12`
          : getLastMonth(),
    },
    { name: "Hozirgi oy", val: thisMonth() },
    { name: "O’tgan yil", val: getLastYear() },
    { name: "Hozirgi yil", val: getThisYear() },
  ];
  function choose(e) {
    setFIlter(e)
  }
  const column = [
    {
      title: "ID",
      key: "id",
    },
    {
      title: "Ism va familiya",
      key: "name",
    },
    {
      title: "Telefon raqam",
      key: "phone_number",
    },
    {
      title: "Shaxsiy Ball",
      key: "ball",
    },
    {
      title: "Bonus",
      key: "bonus",
    },
    {
      title: "Status",
      key: "status",
    },
  ];
  const data = [
    {
      name: "Shaxnoza Karimova",
      status: "Konsultant",
      phone_number: "60 000 20 23",
      bonus: "Elbek Botirov",
      ball: "3 000",
      id: "23456789"
    },
    {
      name: "Shaxnoza Karimova",
      status: "Konsultant",
      phone_number: "60 000 20 23",
      bonus: "Elbek Botirov",
      ball: "3 000",
      id: "23456789"

    },
    {
      name: "Shaxnoza Karimova",
      status: "Konsultant",
      phone_number: "60 000 20 23",
      bonus: "Elbek Botirov",
      ball: "3 000",
      id: "23456789"

    },
  ];
  const [pageTable, setPageTable] = useState(1)

  return (
    <GetAll name={'users-profile'} url={`/brand-manager-profile/month/${filter}`} >
      {({ items }) => {
        if (items.isLoading) return <Loader />
        return (
          <div className={s.Profile}>
            <div className="container">
              <div className={s.Button_wrapper} >
                <div className={s.filetr_part} >
                  {
                    filters.map((item) => (
                      <button onClick={() => choose(item.val)} className={filter == item.val ? `${s.chosen}` : s.btns} >{item.name}</button>
                    ))
                  }
                </div>
                <DateInput change={choose} />
              </div>
              <Info data={items?.data?.user} />
              <div className={s.table_wrapper} >
                {
                  items?.data?.user_tree &&
                  Object.keys(items?.data?.user_tree)?.map((elem, i) => (
                    <>
                      <h1 className={s.title}>{elem} Shajara</h1>
                      <UserTabel data={items?.data?.user_tree[pageTable]} elem={elem} />
                    </>
                  ))
                }
              </div>
              {
                items?.data?.user_tree &&
                <div className={s.mob_table_wrapper} >
                  <UserTabel info={Object.keys(items?.data?.user_tree)} setPageTable={setPageTable} data={items?.data?.user_tree[pageTable]} elem={pageTable} />
                </div>
              }


            </div>
          </div>
        )
      }}
    </GetAll>

  );
}
