import React, { useEffect, useState } from "react";
import s from './Employee.module.scss'
import Input from "../../components/Input";
import Button from "../../components/Button";
import image from '../../assets/icons/young-woman-preparing-her-nutrition-diet 1.png'
import note from '../../assets/icons/Note_Edit2.svg'
import { usePost } from "../../hooks";
import Loader from "../../components/Loader";
import { toast, ToastContainer } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css";
import { useParams, Link } from "react-router-dom";
import useImage from '../../assets/icons/woman2.png'
export default function Employee({ data, setSalary, recovery, archive }) {
    const [state, setState] = useState()
    useEffect(() => {
        setState(data?.employee)
    }, [data])
    const [disabled, setDiabled] = useState(true)
    function submitVal(e) {
        setState({ ...state, [e.target.name]: e.target.value })
    }
    const { empoyId } = useParams()
    const { mutate, isLoading } = usePost()
    function postData() {
        delete state?.id
        delete state?.photo
        const formdata = new FormData
        Object.keys(state).forEach((elem) => {
            if (elem == 'warehouse') {
                Object.keys(elem).forEach((item) => {
                    formdata.append(`warehouse[${item}]`, elem[item])
                })
            }
            formdata.append(elem, state[elem])
        })
        mutate({
            url: `/employees/${empoyId}/`,
            data: formdata,
            method: 'put',
            onSuccess: success,
            onError: error,
        })
    }
    function success(data) {
        setState(data.data)
        toast.success("Xodim muvofaqqiyatli o'zgartrildi !", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    function error(data) {
        toast.error("Xodim  o'zgartrilmadi!", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    function deleteEmp() {
        if (window.confirm('Ushbu xodimni ishdan olishga aminmisiz?')) {
            mutate({
                url: `/employees/${empoyId}/`,
                method: 'delete',
                onSuccess: successDelete,
                onError: errorDelete,
            })
        }
    }
    function successDelete(data) {
        window.history.back()
        toast.success("Xodim muvofaqqiyatli ishdan olindi !", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    function errorDelete(data) {
        toast.error("Xodim ishdan olinmadi qaytadan urunib ko'ring!", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    if (isLoading) return <Loader />
    return (
        <div className={s.Employee} >
            <button onClick={() => setDiabled(false)} className={s.edite_btun} >Tahrirlash<img src={note} /> </button>
            <div className={s.Employee_top} >
                <div className={s.Employee_left} >
                    {
                        !state?.photo ?
                            <img src={useImage} alt="user" />
                            :
                            <img
                                src={`https://rizonwebappapi.pythonanywhere.com/${state?.photo}`}
                                alt="userImage"
                            />
                    }
                    <div className={s.Employee_left_right} >
                        <div className={s.Employee_right_top_left} >
                            <h2 className={s.sum} >{data?.fee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</h2>
                            <p className={s.ball} >Umumiy ball</p>
                        </div>
                        <div className={s.Employee_right_top_right} >
                            <h2 className={s.sum} >{data?.total_salary?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</h2>
                            <p className={s.ball} >Umumiy daromad</p>
                        </div>
                    </div>
                </div>
                <div className={s.Employee_right} >
                    <div className={s.Employee_right_top} >
                        <h2 className={s.Employee_left_username} >{state?.first_name} {state?.last_name}</h2>
                        <p className={s.Employee_left_vendor} >Sotuvchi</p>
                    </div>
                    {
                        archive ?
                            null
                            :
                            <div className={s.Employee_right_bottom} >
                                <Link to={`/Branches/Salary/${state?.id}`} style={{ textDecoration: 'none' }} ><Button text={'Maosh tarixi'} /></Link>
                                <Button onClick={() => setSalary(true)} text={'Maosh to’lash'} />

                            </div>
                    }
                    {
                        archive ?
                            <div className={s.Employee_right_bottom} >
                                <Button onClick={recovery} text={'Qayta tiklash'} />
                            </div>
                            :
                            null
                    }

                </div>
            </div>
            <h1 className={s.edite_btn} >Tahrirlash</h1>
            <form className={s.input_form} >
                <Input submitVal={submitVal} disabled={disabled} value={state?.first_name} name='first_name' type='text' label='Ism' />
                <Input submitVal={submitVal} disabled={disabled} value={state?.last_name} name='last_name' type='text' label='Familiya' />
                <Input submitVal={submitVal} disabled={disabled} value={state?.phoneNumTwo} name='phoneNumTwo' type='text' label='Telefon raqam 2 ' />
                <Input submitVal={submitVal} disabled={disabled} value={state?.phone_number} name='phoneNum' type='text' label='Telefon raqam' />
                <Input submitVal={submitVal} disabled={disabled} value={state?.address} name='address' type='text' label='Manzil' />
            </form>
            {
                archive ?
                    null
                    :
                    <div className={s.sub_btns} >
                        <Button onClick={() => deleteEmp()} color='#FF0000' text={'Ishdan olish'} />
                        <Button onClick={() => postData()} disabled={disabled} text={'Saqlash'} />
                    </div>
            }
        </div>
    )
}