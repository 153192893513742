import "./About.css";
// import CountUp from "react-countup";
// import ScrollTrigger from "react-scroll-trigger";
import { useEffect, useState } from "react";
// import "swiper/css/pagination";
// import "swiper/css/navigation";
import Job from "../components/Job";
import logo1 from '../public/images/h1.png'
import logo2 from '../public/images/h2.png'
import logo3 from '../public/images/h3.png'
import logo4 from '../public/images/h4.png'
import GetAll from "../hooks/GetAll";
import Loader from '../components/Loader'
function About() {
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);
  const [state, setState] = useState(false);
  const {data,loading}=GetAll({
    url:'/landing-about/'
  })
  if (loading) return <Loader/>
  return (
    <div className="about_page">
      <div className="about_hero">
        <div className="container2">
          <div className="txt_h1_div_left" >
            <h1 className="txt_h1">Biz insonlarga salomatlik ulashamiz!</h1>
            <p className="about_desk_txt p">
              Lorem ipsum dolor sit amet consectetur. Rutrum sit quisque varius
              interdum dictumst id. Eget mi sed tempus mattis lectus orci. Amet
              sit accumsan nec suspendisse neque netus.
            </p>
          </div>
        </div>
      </div>
      <hr />
      <div
        className="container2"
        style={{
          padding: " 30px 50px",
        }}
      >
        <div
          className="d-flex"
          style={{
            alignItems: "start",
          }}
        >
          <h1>Rizon <br /> mijozlar foydasini o'ylaydi</h1>
          <p
            // style={{
            //   width: " 50%",
            //   lineHeight: " 39px",
            //   fontWeight: "600",
            //   color: "#000000D9",
            // }}
          >
            Akimyogar Farm Kompaniyasi Kanada nanotexnologiyalari asosida yuqori sifatli kolloid minerallar hamda dorivor o’simliklardan tayyorlangan shifobaxsh ekstraktlar ishlab chiqarish bilan shug’ullanadi. Ekstraktlar gidrolat usulida bo’lib, MDX davlatlari ichida ularni iste’mol uchun chiqargan ilk kompaniya hisoblanadi! Korxona 2019-yilda tashkil topgan bo’lib, mana shu qisqa vaqt mobaynida 20 dan ortiq mahsulot assortimenti hamdan 5000 dan ortiq o’zining mamnun mijozlariga ega. Kompaniya sifatli, tabiiy hamda hamyonbob mahsulotlar ishlab chiqarish asosida millatning salomatligiga hissa qo’shishni o’z oldiga maqsad qilib qo’ygan.
          </p>
        </div>
      </div>
      <hr />
      {/* <hr /> */}
      {/* <ScrollTrigger
        onEnter={() => setState(true)}
        onExit={() => setState(false)}
      >
        <div className="container count d-flex">
          <div className="count-text">
            <h1>
              Xorijiy va mahalliy ilmiy- tadqiqot markazlari bilan hamkorlik!
            </h1>
            <h1>
              {state && <CountUp start={0} end={7} duration={4} delay={0} />}
              <span className="plas">+</span>
            </h1>
          </div>
          <div className="count-text">
            <h1>
              Doimiy mamnun iste'molchilar!
            </h1>
            <h1>
              {state && <CountUp start={0} end={4000} duration={4} delay={0} />}
              <span className="plas">+</span>
            </h1>
          </div>
          <div className="count-text">
            <h1>Shifobaxsh mahsulotlar assortimenti</h1>
            <h1>
              {state && <CountUp start={0} end={22} duration={4} delay={0} />}
              <span className="plas">+</span>
            </h1>
          </div>
        </div>
      </ScrollTrigger> */}
      <hr />
      <div className="about-logo-main" style={{ margin: "40px auto" }} id="partners">
        <h1 className="title">Hamkorlar</h1>
        <div className="about-logo">
          <img src={logo1} alt="" />
          <img src={logo2} alt="" />
          <img src={logo3} alt="" />
          <img src={logo4} alt="" />
          {/* <img src={logo3} alt="" /> */}
        </div>
      </div>
      <hr />
      <div className="container2 team_container" id="team">
        <h1 className="about13"></h1>
        <div className="team">
          <div className="desktop_"><Job data={data?.team} /></div>
          <div className="media_">
            {/* <MediaJob data={data} /> */}
            </div>
        </div>
      </div>
    </div>
  );
}

export default About;
