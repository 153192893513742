import React, { useState } from "react";
import s from "./Product.module.scss";
import Button from "../Button";
import stop from "../../assets/icons/Stop_Sign.svg";
import productImg from "../../assets/imgs/Argenta kalloid 1.png";
import canada from "../../assets/icons/canada.png";
import icon from '../../assets/icons/Shopping_Cart_01.png'
import { useGetAll, usePost } from "hooks";
import { toast } from 'react-toastify'
import Loader from "components/Loader";
import { useQueryClient } from "@tanstack/react-query";
function CustomerProductOrders({ item, product }) {
    const queryClient = useQueryClient();
  const [state, setState] = useState({})
  function colculate(e) {
    setState({ ...state, amount: e, total_sum: item.price * e })
  }
  const { data } = useGetAll({
    url: '/warehouses/',
  })
  const { mutate, isLoading } = usePost()
  function postOprder(params) {
    if (window.confirm('Buyurtmani bekor qilasizmi?')) {
      mutate({
        url: `/users/product/order/${item.id}/`,
        method: 'delete',
        onSuccess: success,
        onError: error
      })
    }

  }
  function success(params) {
    toast.success("Buyurtma bekor qilindi!", {
      position: toast.POSITION.TOP_RIGHT,
    });
    queryClient.invalidateQueries("users-orders");
  }
  function error(params) {
    toast.error("Buyurtma bekor qilinmadi!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  return (
    <div className={s.Product}>
      {
        isLoading ?
          <div className={s.loader_back} >
            <Loader />
          </div>
          :
          null
      }
      <div className={s.product_img}>
        <img src={'https://rizonwebapp.pythonanywhere.com' + item?.product?.photo_link} alt="" />
      </div>
      <div className={s.product_info}>
      <img className={s.mob_img} src={'https://rizonwebappapi.pythonanywhere.com/' + item?.product?.photo_link} alt="" />
        <h2>{item?.product?.name}</h2>
        <h2>{item?.product?.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}uzs</h2>
        <h3>Batafsil</h3>
        <p>{item?.product?.about}</p>
        <h4 className={s.author}>
          Ishlab chiqaruvchi: <a href={'#'} >{item?.product?.manufacturer}</a>
        </h4>
          <h1 className={s.orders} >Buyurtma:</h1>
          <div className={s.product_form}>
            <div className={s.text_wrapper} >
              <h3>Soni</h3>
              <p>{item?.amount}</p>
            </div>
            <div className={s.text_wrapper} >
              <h3>Umumiy summa</h3>
              <p>{item?.summa}</p>
            </div>
          </div>
        <Button onClick={()=>postOprder()} color={'red'} text={"Bekor qilish"} icon={stop} />
      </div>
    </div>
  );
}

export default CustomerProductOrders;
