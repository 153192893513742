import React from "react";
import s from './Orders.module.scss'
import Table from "../../components/Table/Table";
import search from '../../assets/icons/Search_Magnifying_Glass.png'
import { elements } from "chart.js";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import { generateDate } from "../../service/dates";
export default function Orders({data}) {
    const column = [
        {
            title: 'Sana',
            key: 'date',
            render:(item)=>{
                return(
                    <span>{generateDate(item)}</span>
                )
            }
        },

        {
            title: 'Dori nomi',
            key: 'product',
            render:(item)=>{
                return(
                    <span>{item?.name}</span>
                )
            }
        },
        {
            title: 'Miqdori',
            key: 'amount',
        },
        {
            title: 'Summa',
            key: 'product',
            render:(item)=>{
                return(
                    <span>{item?.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}uzs</span>
                )
            }
        },
    ]

    return (
        <div className={s.Orders}>
            <div className={s.Orders_table} >
                <Table column={column} data={data} />
            </div>
        </div>
    )

}