import React, { useState } from "react";
import s from "./SalaryHistory.module.scss";
import Table from "../../../components/Table/Table";
import GetAll from "modules/GetAll";
import { useParams } from "react-router-dom";
import Loader from "components/Loader";
export default function SalaryHistory() {
  const { id } = useParams()
  const column = [
    // {
    //   title: "No",
    //   key: "no",
    // },
    {
      title: " Sana",
      key: "date",
 
    },
    {
      title: "Telefon raqam",
      key: "user",
      render:(item)=>{
        return(
          <span>{item?.phone_number}</span>
        )
      }
    },
    {
      title: "Shaxsiy aylanma",
      key: "user_score",
    },
    {
      title: "Shaxsiy sotuv bonusi",
      key: "personal_bonus",
    },
    {
      title: "Ustozlik bonusi",
      key: "forMentorship",
    },
    {
      title: "Jamoaviy aylanma",
      key: "user_tree_score",
    },
    {
      title: "Jamoaviy bonus",
      key: "collective_bonus_amount",
    },
    {
      title: "Extra bonus",
      key: "extra_bonus",
    },
    {
      title: "Kupon",
      key: "coupon",
    },
    {
      title: "To’landi",
      key: "paid",
      render:(item)=>{
        return(
          <span>{item?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}uzs</span>
        )
      }
    },
    {
      title: "Qarz",
      key: "debt",
      render:(item)=>{
        return(
          <span>{item?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}uzs</span>
        )
      }
    },
    {
      title: "Filial",
      key: "salary_payer",
      render:(item)=>{
        return(
          <span>{item?.name}</span>
        )
      }
    },
  ];


  return (
    <GetAll url={`/users-salary/payments/`}>
      {({ items }) => {
        if (items.isLoading) return <Loader/>
        return (
          <div className={s.SalaryHistory}>
            <div className="container">
              <div className={s.HistoryInfo}>
                <Table column={column} data={items?.data?.salary_history} />
              </div>
            </div>
          </div>
        )
      }}
    </GetAll>

  );
}
