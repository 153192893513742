import React, { useEffect, useState } from "react";
import s from './Profile.module.scss'
import { Link } from "react-router-dom";
import woman from "../../.././assets/icons/woman.png"
import Button from "../../.././components/Button";
import Passport from "components/Passport";
import Inputdate from "components/Inputdate";
import { usePost } from "hooks";
import user from '../../../assets/icons/woman2.png'
import { toast } from 'react-toastify';
export default function Info({ data }) {
    const [state, setState] = useState({})
    const [disabled, setDisabled] = useState(true)
    const [val, setVal] = useState({ day: '', month: '', year: '' })
    useEffect(() => {
        setState(data)
    }, [data])
    const { mutate, isLoading } = usePost()
    function postData() {
        const formdata = new FormData()
        delete state?.dateOfBirth
        delete state?.photo
        delete state?.warehouse
        delete state?.email
        Object.keys(state).forEach((elem) => {
            formdata.append(elem, state[elem])
        })
        formdata.append('dateOfBirth', `${val.year}-${val.month}-${val?.day}`)
        mutate({
            method: 'patch',
            url: `/users/${state?.id}/`,
            data: formdata,
            onSuccess: onsuccess,
            onError: onerror,
        })
    }
    function onsuccess(params) {
        toast.success("Mijoz muvoffaqiyatli o'zgartrildi!", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    function onerror(params) {
        toast.error("Mijoz o'zgartrilmadi!", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    function copySaleUrl(e) {
        let url = state?.user_id;
        navigator.clipboard.writeText(url);
        let notification = document.createElement('div');
        notification.textContent = 'ID Saqlandi!';
        notification.className = 'copyed'
        notification.style.position = 'absolute';
        notification.style.top = `${e.clientY}px`;
        notification.style.left = `${e.clientX}px`;
        document.body.appendChild(notification);
        setTimeout(() => {
            document.body.removeChild(notification)
        }, 500)
    }
    return (
      <div className={s.profileInfo}>
        <div className={s.userData}>
          <div className={s.userOne}>
            <div className={s.userOneImg}>
              {!state?.photo ? (
                <img src={user} alt="" />
              ) : (
                <img
                  src={
                    "https://rizonwebappapi.pythonanywhere.com/" + state.photo
                  }
                  alt=""
                />
              )}
            </div>
            <div className={s.userOneInfo}>
              <h1>
                {state?.first_name} {state?.last_name}
              </h1>
              <h3 className={s.type}>{state?.user_status}</h3>
            </div>
          </div>
          <div className={s.user_income}>
            <div className={s.count}>
              <div className={s.text_wrapper}>
                <h2>{state?.coupon}</h2>
                <h3 className={s.text}>Umumiy kupon</h3>
              </div>
              <div className={s.text_wrapper}>
                <h2>{state?.salary}</h2>
                <h3 className={s.text}>Umumiy daromad</h3>
              </div>
            </div>
            <div className={s.income_btns}>
              <Link style={{ textDecoration: "none" }} to={"/account-number"}>
                <Button text="Hisobraqam" />
              </Link>
              <Link style={{ textDecoration: "none" }} to={"/transfer"}>
                <Button text="Kupon transfer" />
              </Link>
              <Link style={{ textDecoration: "none" }} to={"/history"}>
                <Button text="Harid tarixi" />
              </Link>
              <Link
                style={{ textDecoration: "none" }}
                to={`/salary/${state?.id}`}
              >
                <Button text="Maosh tarixi" />
              </Link>
            </div>
          </div>
        </div>
        <h1 className={s.profileInfo_title}>Ma’lumotlar</h1>
        <div className={s.userId}>
          <form action="">
            <div className={s.labels}>
              <label htmlFor="">
                <h3>ID raqam</h3>
                <input
                  onClick={copySaleUrl}
                  type="text"
                  disabled={false}
                  value={state?.user_id}
                />
              </label>
              <label htmlFor="">
                <h3>Telefon raqam</h3>
                <input
                  onChange={(e) =>
                    setState({ ...state, phone_number: e.target.value })
                  }
                  type="text"
                  disabled={disabled}
                  value={state?.phone_number}
                />
              </label>
              <label htmlFor="">
                <h3>Telefon raqam 2 </h3>
                <input
                  onChange={(e) =>
                    setState({ ...state, phoneNumTwo: e.target.value })
                  }
                  type="text"
                  disabled={disabled}
                  value={state?.phoneNumTwo}
                />
              </label>
              <label htmlFor="">
                <h3>Manzil</h3>
                <input
                  onChange={(e) =>
                    setState({ ...state, address: e.target.value })
                  }
                  type="text"
                  disabled={disabled}
                  value={state?.address}
                />
              </label>
              <label className={s.passport_wrapper} htmlFor="">
                <Passport
                  setState={setState}
                  state={state}
                  disabled={disabled}
                  value={state?.passport}
                />
              </label>
              <label className={s.date_wrapper} htmlFor="">
                <Inputdate
                  setVal={setVal}
                  val={val}
                  disabled={disabled}
                  value={state?.dateOfBirth}
                />
              </label>
            </div>
          </form>
          <div className={s.userForm_btns}>
            <Button onClick={() => setDisabled(false)} text="O’zgartirish" />
            <Button
              onClick={() => (!disabled ? postData() : null)}
              disabled={disabled}
              text="Saqlash"
            />
          </div>
        </div>
      </div>
    );

}