import React from "react";
import Chart from "chart.js/auto";
import { Bar, Pie } from "react-chartjs-2";
import s from './BarChart.module.scss'
import { Link } from "react-router-dom";
import { ResponsiveBar } from '@nivo/bar'
const LineChartBig = ({ elem }) => {
  function getData() {
    let data = []
    Object.keys(elem).forEach((item) => {
      console.log(elem[item]);
      data.push({ minishop: item, name: elem[item].sales_amount, id: item })
    })
    return data
  }
  const theme = {
    axis: {
      textColor: '#eee',
      fontSize: '24px',
      tickColor: '#eee',
    },
    grid: {
      stroke: '#888',
      strokeWidth: 1
    },
  };
  return (
    <div className={s.Barchart_wrapper} >
      <div className={s.Barchart_wrapper_inner} >
        <div className={s.Barchart_wrapper_inner_element} >
          <ResponsiveBar
            data={getData()}
            keys={[
              'name',
            ]}
            indexBy="minishop"
            margin={{ top: 50, right: 130, bottom: 50, left: 150 }}
            padding={0.3}
            groupMode="grouped"
            layout="horizontal"
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={{ scheme: 'paired' }}
            defs={[
              {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: '#38bcb2',
                size: 4,
                padding: 1,
                stagger: true
              },
              {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: '#eed312',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
              }
            ]}
            fill={[
              {
                match: {
                  id: 'fries'
                },
                id: 'dots'
              },
              {
                match: {
                  id: 'sandwich'
                },
                id: 'lines'
              }
            ]}
            borderColor={{
              from: 'color',
              modifiers: [
                [
                  'darker',
                  1.6
                ]
              ]
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Savdo ulushi',
              legendPosition: 'middle',
              legendOffset: 32
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Mini Do'kon",
              legendPosition: 'middle',
              legendOffset: -40
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
              from: 'color',
              modifiers: [
                [
                  'darker',
                  1.6
                ]
              ]
            }}
            legends={[]}
            role="application"
            ariaLabel="Nivo bar chart demo"
            barAriaLabel={e => e.id + ": " + e.formattedValue + " in country: " + e.indexValue}
            theme={{
              axis: {
                ticks: {
                  text: {
                    fontSize: 14,
                    // fill: "lightblue"
                  }
                },
                legend:{
                  text: {
                    fontSize: 14,
                    // fill: "lightblue"
                  }
                }
              }
            }}

          />
        </div>

        {/* <p className={s.Barchart_wrapper_text_two} >Lorem ipsum dolor sit amet consectetur. Facilisis aenean nec laoreet nulla semper varius lectus nulla. Vitae metus vitae est non suspendisse purus. Etiam arcu non lorem id aliquam placerat morbi. Risus eget volutpat massa bibendum mauris sed. Nulla nam turpis purus sed sed fermentum mi. Elementum ut aenean pellentesque eget tortor in elit. Eleifend eros facilisi dictum at. Purus ligula auctor purus elit.
          Amet pharetra semper mauris arcu donec id molestie. Placerat ac tortor duis lectus consectetur lorem sit. </p> */}
      </div>
    </div>
  );
};

export default LineChartBig;
