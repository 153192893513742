import React from "react";
import s from './InnerPage.module.scss'
import Employeemore from "container/Employeemore";
import GetAll from "modules/GetAll";
import { useParams } from "react-router-dom";
import Loader from "components/Loader";
export default function InnerPageMed() {
    const {id} =useParams()
    return (
        <GetAll name={'inner_med_page'} url={`medics/${id}`} >
            {({ items }) => {
                if (items.isLoading) return <Loader/>
                return (
                    <div className={s.innerPageMed}>
                        <div className='container' >
                            <h1 className={s.InnerPageMed_title}>Xodim</h1>
                            <Employeemore refetch={items.refetch} data={items.data} item={items?.data?.medic}/>
                        </div>
                    </div>
                )
            }}
        </GetAll>
    )
}