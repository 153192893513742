import React, { useState } from "react";
import s from "./History.module.scss";
import Table from "../../../components/Table/Table";
import GetAll from "modules/GetAll";
import Loader from "components/Loader";
import { generateDate } from "service/dates";
import Button from "components/Button";
export default function History() {
  const column = [
    {
      title: "Sana",
      key: "dateTime",
      render:(item)=>{
        return(
          <span>{generateDate(item)}</span>
        )
      }
    },
    {
      title: "Ombor",
      key: "warehouse",
      render:(item)=>{
        return(
          <span>{item?.name}</span>
        )
      }
    },
    {
      title: "Turi",
      key: "product",
      render:(item)=>{
        return(
          <span>{item?.product_type}</span>
        )
      }
    },
    {
      title: "Miqdori",
      key: "amount",
    },
    {
      title: "Summa",
      key: "summa",
      render:(item)=>{
        return(
          <span>{item?.product_type?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}uzs</span>
        )
      }
    },
    // {
    //   title: "Batafsil",
    //   key: "id",
    //   render:(item)=>{
    //     return(
    //       <Button text={'Batafsil'} />
    //     )
    //   }
    // },
  ];
 
  return (
    <GetAll name={'users-sales/ '} url={'brand-manager-sales/'}>
      {({ items }) => {
        if (items.isLoading) return <Loader/>
        return (
          <div className={s.History}>
            <div className="container">
              <div className={s.HistoryInfo}>
                <Table column={column} data={items?.data} />
              </div>
            </div>
          </div>
        )
      }}
    </GetAll>

  );
}
