import React, { useState } from "react";
import s from './Archive.module.scss'
import BrachAdd from "components/addBranch";
import ViewBranch from "components/viewBranch/ViewBranch";
import Loader from "components/Loader";
import user from '../../../assets//icons/woman2.png'
import Table from "components/Table/Table";
import Button from "components/Button";
import GetAll from "modules/GetAll";
import { useNavigate, useParams } from "react-router-dom";
import { usePost } from "hooks";
import {toast} from 'react-toastify'
export default function Branch() {
    const [state, setState] = useState({ photo: user })
    const { id } = useParams()
    const navigate = useNavigate()
    const columnEmp = [
        {
            title: "Xodim ID",
            key: 'user_id',
        },
        {
            title: 'Isim va familiyasi',
            key: 'first_name',
            render:(item,all)=>(item+' '+all?.last_name)
        },
        {
            title: 'Telefon raqam',
            key: 'phone_number',
        },
        {
            title: 'Ball',
            key: 'ball',
        },
    ]
    const columnProfduct = [
        {
            title: "Mahsulot nomi",
            key: 'product',
            render:(item,all)=>(item?.name)
        },
        {
            title: 'Ichki narxi',
            key: 'product',
            render:(item,all)=>(item?.price)

        },
        {
            title: 'Tashqi narx',
            key: 'product',
            render:(item,all)=>(item?.extraPrice)

        },
        // {
        //     title: "Umumiy ball",
        //     key: 'ball',
        // },
        {
            title: "Soni",
            key: 'amount',
        },
    ]
    const columnProfductSell = [
        {
            title: "Mahsulot nomi",
            key: 'product',
            render:(item,all)=>(item?.name)
        },
        {
            title: "Summa",
            key: 'summa',
            render:(item,all)=>(item?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")+'uzs')
        },
        {
            title: "Soni",
            key: 'amount',
        },
    ]
    const {mutate,isLoading}=usePost()
    function recovery(e) {
        const formdata = new FormData()
        formdata.append('deleted',false)
        mutate({
            url:`admin-archive/warehouse/${id}/`,
            method:'put',
            data:formdata,
            onSuccess:success,
            onError:error
        })
        
    }
    function success(params) {
        navigate('/arxiv')
        toast.success("Filial tiklandi!", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    function error(params) {
        toast.error("Filial tiklanmadi!", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    return (
        <GetAll name={'branch_archive_inner'} url={`manager-archive/warehouse/${id}/`} >
            {({ items }) => {
                if (items?.isLoading) return <Loader/>
                if(isLoading) return <Loader/>
                return (
                    <div className={s.AddProducts}>
                        <div className="container" >
                            <BrachAdd archive={true} setState={setState} state={items?.data?.warehouse} />
                            {
                                items?.data?.warehouse?.photo ?
                                    <ViewBranch archive={true} setState={setState} state={items?.data?.warehouse} />
                                    :
                                    null
                            }
                            <div className={s.Archive_table_wrapper} >
                                <h1 className={s.Archive_table_wrapper_title} >Xodimlar</h1>
                                <Table data={items?.data?.employees} column={columnEmp} />
                            </div>
                            <div className={s.Archive_table_wrapper} >
                                <h1 className={s.Archive_table_wrapper_title} >Qabul qilingan mahsulotlar</h1>
                                <Table data={items?.data?.warehouse_products} column={columnProfduct} />
                            </div>
                            <div className={s.Archive_table_wrapper} >
                                <h1 className={s.Archive_table_wrapper_title} >Sotuvlar jadvali</h1>
                                <Table data={items?.data?.sales} column={columnProfductSell} />
                            </div>
                            <Button onClick={recovery} text={'Qayta tiklash'} />
                        </div>
                    </div>
                )
            }}
        </GetAll>

    )

}