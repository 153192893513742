import React, { useEffect, useState } from "react";
import s from "./Header.module.scss";
import search from "../../assets/icons/Search_Magnifying_Glass.png";
import ellips from "../../assets/icons/Ellipse 6.png";
import icon from "../../assets/icons/Ellipseeeeee.png";
import useGetAll from "../../hooks/useGetAll";
import { setToken, getToken } from "../../service/storage/token";
import group from "../../assets/icons/Group 176.png";
import group2 from "../../assets/icons/logout (2).png";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Button from "components/Button";
import { useNavigate, Link } from "react-router-dom";
import icon2 from "../../assets/icons/Bell_Notification.png";
import Hamburger from "../../assets/icons/humburger.png";
import { RoutingData } from "../../Routes/config";
import { http } from "service";
export default function Header({ setDashboard, setLogin }) {
  const navigate = useNavigate();
  const location = useLocation();
  const paths = {
    "/Actions": "Aksiyalar",
    "/Branches": "Filiallar",
    "/Customers": "Mijozlar",
    "/MedicalConsultant": "Med konsultant",
    "/Product": "Mahsulotlar",
    "/Statistics": "Statistika",
  };
  function logOut() {
    navigate("/");
    setLogin("landing");
    window.localStorage.removeItem("token");
  }
  function copyUrl(e) {
    let url = "https://rizon.uz/";
    navigator.clipboard.writeText(url);
    let notification = document.createElement("div");
    notification.textContent = "URL Saqlandi!";
    notification.className = "copyed";
    notification.style.position = "absolute";
    notification.style.top = `${e.clientY}px`;
    notification.style.left = `${e.clientX}px`;
    document.body.appendChild(notification);
    setTimeout(() => {
      document.body.removeChild(notification);
    }, 500);
  }
  function copySaleUrl(e) {
    let url = window.localStorage.getItem("link");
    navigator.clipboard.writeText(url);
    let notification = document.createElement("div");
    notification.textContent = "URL Saqlandi!";
    notification.className = "copyed";
    notification.style.position = "absolute";
    notification.style.top = `${e.clientY}px`;
    notification.style.left = `${e.clientX}px`;
    document.body.appendChild(notification);
    setTimeout(() => {
      document.body.removeChild(notification);
    }, 500);
  }
  const [users, setUsers] = useState([]);
  const role = window.localStorage.getItem("role");
  // const [input, setInput] = useState(null)
  const getUsers = (input) => {
    if (role === "manager" && input) {
      http
        .get(`v2/admin-users-filter/?user_id=${input}`)
        .then((res) => {
          console.log("RESPONSE", res?.data);
          setUsers(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <>
      <div className={s.Header_top}>
        <div className={s.Header_top_left}>
          <div
            className={s.Hamburger_wrapper}
            onClick={() => setDashboard(true)}
          >
            <img src={Hamburger} alt="humburger" />
          </div>
          <h1 className={s.Header_top_left_text}>
            {RoutingData[role]?.map((item) => {
              if (location.pathname.toLowerCase() == item.path.toLowerCase()) {
                return item.name;
              }
            })}
          </h1>
        </div>

        <div className={s.Header_top_right}>
          {role == "ordinary_user" || role == "brand_manager" ? (
            <div className={s.Header_top_right_btn}>
              <Button onClick={(e) => copyUrl(e)} text={"Izdosh uchun link"} />
              <Button
                onClick={(e) => copySaleUrl(e)}
                text={"Sotuv uchun link"}
              />
            </div>
          ) : (
            <div className={s.Header_top_right_input}>
              <input
                className={s.Header_top_right_input_input}
                type={
                  role === "manager" && location.pathname === "/Customers"
                    ? "number"
                    : "text"
                }
                placeholder="Qidirish"
                onChange={(e) => {
                  setTimeout(() => {
                    // setInput(e.target.value);
                    getUsers(e.target.value);
                    // console.log(e.target.value);
                    // console.log(users);
                  }, 1000);
                }}
              />
              <img className={s.Header_top_right_input_icon} src={search} />
              {users.length ? (
                <div className={s.usersNames}>
                  {users?.map((user) => {
                    return (
                      <Link to={`/Customers/${user?.id}`}>
                        <p>
                          {user?.first_name} {user?.last_name}
                        </p>
                      </Link>
                    );
                  })}
                </div>
              ) : null}
            </div>
          )}
          {role == "ordinary_user" || role == "brand_manager" ? (
            <Link
              to={"/notifications"}
              className={s.Header_top_right_image_one_wrapper}
            >
              <img className={s.Header_top_right_image_one2} src={icon2} />
              <img className={s.Header_top_right_image_one} src={icon} />
            </Link>
          ) : null}

          {/* <img className={s.Header_top_right_image_two} src={ellips} /> */}
          <Button onClick={() => logOut()} text={"Chiqish"} />
        </div>
      </div>
      <div className={s.Headr_mobile}>
        <Link
          to={"/notifications"}
          className={s.Header_top_right_image_one_wrapper}
        >
          <img className={s.Header_top_right_image_one} src={group} />
        </Link>
        <h1 className={s.path_name}>
          {RoutingData[role]?.map((item) => {
            if (location.pathname.toLowerCase() == item.path.toLowerCase()) {
              return item.name;
            }
          })}
        </h1>
        <button onClick={logOut} className={s.logOut_mobile}>
          <img className={s.Header_top_right_image_one} src={group2} />
        </button>
      </div>
    </>
  );
}
