import React, { useState, useEffect } from "react";
import s from './Forgot.module.scss'
import { Button, Checkbox, Form, Input, Select } from 'antd';
import axios from "axios";
import { setToken } from "service/storage/token";
import Loader from "components/Loader";
import { toast } from 'react-toastify'
// import ReactCodeInput from 'react-verification-code-input';
import { useNavigate, useOutletContext } from "react-router-dom";
export default function Step2() {
    const [loading, setLoading] = useState(false)
    const [role, setRole] = useState(false)
    const navigate = useNavigate()
    const [token, setToken] = useOutletContext()
    const [num, setNum] = useState('998')
    const onFinish = (values) => {
        setLoading(true)
        const formdata = new FormData
        formdata.append('code', values?.code)
        axios
            .post('https://rizonwebappapi.pythonanywhere.com/api/verify/', formdata, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((res) => {
                setLoading(false)
                navigate('/ForgotPassword/Step3')
                setToken(res?.data?.access)
            })
            .catch((err) => {
                setLoading(false)
            })
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const [minut, setMinut] = useState(1)
    const [sec, setSec] = useState(59)
    useEffect(() => {
        const interval = setInterval(() => {
            if (sec > 0) {
                setSec(sec - 1);
            }
            if (sec === 0) {
                if (minut === 0) {
                    clearInterval(interval);
                } else {
                    setSec(59);
                    setMinut(minut - 1);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [sec]);
    function resendCode() {
        axios
        .get('https://rizonwebappapi.pythonanywhere.com/api/new-verify/', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then((res) => {
            setMinut(1)
            setSec(59)
        })
        .catch((err) => {
        })

    }
    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
            <Select defaultValue={'998'} onChange={(e) => setNum(e)} style={{ width: 80 }}>
                <Select.Option value="+998">+998</Select.Option>
            </Select>
        </Form.Item>
    );
    return (
        <div className={s.Login2}>
            {
                loading ?
                    <div className={s.Login_loader_back} >
                        <Loader />
                    </div>
                    :
                    null
            }
            <Form
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                style={{
                    maxWidth: 600,
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <p className={s.text} >Biz sizning telefon raqamingizga 6 xonali tasdiqlash kodi yubordik shu kodni kiriting</p>
                <Form.Item
                    name="code"
                    style={{
                        width: 340
                    }}
                    rules={[
                        {
                            required: true
                        },
                    ]}
                >
                    {/* <ReactCodeInput /> */}
                </Form.Item>
                {
                    minut == 0 && sec == 0 ?
                        <button onClick={() => resendCode()} className={s.minutes_btn} >Kodni qayta yuborish {minut < 10 ? `0${minut}` : minut} : {sec < 10 ? `0${sec}` : sec}</button>
                        :
                        <p className={s.minutes} >Kodni qayta yuborish {minut < 10 ? `0${minut}` : minut} : {sec < 10 ? `0${sec}` : sec}</p>
                }
                <Form.Item
                    wrapperCol={{
                        offset: 8,
                        span: 16,
                    }}
                >
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )

}