import React, { useEffect, useState } from "react";
import s from './User.module.scss'
import Input from "../../components/Input";
import Button from "../../components/Button";
import Inputdate from "../../components/Inputdate";
import Passport from "../../components/Passport";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import CuponMinus from "components/CuponMinus";
import SalaryPayUser from "components/SalaryPayUser";
import { usePost } from "hooks";
import {toast} from 'react-toastify'
export default function User({ data, employe,brand }) {
    console.log(data);
    const [state, setState] = useState()
    const [salary, setSalary] = useState(false)
    const {mutate,isLoading}=usePost()
    const [val, setVal] = useState({ year: '', month: '', day: '' })
    useEffect(() => {
        setState(data)
    }, [data])
    const { id } = useParams()
    function deleteEmp() {
        if (window.confirm('Ushbu mijozni ishdan olishga aminmisiz?')) {
            mutate({
                url: `/users/${id}/`,
                method: 'delete',
                onSuccess: successDelete,
                onError: errorDelete,
            })
        }
    }
    function successDelete(data) {
        window.history.back()
        toast.success("Xodim muvofaqqiyatli ishdan olindi !", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    function errorDelete(data) {
        toast.error("Xodim ishdan olinmadi qaytadan urunib ko'ring!", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    return (
      <div className={s.User}>
        <div className={s.User_top}>
          <div className={s.User_left}>
            <img
              src={`https://rizonwebapp.pythonanywhere.com${state?.photo}`}
            />
            <div className={s.User_right_top}>
              <h2 className={s.User_left_username}>
                {state?.first_name} {state?.last_name}
              </h2>
            </div>
            <div className={s.User_left_right}>
              {/* <div className={s.User_right_top_left} >
                            <h2 className={s.sum} >{''}</h2>
                            <p className={s.ball} >Umumiy ball</p>
                        </div> */}
              <div className={s.User_right_top_right}>
                <h2 className={s.sum}>{data?.salary}uzs</h2>
                <p className={s.ball}>Daromad</p>
              </div>
            </div>
          </div>
          <div className={s.User_right}>
            <div className={s.User_right_bottom}>
              {/* <CuponMinus data={state} /> */}
              <Link
                to={`/history/sale/${id}`}
                style={{ textDecoration: "none" }}
              >
                <Button text={"Sotuv tarixi"} />
              </Link>
              <Link
                to={`/salaryhistory/${id}`}
                style={{ textDecoration: "none" }}
              >
                <Button text={"Maosh tarixi"} />
              </Link>
              <Link
                to={`/user-tree/${id}`}
                style={{ textDecoration: "none" }}
              >
                <Button text={"Daraxt ko'rinishida"} />
              </Link>
              {employe ? (
                <>
                  <Button
                    onClick={() => setSalary(true)}
                    text={"Maosh To'lash"}
                  />
                  {salary && (
                    <SalaryPayUser data={state} setSalary={setSalary} />
                  )}
                </>
              ) : null}
            </div>
          </div>
        </div>
        <h1 className={s.edite_btn}>Tahrirlash</h1>
        <form className={s.input_form}>
          <Input
            disabled={true}
            value={state?.phone_number}
            name="Phone"
            type="text"
            label="Telefon raqam"
          />
          <Input
            disabled={true}
            value={state?.phoneNumTwo}
            name="phone2"
            type="text"
            label="Telefon raqam 2 "
          />
          <Input
            disabled={true}
            value={state?.address}
            name="address"
            type="text"
            label="Manzil"
          />
          <Inputdate
            setVal={setVal}
            val={val}
            disabled={true}
            name="dateOfBirth"
            value={state?.dateOfBirth}
          />
          <Passport disabled={true} name="passport" value={state?.passport} />
        </form>
        <div className={s.sub_btns}>
          <Button onClick={deleteEmp} color="#FF0000" text={"O'chirish"} />
          <Button disabled={true} text={"Saqlash"} />
        </div>
      </div>
    );
}