import React, { useState } from "react";
import s from './Notes.module.scss'
import Note from "../../../components/reminder/Note";
import GetAll from "modules/GetAll";
import Loader from "components/Loader";
export default function Notes() {
  return (
    <GetAll name={'/users-notifications/'} url={'/brand-manager-notifications/'} >
      {({ items }) => {
        if (items.isloading) return <Loader />
        return (
          <div className={s.Notes}>
            {
              items?.data?.notifications?.map((elem)=>(
                <Note item={elem} />
              ))
            }
          </div>
        )
      }}
    </GetAll>

  );
}