import GetAll from "modules/GetAll";
import s from "./Settings.module.scss";
import Loader from "components/Loader";
import card1 from "../../assets/icons/Group 19 (1).png";
import card3 from "../../assets/icons/Group 19 (3).png";
import Card from "components/Card";
import React, { useState } from "react";
import Button from "components/Button";
import { Form, Input, Modal } from "antd";
import { http } from "service";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";

const Settings = () => {
  const [bonusModal, setBonusModal] = useState({ data: null, isOpen: false });
  const [passwordModal, setPasswordModal] = useState({
    data: null,
    isOpen: false,
  });
  const [loading, setLoading] = useState(false)
  const queryClient = useQueryClient();

    const bonusChange = (values) => {
      setLoading(true);
      // console.log('Form values',values);
      const formdata = new FormData();
    //   formdata.append("user", bonusTransferModal?.data?.user?.id);
      formdata.append("bonus_account_lifetime_month", values?.bonus_lifetime);
      formdata.append("bonus_account_lifetime_month_break", values?.bonus_break);
    //   formdata.append("amount", +values?.amount);
      http
        .post(`admin-parametrs/`, formdata)
        .then((res) => {
          // if (res.ok) {
        //   console.log(res);
          toast.success(res?.data?.message ? res?.data?.message : "SUCCESSFUL");
          setLoading(false);
          queryClient.invalidateQueries("admin-parametrs/");
          // }
          // throw new Error(res?.message ? res?.message : "Something went wrong");
        })
        .catch((err) => {
        //   console.log("ERROR", err);
          toast.error(
            err?.response?.data?.message
              ? err?.response?.data?.message
              : err.message
          );
          setLoading(false);
        });
    };


    const passwordChange = (values) => {
      setLoading(true);
      // console.log('Form values',values);
      const formdata = new FormData();
      //   formdata.append("user", bonusTransferModal?.data?.user?.id);
      formdata.append("password", values?.password);
      formdata.append("confirm_password", values?.confirm_password);
      //   formdata.append("amount", +values?.amount);
      http
        .put(`reset-password/`, formdata)
        .then((res) => {
          // if (res.ok) {
          //   console.log(res);
          toast.success(res?.data?.message ? res?.data?.message : "SUCCESSFUL");
          setLoading(false);
          // }
          // throw new Error(res?.message ? res?.message : "Something went wrong");
        })
        .catch((err) => {
          //   console.log("ERROR", err);
          toast.error(
            err?.response?.data?.message
              ? err?.response?.data?.message
              : err.message
          );
          setLoading(false);
        });
    };

  return (
    <GetAll name={"admin-parametrs/"} url={"admin-parametrs/"}>
      {({ items }) => {
        console.log(items.data);

        const card = [
          {
            icon: card1,
            title: "3X bonusi muddati",
            inner: items?.data?.bonus_parametrs?.bonus_account_lifetime_month
              ?.toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
            val: "oy",
          },
          {
            icon: card3,
            title: "3X bonus tanaffusi",
            inner: items?.data?.bonus_parametrs?.bonus_account_lifetime_month_break
              ?.toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
            val: "oy",
          },
        ];

        if (items.isLoading) return <Loader />;
        return (
          <div className={s.Settings_page}>
            <div className="container">
              <div className={s.cards_box}>
                {card?.map((elem, i) => (
                  <React.Fragment key={i}>
                    <Card item={elem} />
                  </React.Fragment>
                ))}
                <div className={s.Buttons}>
                  <Button
                    text={"Bonus o'zgartirish"}
                    onClick={() =>
                      setBonusModal({ ...bonusModal, isOpen: true })
                    }
                  />
                  <Button
                    text={"Parol o'zgartirish"}
                    onClick={() =>
                      setPasswordModal({ ...passwordModal, isOpen: true })
                    }
                  />
                </div>
              </div>

              {/* BONUS MODAL */}
              <Modal
                title={"Bonus vaqtlarini o'zgartirish"}
                centered
                open={bonusModal.isOpen}
                onCancel={() => setBonusModal({ ...bonusModal, isOpen: false })}
                footer={null}
                id="bonusMod"
              >
                <Form
                  name="user_bonus"
                  // className="login-form"
                  initialValues={{
                    bonus_lifetime:
                      items?.data?.bonus_parametrs
                        ?.bonus_account_lifetime_month,
                    bonus_break:
                      items?.data?.bonus_parametrs
                        ?.bonus_account_lifetime_month_break,
                  }}
                  onFinish={bonusChange}
                >
                  <Form.Item
                    name="bonus_lifetime"
                    rules={[
                      {
                        required: true,
                        message: "Please input your bonus lifetime!",
                      },
                    ]}
                    label="3X bonus muddati"
                  >
                    <Input type="number" placeholder="Bonus muddati" />
                  </Form.Item>
                  <Form.Item
                    name="bonus_break"
                    rules={[
                      {
                        required: true,
                        message: "Please input your bonus timeout!",
                      },
                    ]}
                    label="3X bonus tanaffusi"
                  >
                    <Input type="number" placeholder="Bonus tanaffusi" />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      disabled={loading}
                      text={loading ? "Loading" : "Yuborish"}
                      style={loading ? { backgroundColor: "gray" } : {}}
                    />
                  </Form.Item>
                </Form>
              </Modal>

              {/* PASSWORD MODAL */}
              <Modal
                title={"Parolni o'zgartirish"}
                centered
                open={passwordModal.isOpen}
                onCancel={() =>
                  setPasswordModal({ ...passwordModal, isOpen: false })
                }
                footer={null}
                id="passwordMod"
              >
                <Form name="admin_password" onFinish={passwordChange}>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input placeholder="Parol" />
                  </Form.Item>
                  <Form.Item
                    name="confirm_password"
                    rules={[
                      {
                        required: true,
                        message: "Please input confirm password!",
                      },
                    ]}
                  >
                    <Input placeholder="Parolni tasdiqlang" />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      disabled={loading}
                      text={loading ? "Loading" : "Yuborish"}
                      style={loading ? { backgroundColor: "gray" } : {}}
                    />
                  </Form.Item>
                </Form>
              </Modal>
            </div>
          </div>
        );
      }}
    </GetAll>
  );
};

export default Settings;
