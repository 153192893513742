import React, { useState } from "react";
import s from './ViewProductsAdmin.module.scss'
import Table from "components/Table/Table";
import add from 'assets/icons/Add_Plus_Circle.svg'
import history from 'assets/icons/Archive.svg'
import send from 'assets/icons/Share_iOS_Export.svg'
import Button from "components/Button";
import { generateDate } from "service/dates";
import AdminSallProduct from "components/AdminSallProduct";
import images from 'assets/icons/Arrow_Undo_Up_Left.svg'
import SallProductAdmin from "components/SallProductAdmin";
import { getLastMonth, getLastYear, thisMonth, formatDate, getThisYear } from 'service/dates'
import GetAll from "modules/GetAll";
import Loader from "components/Loader";
import { DateInput } from "components/DateInput";
import { useParams } from "react-router-dom";
export default function ViewProductsAdmin({ datas }) {
    const [filter, setFIlter] = useState(thisMonth())
    const { id } = useParams()
    let filters = [
        { name: 'O’tgan Oy', val: getLastMonth().slice(- 2) == "00" ? `${getLastYear()}-12` : getLastMonth() },
        { name: 'Hozirgi oy', val: thisMonth() },
        { name: 'O’tgan yil', val: getLastYear() },
        { name: 'Hozirgi yil', val: getThisYear() }
    ]
    function choose(e) {
        setFIlter(e)
    }
    const btn = [
        {
            text: 'Nasiya to’ldirish',
            icon: add
        },
        {
            text: 'Tarixni ko’rish',
            icon: history
        },
        {
            text: 'Mahsulot yuborish',
            icon: send
        },
    ]
    const column = [
        {
            title: 'Shartnoma raqami',
            key: 'id',
        },
        {
            title: 'Sana',
            key: 'date',
            render: (item) => {
                return (
                    <span>{generateDate(item)}</span>
                )
            }
        },
        {
            title: 'Dori nomi',
            key: 'name',
        },
        {
            title: 'Miqdori',
            key: 'amount',
        },
        {
            title: 'To’landi',
            key: 'paid',
            render: (item) => {
                return (
                    <span>{item + 'uzs'}</span>
                )
            }
        },
        {
            title: 'Nasiya',
            key: 'debt',
            render: (item) => {
                return (
                    <span>{item + 'uzs'}</span>
                )
            }
        },
        {
            title: 'Umumiy summa',
            key: 'summa',
            render: (item) => {
                return (
                    <span>{item + 'uzs'}</span>
                )
            }
        },
    ]
    function createObj(info) {
        let array = []
        info?.forEach(elem => {
            array.push({
                amount: elem.amount,
                date: elem.dateTime,
                name: elem.product.name,
                summa: elem.summa,
                debt: elem.debt,
                paid: elem.paid,
                id:elem.id
            })
        });
        return array
    }
    return (
        <GetAll name="Branch_inner_more" url={`/admin-warehouses/month/${filter}/warehouse/${id}`}>
            {({ items }) => {
                if (items.isLoading) return <Loader />
                return (
                    <div className={s.History}>
                        <div className={s.Button_wrapper} >
                            <div className={s.filetr_part} >
                                {
                                    filters.map((item) => (
                                        <button onClick={() => choose(item.val)} className={filter == item.val ? `${s.chosen}` : s.btns} >{item.name}</button>
                                    ))
                                }
                            </div>
                            <DateInput change={choose} />
                        </div>
                        <div className={s.History_top} >
                            <h1 className={s.History_title} >Tarixi</h1>
                        </div>
                        <div className={s.History_table} >
                            <Table column={column} data={createObj(items?.data?.warehouse_product_history)} />
                        </div>
                    </div>
                )
            }}
        </GetAll>

    )

}