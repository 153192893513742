import { useState } from "react";
import GetAll from "../../../modules/GetAll";
import { generateDate, getLastDay, getLastMonth, getLastYear } from "../../../service/dates";
import s from "./home.module.scss";
import Loader from "../../../components/Loader";
import card1 from "../../../assets/icons/Group 19 (1).png";
import card2 from "../../../assets/icons/Group 19 (2).png";
import card3 from "../../../assets/icons/Group 19 (3).png";
import { DateInput } from "../../../components/DateInput";
import Card from "../../../components/Card";
import Button from '../../../components/Button'
import Table from "../../../components/Table/Table";
import SallProduct from "../../../components/SallProduct";
const Home = () => {
  const [filter, setFIlter] = useState();

  let filters = [
    { name: "O’tgan Oy", val: getLastMonth() },
    { name: "O’tgan 24 soat", val: getLastDay() },
    { name: "O’tgan Hafta", val: "" },
    { name: "O’tgan yil", val: getLastYear() },
  ];
  function choose(e) {
    setFIlter(e);
  }


  return (
    <GetAll name={"main_page"} url={`/employees-main/`}>
      {({ items }) => {
        const card = [
          {
            icon: card1,
            title: "Umumiy Savdo",
            inner: items?.data?.total_sales_summa
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
            val: "ball",
          },
          {
            icon: card2,
            title: "Mijozlar soni",
            inner: items?.data?.total_users
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
            val: "ta",
          },
          {
            icon: card3,
            title: "Umumiy ball",
            inner: items?.data?.total_coupon
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
            val: "ball",
          },
          {
            icon: card3,
            title: "Qoldiq Mahsulotlar",
            inner: items?.data?.residual_products_amount
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
            val: "ta",
          },
        ];
        if (items.isLoading) return <Loader />;
        return (
          <div className={s.Home}>
            <div className="container">
              <div className={s.cards_box}>
                {card?.map((elem, i) => (
                  <Card item={elem} key={i} percentPart={false} />
                ))}
              </div>
              <div className={s.charts_box}>
                <div className={s.Table1}>
                  <h1 className={s.Title}>Buyurtmalar jadvali</h1>
                  <Table column={column()} data={items?.data?.orders} />
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </GetAll>
  );
};

export default Home;

function column(){
    return[
  {
    title: "ID",
    key: "user",
    render:(item)=>{
      return(
        <span>{item?.user_id}</span>
      )
    }
  },
  {
    title: "Mahsulot nomi",
    key: "product",
    render:(item)=>{
      return(
        <span>{item?.name}</span>
      )
    }
  },
  {
    title: "Sana",
    key: "date",
    render:(item)=>{
      return(
        <span>{generateDate(item)}</span>
      )
    }
  },
  {
    title: "Summa",
    key: "product",
    render:(item,all)=>{
      return(
        <span>{item?.price}</span>
      )
    }
  },
  {
    title: "Jami",
    key: "amount",
     render: (item,all)=> {
      return <span style={all?.done ? {color:'#F4BE37'} : {color:'#002940'}} >{item}</span>;
  }
  },
  {
    title: "",
    key: "done",
    render: (item)=> {
        return <SallProduct icon={card3} /> ;
    }
  },
]
}
function data(){
    return [
      {
        id: 1,
        name: "Elbek Olimov",
        date: "11.01.2023",
        sum: "3 000 000",
        overall: "1200",
      },
      {
        id: 2,
        name: "Elbek Olimov",
        date: "11.01.2023",
        sum: "3 000 000",
        overall: "1200",
      },
      {
        id: 3,
        name: "Elbek Olimov",
        date: "11.01.2023",
        sum: "3 000 000",
        overall: "1200",
      },
      {
        id: 4,
        name: "Elbek Olimov",
        date: "11.01.2023",
        sum: "3 000 000",
        overall: "1200",
      },
      {
        id: 5,
        name: "Elbek Olimov",
        date: "11.01.2023",
        sum: "3 000 000",
        overall: "1200",
      },
    ];}