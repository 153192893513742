import React, { useState } from "react";
import s from "./UsersTree.module.scss";
import Tree from "react-d3-tree";
import { useGetAll } from "hooks";
import Loader from "components/Loader";
import { getLastMonth, getLastYear, getThisYear, thisMonth } from "service/dates";
import { DateInput } from "components/DateInput";
// import { Tree } from "antd";

const UsersTree = () => {
        const [filter, setFIlter] = useState(thisMonth());
        let filters = [
          { name: "O’tgan Oy", val: getLastMonth() },
          { name: "Hozirgi oy", val: thisMonth() },
          { name: "O’tgan yil", val: getLastYear() },
          { name: "Hozirgi yil", val: getThisYear() },
        ];
        function choose(e) {
          setFIlter(e);
        }
  // USER TREE
  const { data: treeData, isLoading: isTreeLoading } = useGetAll({
    name: "tree",
    url: `/get-own-family-tree/${filter}/`,
  });
    // console.log('DATA', treeData);
    console.log('tree',treeCorrect(treeData?.tree))
  function treeCorrect(tdata) {
    if (tdata) {
      const data = tdata?.map((el) => {
        return {
          name: el.user.first_name + " " + el.user.last_name + '\n  tree-score:' +el.user.tree_score + '\n   user-score:'+el.user.user_score,
          // key:el?.user?.user_id,
          children: el.tree.length ? treeCorrect(el.tree) : null,
        };
      });
      return data;
    }
  }
  //
  if (isTreeLoading) return <Loader />;
  return (
    <div className={s.UsersTree}>
      <div className="container">
        <div className={s.Button_wrapper}>
          <div className={s.filetr_part}>
            {filters.map((item) => (
              <button
                onClick={() => choose(item.val)}
                className={filter === item.val ? `${s.chosen}` : s.btns}
              >
                {item.name}
              </button>
            ))}
          </div>
          <DateInput change={choose} />
        </div>

        {treeData?.tree.length ? (
          <div className={s.Tree_wrapper}>
            {/* <Tree treeData={treeCorrect(treeData?.tree)} defaultExpandAll icon={()=><p>hello</p>}/> */}
            <Tree
              data={treeCorrect(treeData?.tree)}
              // rootNodeClassName={s.node__root}
              // branchNodeClassName={s.node__branch}
              // leafNodeClassName={s.node__leaf}
              // dimensions={{ height: 300, width: 500 }}
              nodeSize={{x:500, y:300}}
              orientation="vertical"
            />
          </div>
        ) : (
          <h1>Mijozlar yoq </h1>
        )}
      </div>
    </div>
  );
};

export default UsersTree;
