import React from "react";
import s from './AddProduct.module.scss'
import calendar from "../../assets/icons/send.svg";
import Button from "../Button";
export default function BrachAdd({ setState, state, postData, archive,minishop }) {
  return (
    <div className={s.container}>
      <div className="" style={{marginBottom:'40px'}} > 
        <div className={s.addProduct}>
          <h1 className={s.addProduct_title}>{minishop ? "Mini do'kon" : archive ? 'Filial' : 'Filial qo’shish'} </h1>
          <form action="">
            <div className={s.productInfo}>
              <label htmlFor="productName">
                {minishop ? "Mini do'kon" : 'Filial'}  to’liq nomi
                <input onChange={(e) => setState({ ...state, name: e.target.value })} value={state?.name} type="text" id="productName" />
              </label>
              <label htmlFor="create">
                Manzil
                <input onChange={(e) => setState({ ...state, address: e.target.value })} value={state?.address} type="text" id="create" />
              </label>
              <label htmlFor="">
                Telefon raqam
                <input onChange={(e) => setState({ ...state, phone: e.target.value })} value={state?.phone} type="text" id="" />
              </label>
            </div>
            <div>
              <p>
                *Lorem ipsum dolor sit amet consectetur. Sit ante curabitur
                diam lectus laoreet. Integer tellus ullamcorper sed sagittis
                venenatis.
              </p>
            </div>
            <div className={s.addProduct_detail}>
              <h1 className={s.addProduct__title}>Batafsil</h1>
              <textarea onChange={(e) => setState({ ...state, about: e.target.value })} value={state?.about} name="" id="" cols="30" rows="10"></textarea>
            </div>
            {
              archive ?
                null :
                <div className={s.send}>
                  <label className={s.uploadImg} htmlFor="addProduct_file">
                    Rasm yuklash
                    <img src={calendar} alt="" />
                    <input onChange={(e) => setState({ ...state, photo: e.target.files[0] })} type="file" id="addProduct_file" />
                  </label>
                  <Button onClick={(e) => state.name && state.photo ? postData(e) : null} disabled={state.name && state.photo ? false : true} text={"Saqlash"} />
                </div>
            }

          </form>
        </div>

      </div>
    </div>
  );
}