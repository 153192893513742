import React, { useState } from "react";
import s from './MedicalConsultant.module.scss'
import Card from "../../components/Card";
import { card, cardBranch } from '../../assets/db'
import Consultants from "../../container/Consultants/Consultants";
import Button from "../../components/Button";
import calendar from '../../assets/icons/Calendar_Event.png'
import GetAll from "../../modules/GetAll";
import Loader from "../../components/Loader";
import card1 from '../../assets/icons/Group 19 (1).png'
import card2 from '../../assets/icons/Group 19 (2).png'
import card3 from '../../assets/icons/Group 19 (3).png'
import { DateInput } from "components/DateInput";
import {getLastMonth,getLastYear,thisMonth,getThisYear} from '../../service/dates'
export default function MedicalConsultant() {
    const [filter, setFIlter] = useState(thisMonth())
    let filters = [
        { name: 'O’tgan Oy', val: getLastMonth().slice(- 2) == "00" ? `${getLastYear()}-12` : getLastMonth() },
        { name: 'Hozirgi oy', val: thisMonth() },
        { name: 'O’tgan yil', val: getLastYear() },
        { name: 'Hozirgi yil', val: getThisYear() }
    ]
    function choose(e) {
        setFIlter(e)
    }
    return (
        <GetAll name={'Med_page'} url={`medics/month/${filter}`} >
            {({items}) => {
                console.log(items);
                 const card =[
                    {
                        icon: card1,
                        title:'Umumiy Savdo',
                        inner:items?.data?.total_sales_summa.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") ,
                        val:"ball" 
                    },
                    {
                        icon: card2,
                        title:'Mijozlar soni',
                        inner:items?.data?.total_users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") ,
                        val:'ta'
                    },
                    {
                        icon: card3,
                        title:'Umumiy Kupon',
                        inner:items?.data?.total_coupon.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") ,
                        val:'ball' 
                    },
                    {
                        icon: card3,
                        title:'Umumiy ball',
                        inner:items?.data?.total_ball.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") ,
                        val:'ta' 
                    },
                ]
                if (items.isLoading) return <Loader/> 
                return (
                    <div className={s.MedicalConsultant} >
                        <div className="container" >
                            <div className={s.Button_wrapper} >
                                <div className={s.filetr_part} >
                                    {
                                        filters.map((item) => (
                                            <button onClick={() => choose(item.val)} className={filter == item.val ? `${s.chosen}` : s.btns} >{item.name}</button>
                                        ))
                                    }
                                </div>
                                <DateInput change={choose} />
                            </div>
                            <div className={s.cards_box} >
                                {
                                    card?.map((elem, i) => (
                                        <Card item={elem} key={i} />
                                    ))
                                }
                            </div>
                            <Consultants refatch={items.refetch} data={items?.data?.medics} />
                        </div>
                    </div>
                )
            }}
        </GetAll>


    )
}