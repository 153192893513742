import React, { useState } from "react";
import s from './InnerPage.module.scss';
import Card from "components/Card";
import BarChart from 'components/BarChart'
import History from "container/History";
import BigCard from "components/BigCard";
import Button from "components/Button";
import { usePost } from "hooks";
import calendar from 'assets/icons/Calendar_Event.png'
import GetUpdate from "modules/GetUpdate";
import add from 'assets/icons/Add_Plus_Circle2.svg'
import { getLastMonth, getLastYear, thisMonth, formatDate, getThisYear } from 'service/dates'
import AddEmpModal from "components/AddEmpModal";
import { useNavigate, useParams,Link } from "react-router-dom";
import Loader from "components/Loader";
import { toast } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css";
import { DateInput } from "components/DateInput";
import BrachAdd from "components/addBranch/BrachAdd";
import ViewBranch from "components/viewBranch/ViewBranch";
import { arrayToColumns } from "service/columnGenerator";
import Table from "components/Table/Table";
export default function InnerPage() {
    const [filter, setFIlter] = useState(thisMonth())
    const [adds, setAdd] = useState(false)
    const navigate = useNavigate()
    let filters = [
      { name: 'O’tgan Oy', val: getLastMonth().slice(- 2) == "00" ? `${getLastYear()}-12` : getLastMonth() },
      { name: 'Hozirgi oy', val: thisMonth() },
      { name: 'O’tgan yil', val: getLastYear() },
      { name: 'Hozirgi yil', val: getThisYear() }
    ]
    function choose(e) {
        setFIlter(e)
    }
    function top() {
        window.scrollTo(0, 0)
    }
    const { id } = useParams()
    const { mutate, isLoading } = usePost()
    function recovery(e) {
        const formdata = new FormData()
        formdata.append('deleted', true)
        mutate({
            url: `admin-warehouses/${id}/`,
            method: 'put',
            data: formdata,
            onSuccess: success,
            onError: error
        })

    }
    function success(params) {
        navigate('/Branches')
        toast.success("Filial o'chirildi!", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    function error(params) {
        toast.error("Filial o'chirilmadi!", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    
    return (
        <GetUpdate name="Branch_inner" url={`v2/manager-warehouses/month/${filter}/warehouse/${id}`} >
            {({ items, }) => {
                function getProdDate(data) {
                    let obj = {}
                    data?.forEach((item) => {
                        obj[item.product.name] = item.sale_amount
                    })
                    return obj
                }
                console.log(items?.data);
                const cardBranch = [
                    {
                        icon: '',
                        title: 'Umumiy Savdo',
                        inner: items?.data?.warehouse_sales_summa.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' ' + 'uzs',
                    },
                    {
                        icon: '',
                        title: 'Nasiya',
                        inner: items?.data?.debt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' ' + 'uzs',
                    },
                    {
                        icon: '',
                        title: ' Mahsulotlar',
                        inner: items?.data?.product_balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' ' + 'ta',
                    },
                ]
                // const cardChart = [
                //     {
                //         title: 'Savdo',
                //         inner: '*Lorem ipsum dolor sit amet consectetur. Sit ante curabitur diam lectus laoreet. ',
                //         info: items?.data?.sale
                //     },
                //     {
                //         title: 'Mijozlar maosh grafigi (dona)',
                //         inner: '*Lorem ipsum dolor sit amet consectetur. Sit ante curabitur diam lectus laoreet. ',
                //         info: items?.data?.paid_users_salary
                //     },
                //     {
                //         title: 'Xodimlar maoshi grafigi',
                //         inner: '*Lorem ipsum dolor sit amet consectetur. Sit ante curabitur diam lectus laoreet. ',
                //         info: items?.data?.employee_salary
                //     },
                //     {
                //         title: 'Mahsulot grafigi (dona)',
                //         inner: '*Lorem ipsum dolor sit amet consectetur. Sit ante curabitur diam lectus laoreet. ',
                //         info: getProdDate(items?.data?.product_sales_data)
                //     },
                // ]

                if (items?.isFetching) return <Loader />

                const yearProducts = arrayToColumns(items?.data?.year_products_given_data)

                return (
                  <div className={s.InnerPage}>
                    {isLoading && (
                      <div className={s.loader_wrapper}>
                        <Loader />
                      </div>
                    )}
                    <div className="container">
                      <div className={s.Button_wrapper}>
                        <div className={s.filetr_part}>
                          {filters.map((item) => (
                            <button
                              onClick={() => choose(item.val)}
                              className={
                                filter == item.val ? `${s.chosen}` : s.btns
                              }
                            >
                              {item.name}
                            </button>
                          ))}
                        </div>
                        <DateInput change={choose} />
                        <Link
                          to={`/sallhistory/${id}`}
                          style={{ textDecoration: "none" }}
                        >
                          <Button text={"Sotuv tarixi"} />
                        </Link>
                      </div>

                      <div className={s.cards_box}>
                        {cardBranch?.map((elem, i) => (
                          <Card item={elem} key={i} />
                        ))}
                      </div>

                      <div className={s.cards_box}>
                        {items?.data?.employees?.map((elem, i) => (
                          <BigCard key={i} datas={items.data} elem={elem} />
                        ))}
                        <img
                          onClick={() => setAdd(true)}
                          style={{ cursor: "pointer" }}
                          src={add}
                        />
                      </div>
                      <History
                        datas={items?.data?.warehouse_product_history}
                      />
                      <div className={s.Table}>
                        <h1>{filter.slice(0, 4)}-yil mahsulot tarixi</h1>
                        <Table
                          column={yearProducts}
                          data={items?.data?.year_products_given_data}
                          numbers
                        />
                      </div>

                      <div style={{ marginTop: "30px" }}>
                        <BrachAdd
                          archive={true}
                          state={items?.data?.warehouse}
                        />
                        {items?.data?.warehouse?.photo ? (
                          <ViewBranch
                            archive={true}
                            state={items?.data?.warehouse}
                          />
                        ) : null}
                        <Button onClick={recovery} text={"Filial o'chirish"} />
                      </div>
                      {/* <div className={s.charts_box} >
                                <h3 className={s.cards_box_title} >{items?.data?.warehouse?.name} savdo aylanmalari</h3>
                                <div className={s.charts_box_inner} >
                                    {
                                        cardChart?.map((elem, i) => (
                                            <BarChart elem={elem} />
                                        ))
                                    }
                                </div>
                            </div> */}
                      {adds ? (
                        <AddEmpModal
                          refetch={items?.refetch}
                          setAdd={setAdd}
                          werehouse={items?.data?.warehouse}
                        />
                      ) : null}
                    </div>
                  </div>
                );
            }}
        </GetUpdate>


    )
}