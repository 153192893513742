import React, { useState } from "react";
import s from "./SalaryHistory.module.scss";
import Table from "components/Table/Table";
import GetAll from "modules/GetAll";
import { useParams } from "react-router-dom";
import Loader from "components/Loader";
import Button from "components/Button";
import { usePost } from "hooks";
import { toast } from 'react-toastify'
export default function SallHistory() {
  const { id } = useParams()

  const { mutate, isLoading } = usePost()


  return (
    <GetAll url={`/admin-warehouses/${id}/sales/`}>
      {({ items }) => {
          const column = [
            // {
            //   title: "No",
            //   key: "no",
            // },
            // {
            //   title: " Sana",
            //   key: "date",
        
            // },
            {
              title: "Mijoz ID",
              key: "user",
              render: (item) => {
                return (
                  <span>{item?.user_id}</span>
                )
              }
            },
            {
              title: "Mahsulot",
              key: "product",
              render: (item) => {
                return (
                  <span>{item?.name}</span>
                )
              }
            },
            {
              title: "Miqdori",
              key: "amount",
            },
            {
              title: "Summa",
              key: "summa",
            },
            {
              title: "Xodim",
              key: "employee",
              render: (item) => {
                return (
                  <span>{item?.user?.first_name} {item?.user?.last_name}</span>
                )
              }
            },
            {
              title: "Filial",
              key: "warehouse",
              render: (item) => {
                return (
                  <span>{item?.name}</span>
                )
              }
            },
            {
              title: "Harakat",
              key: "id",
              render: (item) => {
                return (
                  <Button onClick={() => DeleteSale(item)} text={"O'chirish"} />
                )
              }
            },
          ];
          function DeleteSale(e) {
            mutate({
              url: `/admin-sales/${e}/`,
              method: 'get',
              onSuccess: success,
              onError: error
            })
          }
          function success(params) {
            toast.success("Sotuv o'chirildi !", {
              position: "top-right",
              autoClose: 5000,
            });
            items?.refetch()
          }
          function error(params) {
            toast.error("Sotuv o'chirilmadi !", {
              position: "top-right",
              autoClose: 5000,
            });
          }
        if (items.isLoading) return <Loader />
        if (items.isRefetching) return <Loader />
        if (isLoading) return <Loader />
        return (
          <div className={s.SalaryHistory}>
            <div className="container">
              <div className={s.HistoryInfo}>
                <Table column={column} data={items?.data} />
              </div>
            </div>
          </div>
        )
      }}
    </GetAll>
  );
}
