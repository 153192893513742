import { useEffect, useState } from "react";
import GetAll from "../../../modules/GetAll";
import { getLastDay, getLastMonth, getLastYear } from "../../../service/dates";
import s from "./product.module.scss";
import Loader from "../../../components/Loader";
import card1 from "../../../assets/icons/Group 19 (1).png";
import card2 from "../../../assets/icons/Group 19 (2).png";
import card3 from "../../../assets/icons/Group 19 (3).png";
import calendar from "../../../assets/icons/Calendar_Event.png";
import Button from "../../../components/Button";
import { DateInput } from "../../../components/DateInput";
import Card from "../../../components/Card";
import Table from "../../../components/Table/Table";
import ProductDistribution from "../../../container/ProductDistribution";
import BarChartBig from "components/BarChartBig";
import { Form, Input, Select } from "antd";
import { http } from "service";
import { toast } from "react-toastify";
import Modal from "components/Modal";
import { useQueryClient } from "@tanstack/react-query";
const Product = () => {
  const [cashbackModal, setCashbackModal] = useState({
    isOpen: false,
    data: null,
  });
  const [loading, setLoading] = useState(false);
  const [bonusTransferLoading, setBonusTransferLoading] = useState(false);
  const queryClient = useQueryClient();
  const [products, setProducts] = useState([]);
  const [prodPrice, setProdPrice] = useState(0);
  const [sum, setSum] = useState(0)

  // const form = Form.useForm()
  // const amount = Form.useWatch('amount', form)

  const onFinish = (values) => {
    setLoading(true);
    http
      .get(`employees-get-user-bonus-account-data/${values?.userId}/`)
      .then((res) => {
        console.log("onfinifh", res.data);
        setCashbackModal({ ...cashbackModal, data: res.data });
        // setBonusUseModal({ ...bonusUseModal, data: res.data });
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    http.get("/employees-warehouse").then((res) => {
      // console.log("PRODUCTS", res.data);
      setProducts(res?.data?.products);
    });
  }, []);

  const sellProd = (values) => {
    setBonusTransferLoading(true);
    // console.log('Form values',values);
    const formdata = new FormData();
    formdata.append("user", cashbackModal?.data?.user?.id);
    formdata.append("product", values?.product);
    formdata.append("amount", values?.amount);
    // formdata.append("amount", +values?.amount);
    http
      .post(`employees-sale-products-fr-user-bonus/`, formdata)
      .then((res) => {
        // if (res.ok) {
        // console.log(res);
        toast.success(res?.data?.message ? res?.data?.message : "SUCCESSFUL");
        setBonusTransferLoading(false);
        queryClient.invalidateQueries("admin-user-bonus-accounts-data/");
        // }
        // throw new Error(res?.message ? res?.message : "Something went wrong");
      })
      .catch((err) => {
        // console.log("ERROR", err);
        toast.error(
          err?.response?.data?.message
            ? err?.response?.data?.message
            : err.message
        );
        setBonusTransferLoading(false);
      });
  };

  function cards(items) {
    return [
      {
        icon: card1,
        title: "Umumiy Savdo",
        inner: items?.data?.total_sales_summa
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
        val: "ball",
      },
      {
        icon: card2,
        title: "Mijozlar soni",
        inner: items?.data?.total_users
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
        val: "ta",
      },
      {
        icon: card3,
        title: "Umumiy ball",
        inner: items?.data?.total_coupon
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
        val: "ball",
      },
    ];
  }
  function GetData(data) {
    let obj = {};
    Object.keys(data)?.forEach((elem) => {
      obj[elem] = { sales_amount: data[elem].product_sale_amount };
    });
    return obj;
  }
  return (
    <GetAll name={"product"} url={`/employees-products/`}>
      {({ items }) => {
        if (items.isLoading) return <Loader />;
        // console.log("ITEMS", items.data);
        return (
          <div className={s.Product}>
            <div className="container">
              {/* <div className={s.Button_wrapper} >
                <div className={s.filetr_part} >
                  {
                    filters.map((item) => (
                      <button onClick={() => choose(item.val)} className={filter == item.val ? `${s.chosen}` : s.btns} >{item.name}</button>
                    ))
                  }
                </div>
                <Button text={'Oraliq tanlash'} icon={calendar} />
              </div> */}
              <div className={s.cards_box}>
                {cards(items)?.map((elem, i) => (
                  <Card item={elem} key={i} />
                ))}
              </div>
              <div className={s.buttons}>
                <Button
                  text={"Cashbackdan sotish"}
                  onClick={() => setCashbackModal({ data: null, isOpen: true })}
                />
              </div>
              <div className="s.charts_box">
                <ProductDistribution
                  employee={true}
                  data={items?.data?.sales_history?.slice(0, 6)}
                />
              </div>
              <h1 className={s.Table_title}>Mahsulotlar savdo ulushi </h1>
              <BarChartBig
                elem={GetData(items?.data?.month_product_sales_data)}
              />
            </div>
            {cashbackModal.isOpen ? (
              <Modal modal={cashbackModal} setModal={setCashbackModal}>
                {(data) => {
                  return (
                    <div>
                      <Form
                        name="cashback"
                        // className="login-form"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                      >
                        <Form.Item
                          name="userId"
                          rules={[
                            {
                              required: true,
                              message: "Please input your User ID!",
                            },
                          ]}
                        >
                          <Input placeholder="User ID" />
                        </Form.Item>
                        <Form.Item>
                          <Button
                            disabled={loading}
                            text={loading ? "Loading" : "Yuborish"}
                            style={loading ? { backgroundColor: "gray" } : {}}
                          />
                        </Form.Item>
                      </Form>

                      {data ? (
                        <>
                          <div className={s.user_info}>
                            <div>
                              <p>
                                <span>Ism:</span>
                                {data?.user?.first_name}
                              </p>
                              <p>
                                <span>Familiya:</span>
                                {data?.user?.last_name}
                              </p>
                              <p>
                                <span>Tel raqam:</span>
                                {data?.user?.phone_number}
                              </p>
                            </div>
                            <div>
                              <p>
                                <span>Cashback:</span>
                                {data?.bonuses?.Cashback}
                              </p>
                              <p>
                                <span>ID:</span>
                                {data?.user?.id}
                              </p>
                            </div>
                          </div>
                          <Form
                            name="user_bonus_change"
                            initialValues={{ remember: true }}
                            onFinish={sellProd}
                            // form={form}
                          >
                            <Form.Item
                              name={"product"}
                              rules={[
                                {
                                  required: true,
                                  message: "Please input bonus type!",
                                },
                              ]}
                            >
                              <Select
                                options={products.map((el) => {
                                  return { value: el.id, label: el.name };
                                })}
                                placeholder={"Choose product"}
                                onChange={(el) => {
                                  const price = products.filter(
                                    (product) => product.id === el
                                  )[0]?.price;
                                  setProdPrice(price);
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              name="amount"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input amount!",
                                },
                              ]}
                            >
                              <Input
                                type="number"
                                placeholder="Amount"
                                onChange={(e) => {
                                  setSum(Number(e.target.value) * prodPrice);
                                  // console.log('SUM',sum);
                                }}
                              />
                            </Form.Item>
                            
                            <p className={s.sum_price}>
                              <span>SUMMA:</span>
                              {sum}
                            </p>
                            <Form.Item>
                              <Button
                                disabled={bonusTransferLoading}
                                text={
                                  bonusTransferLoading ? "Loading" : "Yuborish"
                                }
                                style={
                                  bonusTransferLoading
                                    ? { backgroundColor: "gray" }
                                    : {}
                                }
                              />
                            </Form.Item>
                          </Form>
                        </>
                      ) : null}
                    </div>
                  );
                }}
              </Modal>
            ) : null}
          </div>
        );
      }}
    </GetAll>
  );
};

export default Product;
