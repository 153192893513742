import { useState } from 'react';
import s from './Home.module.scss'
import { getLastMonth, getLastYear, getThisYear, thisMonth } from 'service/dates';
import { DateInput } from 'components/DateInput';
import card1 from "../../../assets/icons/Group 19 (1).png";
import Card from 'components/Card';
import OverallIncome from '../../../container/OverallIncome'
import GetAll from 'modules/GetAll';
import Loader from 'components/Loader';

const Home = () => {
  const [filter, setFIlter] = useState(thisMonth());
  let filters = [
    { name: "O’tgan Oy", val: getLastMonth() },
    { name: "Hozirgi oy", val: thisMonth() },
    { name: "O’tgan yil", val: getLastYear() },
    { name: "Hozirgi yil", val: getThisYear() },
  ];
  function choose(e) {
    setFIlter(e);
  }


  return (
    <GetAll name={'main_page_medic'} url={`/medics-main/month/${filter}/`}>
      {
        ({items})=>{
            const card = [
              {
                icon: card1,
                title: "Umumiy Savdo",
                inner: items?.data?.total_income
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
                val: "ball",
              },
            ];
            if (items.isLoading) return <Loader/>
          return (
            <div className={s.Home}>
              <div className="container">
                <div className={s.Button_wrapper}>
                  <div className={s.filetr_part}>
                    {filters.map((item) => (
                      <button
                        onClick={() => choose(item.val)}
                        className={filter == item.val ? `${s.chosen}` : s.btns}
                      >
                        {item.name}
                      </button>
                    ))}
                  </div>
                  <DateInput change={choose} />
                </div>
                <div className={s.cards_box}>
                  {card?.map((elem, i) => (
                    <Card item={elem} key={i} />
                  ))}
                </div>
                <div className={s.Tables_wrapper}>
                  <h1>Umumiy maosh tarixi</h1>
                  {
                    items?.data?.salary_history?.map((history)=>{
                      return <OverallIncome history={history}/>
                    })
                  }
                </div>
              </div>
            </div>
          );
        }
      }
    </GetAll>
  );
}

export default Home