import React, { useEffect, useState } from "react";
import s from './Passport.module.scss'
export default function Passport({value,validate,disabled,setState,state}) {
    const [val, setVal] = useState({serial:'',num:''})
    useEffect(()=>{
        if (value) {
            setVal({ serial:value?.split('').slice(0,2).join(''), num:value?.split('').slice(2,9).join('') })
        }
    },[value])
    function getSerial(e) {
        if (e.length < 3 ) {
            setVal({ ...val, serial: e.toLocaleUpperCase() })
            if (validate?.includes('passport')) {
                validate.splice(validate.indexOf('passport'),1)
            }
            setState({...state,passport:e.toLocaleUpperCase()+val.num})
        }
    }
    function getNum(e) {
        let nums = [1,2,3,4,5,6,7,8,9,0]
        if (!isNaN(e) ) {
            setVal({ ...val, num: e })
            if (validate?.includes('passport')) {
                validate.splice(validate.indexOf('passport'),1)
            }
            setState({...state,passport:val.serial+e})
        }
    }
    return (
        <div className={s.Passport}>
            <h1 className={s.Passport_title} >Pasport seriyasi</h1>
            <div className={s.Passport_inner} >
                <input style={validate?.some((el)=> el=='passport') ? {border:'1px solid red'} : null} disabled={disabled} onChange={(e) => getSerial(e.target.value)} maxLength={2} value={val?.serial} className={s.serial} type={'text'} placeholder='AA' />
                <input style={validate?.some((el)=> el=='passport') ? {border:'1px solid red'} : null} disabled={disabled} onChange={(e) => getNum(e.target.value)} maxLength={7} value={val?.num} className={s.number} type={'text'} placeholder='000 00 00' />
            </div>
        </div>
    )
}