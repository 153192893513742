import React, { useState } from "react";
import s from './SalaryPay.module.scss'
import Input from "../Input";
import Button from "../Button";
import  useGetone  from "../../hooks/useGetOne";
import { useParams } from "react-router-dom";
import Loader from "../Loader";
import cencel from '../../assets/icons/cancel-button.png'
import usePost from "../../hooks/usePost";
import { getThisDay } from "service/dates";
import {toast} from 'react-toastify'
export default function SalaryPayUser({ data,setSalary }) {

    const { id } = useParams()
    const [state, setState] = useState()
    const [state2, setState2] = useState({ forMonth: '' })
    const { mutate, isLoading } = useGetone()
    const { mutate:post, isLoading:loading } = usePost()
    function submitVal(e) {
        mutate({
            url: `/users/${id}/salary/pay/month/${e.target.value}/`,
            method: 'get',
            onSuccess: success
        })
        setState2({ ...state2, forMonth: e.target.value })
    }
    function success(data) {
        setState(data.data.salary)
    }
    console.log(state);
    function getMoney(e) {
        setState({ ...state, summa: e.target.value, })
    }
    function paySalary() {
        const formdata = new FormData
        formdata.append('user',id)
        formdata.append('paid',state.summa)
        formdata.append('date',state2.forMonth)
        post({
            url:'/users/salary/payments/',
            data:formdata,
            onSuccess:successpayment,
            onError:errorpayment
        })
        
    }
    function successpayment(data) {
        setSalary(false)
        toast.success("Mash muvoffaqiyatli to'landi!", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    function errorpayment(e) {
        console.log(e);
        toast.error("Mash to'lanmadi!", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    return (
        <div className={s.SalaryPay_wrapper} >
            {
                isLoading || loading ?
                    <div className={s.loaders} >
                        <Loader />
                    </div>
                    :
                    null
            }
            <div className={s.SalaryPay} >
                <h1 className={s.SalaryPay_title} >Maosh to'lash</h1>
                <img onClick={()=>setSalary(false)} src={cencel} />
                <div className={s.SalaryPay_middle} >
                    <div className={s.SalaryPay_middle_inner} >
                        <h1 className={s.title} >{data?.employee?.first_name} {data?.employee?.last_name}</h1>
                        <p className={s.text} >Sotuvchi</p>
                    </div>
                    <div className={s.SalaryPay_middle_inner} >
                        <p className={s.text} >Hodim haqqi</p>
                        <h1 className={s.title} >{data?.total_salary}uzs</h1>
                    </div>
                </div>
                <form>
                    <Input submitVal={submitVal} value={state2?.forMonth} type='month' name='forMonth' label='Sanasi' />
                    <Input disabled={true} value={state?.salary} type='text' name='text' label='Maosh miqdori' />
                    <Input submitVal={getMoney} value={state?.summa} type='numbar' name='summa' label="To'lanayotgan summa" />
                </form>
                {
                    state?.summa ?
                        <Button onClick={()=>paySalary()} text="To'lash" />
                        :
                        null
                }
            </div>
        </div>

    )

} 