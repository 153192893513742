import React from "react";
import "../components/ProductInfo/Product_info.css";
import reviewImg from "../public/images/reviewImg.png";
import { generateDate } from '../service/dates/index'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Comments = ({ item }) => {
  // console.log('Comment',item);
  const info2 = [
    {
      img: reviewImg,
      name: "Aziz Shukurov",
      sername: "Shifokor",
      date: "07.03.2023",
      id: 1,
    },
    {
      img: reviewImg,
      name: "Aziz Shukurov",
      sername: "Shifokor",
      date: "07.03.2023",
      id: 2,
    },
    {
      img: reviewImg,
      name: "Aziz Shukurov",
      sername: "Shifokor",
      date: "07.03.2023",
      id: 3,
    },
    {
      img: reviewImg,
      name: "Aziz Shukurov",
      sername: "Shifokor",
      date: "07.03.2023",
      id: 4,
    },
  ];
  return (
    <div className="product_review_cards" key={item?.id}>
      <div className="product_review_card" >
        <div className="product_review_card_top">
          <img src={'https://rizonwebappapi.pythonanywhere.com/' + item?.photo} alt="" />
          <div className="product_review_card_top_text">
            <h1>{item?.full_name}</h1>
            {/* <p>{item.sername}</p> */}
          </div>
        </div>
        <div className="product_review_card_middle">
          <p>{item?.comment}</p>
          {/* <p className="mt-5">Sizga ham tavsiya qilaman 👍</p> */}
        </div>
        <div className="product_review_card_bottom">
          <p>{generateDate(item?.dateTime)}</p>
        </div>
      </div>
    </div>
  );
};

export default Comments;
