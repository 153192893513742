import React from "react";
import s from './WarehouseCard.module.scss'
export default function WarehouseCard({item,key}) {
    return (
        <div key={key} className={s.WarehouseCard} >
            <img className={s.WarehouseCard_image} src={'https://rizonwebappapi.pythonanywhere.com/'+item?.photo_link} />
            <h1 className={s.WarehouseCard_name} >{item?.name}</h1>
            <p className={s.WarehouseCard_sum} >{item?.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}uzs</p>
            <p className={s.WarehouseCard_info} >Ishlab chiqaruvchi: <a target='_blank' href={item?.about}>{item?.manufacturer}</a> </p>
        </div>
    )
}