import GetAll from "modules/GetAll";
import s from "./employeeAccountNumbers.module.scss";
import Loader from "components/Loader";
import card1 from "../../../assets/icons/Group 19 (1).png";
import React, { useState } from "react";
import Card from "components/Card";
import Table from "components/Table/Table";
import Button from "components/Button";
import Modal from "components/Modal";
import { Form, Input, Select } from "antd";
import { http } from "service";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";

const EmployeeAccountNumbers = () => {
    const [modal, setModal] = useState({data:null, isOpen:false});
      const [loading, setLoading] = useState(false);
      const [bonusTransferLoading, setBonusTransferLoading] = useState(false);
      const queryClient = useQueryClient();

    const columns = [
      {
        title: "F.I.Sh",
        render: (el, data) => `${data?.first_name} ${data?.last_name}`,
      },
      {
        title: "User ID",
        key: "user_id",
      },
      {
        title: "Status",
        key: "status",
      },
      {
        title: "Bonus turi",
        key: "bonus_type",
      },
      {
        title: "Miqdor",
        key: "amount",
        render: (el) => (
          <span
            style={el < 0 ? { color: "red" } : el > 0 ? { color: "green" } : {}}
          >
            {el}
          </span>
        ),
      },
      {
        title: "Izoh",
        key: "comment",
      },
      {
        title: "Oy",
        key: "month",
      },
    ];

      const onFinish = (values) => {
        setLoading(true);
        http
          .get(`employees-get-user-bonus-account-data/${values?.userId}/`)
          .then((res) => {
            // console.log(res.data);
            setModal({ ...modal, data: res.data });
            setLoading(false);
          })
          .catch((err) => {
            toast.error(err.message);
            setLoading(false);
          });
      };


      const bonusUse = (values) => {
        setBonusTransferLoading(true);
        // console.log('Form values',values);
        const formdata = new FormData();
        formdata.append("user", modal?.data?.user?.id);
        formdata.append("bonus_type", values?.bonus_type);
        formdata.append("amount", +values?.amount);
        http
          .post(
            `employees-use-user-bonus-account-data/`,
            formdata
          )
          .then((res) => {
            // if (res.ok) {
            console.log(res);
            toast.success(
              res?.data?.message ? res?.data?.message : "SUCCESSFUL"
            );
            setBonusTransferLoading(false);
            queryClient.invalidateQueries(
              "employees-users-bonus-account-data/"
            );
            // }
            // throw new Error(res?.message ? res?.message : "Something went wrong");
          })
          .catch((err) => {
            console.log("ERROR", err);
            toast.error(
              err?.response?.data?.message
                ? err?.response?.data?.message
                : err.message
            );
            setBonusTransferLoading(false);
          });
      };

  return (
    <GetAll
      name={"employees-users-bonus-account-data/"}
      url={"employees-users-bonus-account-data/"}
    >
      {({ items }) => {
        console.log(items.data);

        const card = [
          {
            icon: card1,
            title: "To'langan Bonuslar",
            inner: items?.data?.paid_bonuses
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, " "),
            val: "uzs",
          },
        ];

        if(items.isLoading) return <Loader/>
        return (
          <div className={s.EmployeeAccountNumbers}>
            <div className="container">
              {/* Modal */}
              {modal.isOpen ? (
                <Modal modal={modal} setModal={setModal}>
                  {(data) => {
                    console.log("MOdal data", data);
                    return (
                      <div>
                        <Form
                          name="user_bonus"
                          // className="login-form"
                          initialValues={{ remember: true }}
                          onFinish={onFinish}
                        >
                          <Form.Item
                            name="userId"
                            rules={[
                              {
                                required: true,
                                message: "Please input your User ID!",
                              },
                            ]}
                          >
                            <Input placeholder="User ID" />
                          </Form.Item>
                          <Form.Item>
                            <Button
                              disabled={loading}
                              text={loading ? "Loading" : "Yuborish"}
                              style={loading ? { backgroundColor: "gray" } : {}}
                            />
                          </Form.Item>
                        </Form>

                        {data ? (
                          <>
                            <div className={s.user_info}>
                              <div>
                                <p>
                                  <span>Ism:</span>
                                  {data?.user?.first_name}
                                </p>
                                <p>
                                  <span>Familiya:</span>
                                  {data?.user?.last_name}
                                </p>
                                <p>
                                  <span>Tel raqam:</span>
                                  {data?.user?.phone_number}
                                </p>
                              </div>
                              <div>
                                <p>
                                  <span>Cashback:</span>
                                  {data?.bonuses?.Cashback}
                                </p>
                                <p>
                                  <span>Voucher:</span>
                                  {data?.bonuses?.Voucher}
                                </p>
                                <p>
                                  <span>Umra:</span>
                                  {data?.bonuses?.Umra}
                                </p>
                                <p>
                                  <span>Travel:</span>
                                  {data?.bonuses?.Travel}
                                </p>

                                <p>
                                  <span>Avtobonus:</span>
                                  {data?.bonuses?.Avtobonus}
                                </p>
                              </div>
                            </div>
                            <Form
                              name="user_bonus_change"
                              // className="login-form"
                              initialValues={{ remember: true }}
                              onFinish={bonusUse}
                            >
                              <Form.Item
                                name={"bonus_type"}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input bonus type!",
                                  },
                                ]}
                              >
                                {/* <Typography.Text>Dan</Typography.Text> */}
                                <Select
                                  options={data?.bonus_types.map((el) => {
                                    return { value: el, label: el };
                                  })}
                                  placeholder={"Bonus turi"}
                                />
                              </Form.Item>

                              <Form.Item
                                name="amount"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input amount!",
                                  },
                                ]}
                              >
                                {/* <Typography.Text>Miqdor</Typography.Text> */}
                                <Input placeholder="Amount" />
                              </Form.Item>
                              <Form.Item>
                                <Button
                                  disabled={bonusTransferLoading}
                                  text={
                                    bonusTransferLoading
                                      ? "Loading"
                                      : "Yuborish"
                                  }
                                  style={
                                    bonusTransferLoading
                                      ? { backgroundColor: "gray" }
                                      : {}
                                  }
                                />
                              </Form.Item>
                            </Form>
                          </>
                        ) : null}
                      </div>
                    );
                  }}
                </Modal>
              ) : null}

              <div className={s.cards_box}>
                {card?.map((elem, i) => (
                  <React.Fragment key={i}>
                    <Card item={elem} />
                  </React.Fragment>
                ))}
              </div>

              <div className={s.bonus_btns}>
                <Button
                  text={"Bonus transfer"}
                  onClick={() => setModal({ isOpen: true, data: null })}
                />
              </div>

              <div className={s.Account_number_table}>
                <h1 className={s.Account_number_table_title}>
                  Hisob-raqamlar jadval
                </h1>
                <Table column={columns} data={items?.data?.history} />
              </div>
            </div>
          </div>
        );
      }}
    </GetAll>
  );
};

export default EmployeeAccountNumbers;
