import React from "react";
import s from './Archive.module.scss'
import Table from "components/Table/Table";
import { Link, useNavigate } from "react-router-dom";
import Button from "components/Button";
import GetAll from "modules/GetAll";
import Loader from "components/Loader";
import { generateDate } from "service/dates";
import { usePost } from "hooks";
import { toast } from 'react-toastify'
export default function Archive() {
    const navigate = useNavigate()
    const columnMini = [
        {
            title: "Do'kon nomi",
            key: 'name',
        },
        {
            title: 'Telefon raqami',
            key: 'phone',
        },
        {
            title: 'Manzil',
            key: 'address',
        },
        {
            title: 'Umumiy Ball',
            key: 'ball',
        },
        {
            title: "Batafsil",
            key: 'id',
            render: (item) => (
                <Link className={s.inner_butn} to={`/minishoparchive/${item}`} >
                    <Button text='Barafsil' />
                </Link>
            )
        },
    ]
    const dataMini = [
        {
            name: 'Toshkent dokoni',
            phone_number: '+998998045212',
            address: 'Yunusobod 11-29-40',
            ball: '30000',
            id: 1
        },
        {
            name: 'Toshkent filali',
            phone_number: '+998998045212',
            address: 'Yunusobod 11-29-40',
            ball: '30000',
            id: 2
        },
    ]
    const columnBranch = [
        {
            title: "FIlial nomi",
            key: 'name',
        },
        {
            title: 'Telefon raqami',
            key: 'phone',
        },
        {
            title: 'Manzil',
            key: 'address',
        },
        {
            title: 'Umumiy Ball',
            key: 'ball',
        },
        {
            title: "Batafsil",
            key: 'id',
            render: (item) => (
                <Link className={s.inner_butn} to={`/branchinner/${item}`} >
                    <Button text='Barafsil' />
                </Link>
            )
        },
    ]
    const dataBranch = [
        {
            name: 'Toshkent filali',
            phone_number: '+998998045212',
            address: 'Yunusobod 11-29-40',
            ball: '30000',
            id: 1
        },
        {
            name: 'Toshkent filali',
            phone_number: '+998998045212',
            address: 'Yunusobod 11-29-40',
            ball: '30000',
            id: 2
        },
    ]
    const columnProfduct = [
        {
            title: "Mahsulot nomi",
            key: 'name',
        },
        {
            title: 'Ichki narxi',
            key: 'price',
            render: (item) => (item.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + 'uzs')
        },
        {
            title: 'Tashqi narx',
            key: 'extraPrice',
            render: (item) => (item.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + 'uzs')
        },
        {
            title: "Batafsil",
            key: 'id',
            render: (item) => (
                <Link className={s.inner_butn} to={`/productinner/${item}`} >
                    <Button text='Barafsil' />
                </Link>
            )
        },
    ]
    const dataProd = [
        {
            name: 'Ofiyat 8',
            price_inner: '50 000sum',
            price_out: '100 000sum',
            id: 1
        },
        {
            name: 'Ofiyat 8',
            price_inner: '50 000sum',
            price_out: '100 000sum',
            id: 2
        },
    ]
    const columnEmp = [
        {
            title: "Xodim ID",
            key: 'user_id',
        },
        {
            title: 'Isim va familiyasi',
            key: 'first_name',
            render: (item, all) => (item + ' ' + all.last_name)

        },
        {
            title: 'Telefon raqam',
            key: 'phone_number',
        },
        {
            title: "Batafsil",
            key: 'id',
            render: (item) => (
                <Link className={s.inner_butn} to={`/employeinner/${item}`} >
                    <Button text='Barafsil' />
                </Link>
            )
        },
    ]
    const dataEmp = [
        {
            user_d: '423234',
            name: 'Azamkhuja Vosiljonov',
            phone_number: '+998998045212',
            id: 1
        },
        {
            user_d: '423234',
            name: 'Azamkhuja Vosiljonov',
            phone_number: '+998998045212',
            id: 2
        },
    ]

    const dataUser = [
        {
            name: '423234',
            date: '12-09-2023',
            name_prod: 'Ofiyat 5',
            num: '3',
            id: 1
        },
        {
            name: '423234',
            date: '12-09-2023',
            name_prod: 'Ofiyat 5',
            num: '3',
            id: 2
        },
    ]
    const { mutate, isLoading } = usePost()

    return (
        <GetAll name={'archive'} url={'/admin-archive/'} >
            {({ items }) => {
                                const columnUser = [
                                    {
                                        title: "Mijoj ID",
                                        key: 'user_id',
                                    },
                                    {
                                        title: 'Sana',
                                        key: 'date',
                                        render: (item, all) => (generateDate(item))
                
                                    },
                                    {
                                        title: 'Isim va familiya',
                                        key: 'first_name',
                                        render: (item, all) => (item + all?.last_name)
                                    },
                                    {
                                        title: 'Telefon raqami',
                                        key: 'phoneNumTwo',
                                    },
                                    {
                                        title: "Passport",
                                        key: 'passport',
                                    },
                                    {
                                        title: 'Tiklash',
                                        key: 'id',
                                        render: (item, all) => (
                                            <Button onClick={() => recovery(all?.id)} text='Tiklash' />
                                        )
                
                                    },
                                ]
                    function recovery(id) {
                        const formdata = new FormData()
                        formdata.append('deleted', false)
                        mutate({
                            url: `admin-archive/user/${id}/`,
                            method: 'put',
                            data: formdata,
                            onSuccess: success,
                            onError: error
                        })
                
                    }
                    function success(params) {
                        navigate('/arxiv')
                        items?.refetch()
                        toast.success("Mijoz tiklandi!", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                
                    function error(params) {
                        toast.error("Mijoz tiklanmadi!", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }

                if (items.isLoading) return <Loader />
                return (
                    <div className={`${s.Archive} container `} >
                        <div className={s.Archive_table_wrapper} >
                            <h1 className={s.Archive_table_wrapper_title} >Mini dokon</h1>
                            <Table data={items?.data?.mini_warehouses} column={columnMini} />
                        </div>
                        <div className={s.Archive_table_wrapper} >
                            <h1 className={s.Archive_table_wrapper_title} >Filial</h1>
                            <Table data={items?.data?.warehouses} column={columnBranch} />
                        </div>
                        <div className={s.Archive_table_wrapper} >
                            <h1 className={s.Archive_table_wrapper_title} >Mahsulot</h1>
                            <Table data={items?.data?.products} column={columnProfduct} />
                        </div>
                        <div className={s.Archive_table_wrapper} >
                            <h1 className={s.Archive_table_wrapper_title} >Xodim</h1>
                            <Table data={items?.data?.employees} column={columnEmp} />
                        </div>
                        <div className={s.Archive_table_wrapper} >
                            <h1 className={s.Archive_table_wrapper_title} >Mijoz</h1>
                            <Table column={columnUser} data={items?.data?.users} />
                        </div>
                    </div>
                )
            }}

        </GetAll>

    )

}