import React, { useState } from "react";
import s from "./Product.module.scss";
import canada from "../../assets/icons/canada.png";
import Loader from "components/Loader";
import { Link } from "react-router-dom";
function CustomerProductview({ item, product, x, medik, setOrder }) {
  return (
    <Link to={`/InnerPageProdview/${item.id}`} style={{textDecoration:'none'}} className={s.Product}>
      <div className={s.product_img}>
        <img
          src={`https://rizonwebapp.pythonanywhere.com${item?.photo_link}`}
          alt=""
        />
      </div>
      <div className={s.product_info}>
        <h2>{item?.name}</h2>
        <h2>{item?.price}uzs</h2>
        <h3>Batafsil</h3>
        <p>{item?.about}</p>
        <h4 className={s.author}>
          Ishlab chiqaruvchi: <span>{item?.manufacturer}</span>
        </h4>
      </div>
    </Link>
  );
}

export default CustomerProductview;
