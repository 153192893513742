import React, { useState } from "react";
import s from './Archive.module.scss'
import ViewProduct from "components/viewProduct/ViewProduct";
import AddProduct from "components/addProduct/AddProduct";
import { useGetAll, usePost } from "hooks";
import { toast } from 'react-toastify'
import { useNavigate, useParams } from "react-router-dom";
import Loader from "components/Loader";
import user from '../../../assets/icons/woman2.png'
import Button from "components/Button";
import GetAll from "modules/GetAll";
export default function Product() {
    const [state, setState] = useState({ photo_link: user })
    const { id } = useParams()
    const navigate = useNavigate()
    const { mutate, isLoading } = usePost()
    function recovery(e) {
        const formdata = new FormData()
        formdata.append('deleted', false)
        mutate({
            url: `admin-archive/product/${id}/`,
            method: 'put',
            data: formdata,
            onSuccess: success,
            onError: error
        })

    }
    function success(params) {
        navigate('/arxiv')
        toast.success("Mahsulot tiklandi!", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    function error(params) {
        toast.error("Mahsulot tiklanmadi!", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    return (
        <GetAll name={'products-archive'} url={`manager-archive/product/${id}/`} >
            {({ items }) => {
                if (items?.isLoading) return <Loader />
                if (isLoading) return <Loader />
                return (
                    <div className={s.AddProducts}>
                        <div className="container" >
                            <AddProduct archive={true} medik={true} state={items?.data?.product} setState={setState} />
                            {
                                items?.data?.product?.photo_link ?
                                    <ViewProduct archive={true} medik={true} done={true} setState={setState} state={items?.data?.product} />
                                    :
                                    null
                            }
                                <Button onClick={recovery} text={'Qayta tiklash'} />
                        </div>
                    </div>
                )
            }}
        </GetAll>

    )

}