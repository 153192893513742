import GetAll from 'modules/GetAll';
import s from './profile.module.scss'
import userPhoto from 'assets/icons/woman2.png'
import Button from 'components/Button';
import Input from 'components/Input';
import Loader from 'components/Loader';
import { useNavigate } from 'react-router-dom';

const Profile = () => {
  const navigate = useNavigate()
  return (
    <GetAll name={'medic_profile'} url={'/medics-profile/'}>
      {
        ({ items }) => {

          const fullName =
            items?.data?.medic?.first_name +
            " " +
            items?.data?.medic?.last_name;
          if (items?.isLoading) return <Loader />
          return (
            <div className={s.Profile}>
              <div className="container">
                <div className={s.Wrapper}>
                  <div className={s.User}>
                    <div className={s.User_left}>
                      <div className={s.Image_wrapper}>
                        {
                          !items?.data?.medic?.photo ?
                            <img src={userPhoto} alt="user" />
                            :
                            <img src={'https://rizonwebappapi.pythonanywhere.com/' + items?.data?.medic?.photo} alt="user" />
                        }
                      </div>
                      <div className={s.User_fullname}>
                        <h1>{fullName}</h1>
                      </div>
                    </div>
                    <div className={s.User_right}>
                      <div className={s.Salary}>
                        <h1>{items?.data?.remaining_salary}</h1>
                        <h2>Haqdorlik</h2>
                      </div>
                      <div className={s.Salary_history_buttons}>
                        <Button text={"Maosh tarixi"} onClick={() => navigate('/Profile/Salary-history')} />
                        {/* <Button text={"Maosh to’lash"} /> */}
                      </div>
                    </div>
                  </div>
                  <div className={s.User_more_info}>
                    <div className={s.Products}>
                      <h1>Biriktirilgan mahsulot</h1>
                      <div className={s.Products_wrapper}>
                        {items?.data?.medic_products?.map((item, i) => {
                          return <p className={s.Product}>{item?.product?.name}</p>;
                        })}
                      </div>
                    </div>
                    <div className={s.Info_form}>
                      <h1>Tahrirlash</h1>
                      <form action="">
                        <div className={s.Inputs}>
                          <Input label={"Ism va Familiya"} value={fullName} />

                          <Input
                            label={"Telefon raqam 2"}
                            value={items?.data?.medic?.phoneNumTwo}
                          />
                          <Input
                            label={"Telefon raqam"}
                            value={items?.data?.medic?.phone_number}
                          />
                          <Input
                            label={"Manzil"}
                            value={items?.data?.medic?.address}
                          />
                          <Input
                            label={"Tug'ilgan sana"}
                            value={items?.data?.medic?.dateOfBirth}
                            type={"date"}
                          />
                          <Input
                            label={"Pasport seriyasi"}
                            value={items?.data?.medic?.passport}
                          />
                          <Input
                            label={"Ma'lumoti"}
                            value={items?.data?.medic?.degree}
                          />
                          <Input
                            label={"Tamomlagan oliygohi"}
                            value={items?.data?.medic?.university}
                          />
                        </div>
                      </form>
                    </div>
                    <div className={s.Bio_wrapper}>
                      <h1>Biografiya</h1>
                      <div>
                        <p>{items?.data?.medic?.university}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }
      }
    </GetAll>
  );
}

export default Profile