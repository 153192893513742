import React, { useState } from "react";
import s from './SallProduct.module.scss'
import Button from "../Button";
import Input from "../Input";
import send from '../../assets/icons/Share_iOS_Export.svg'
import usePost from "../../hooks/usePost";
import { useGetAll } from "../../hooks";
import Loader from "../Loader";
import x from '../../assets/icons/cancel-button.png'
import { toast } from 'react-toastify'
export default function SallProductAdmin({ icon, undo, text }) {
    const [state, setState] = useState({})
    let minus = '-'
    const [modal, setModal] = useState(false)
    const { data } = useGetAll({
        url: '/products/',
    })
    const { data: dataWarehouse } = useGetAll({
        url: '/warehouses/'
    })
    function submitVal(e) {
        setState({ ...state, [e.target.name]: e.target.value })
    }
    const { mutate, isLoading } = usePost()
    function getSum(e) {
        let sum = data?.filter(el => el.id == e.target.value)[0].price
        setState({ ...state, total_sum: sum, total_price: sum, amount: undo ?  '-'+ 1 : 1, product_id: e.target.value })
    }
    function colculate(e) {
        console.log(state.total_sum * e.target.value.split('-').join(''));
        if (undo) {
            if (e.target.value.split('-').join('') > 0) {
                setState({ ...state, total_price: state.total_sum * Number(e.target.value.split('-').join('')), amount: '-'+e.target.value.split('-').join('') })
            } else {
                setState({ ...state, total_price: state.total_sum, amount: e.target.value })
            }
        } else {
            if (e.target.value > 0) {
                setState({ ...state, total_price: state.total_sum * Number(e.target.value), amount: e.target.value })
            } else {
                setState({ ...state, total_price: state.total_sum, amount: e.target.value })
            }
        }
    }
    function postData() {
        const formdata = new FormData
        formdata.append('warehouse', state.warehouse)
        formdata.append('product', state.product_id)
        if (undo) {
            formdata.append('amount', state.amount)
            formdata.append('summa', minus + state.total_sum)
        } else {
            formdata.append('paid', state.nasiya)
            formdata.append('amount', state.amount)
            formdata.append('summa', state.total_sum)
        }
        mutate({
            url: `/products/distribution/`,
            method: 'post',
            data: formdata,
            onSuccess: successpost,
            onError: onErrorrpost
        })
    }
    function onErrorrpost(e) {
        toast.error("Mahsulot yuborilmadi!", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    function successpost(data) {
        setModal(false)
        toast.success("Mahsulot muvoffaqiyatli yuborildi!", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    return (
        <div className={s.SallProduct} >
            {
                isLoading ?
                    <div className={s.loaders_back} >
                        <Loader />
                    </div>
                    :
                    null
            }
            {
                modal ?
                    <div className={s.SallProduct_modal_back} >
                        <div className={s.SallProduct_modal}>
                            <img onClick={() => setModal(false)} className={s.SallProduct_modal_back_cencel} src={x} />
                            <div className={s.SallProduct_modal_inner} >
                                <select name='warehouse' onChange={(e) => submitVal(e)}>
                                    <option>Qabul qiluvchi filial</option>
                                    {
                                        dataWarehouse?.map((elem) => (
                                            <option value={elem.id} >{elem.name}</option>
                                        ))
                                    }
                                </select>
                                <select onChange={(e) => getSum(e)}>
                                    <option>Mahsulot nomi</option>
                                    {
                                        data?.map((elem) => (
                                            <option value={elem.id} >{elem.name}</option>
                                        ))
                                    }
                                </select>
                                {
                                    !undo ?
                                        <Input submitVal={submitVal} value={state?.nasiya} name='nasiya' type='number' label="Nasiya to'lov" />
                                        :
                                        null
                                }
                                <Input submitVal={colculate} value={state?.amount} name='amount' type='number' label='Umumiy miqdori' />
                            </div>
                            <div className={s.SallProduct_modal_bottom} >
                                <h1 className={s.SallProduct_modal_bottom_title} ><p>Summa:</p>{undo && state?.total_price ? minus + state?.total_price : state?.total_price}uzs</h1>
                                <Button onClick={() => state?.total_price ? postData() : null} disabled={state?.total_price ? false : true} text={'Mahsulot sotish'} icon={send} />
                            </div>
                        </div>
                    </div>
                    :
                    null
            }
            <Button onClick={() => setModal(true)} text={text ? text : 'Mahsulot yuborish'} icon={icon} />
        </div>
    )
}