export const objectToColumn = (obj) => {
    return Object.keys(obj).map((el) => {
      return {
        title: el,
        key: el,
        render: (val, data) => {
          return typeof val === "number"
            ? val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
            : val;
        },
      };
    });
}

export const arrayToColumns = (arr) => {
  return Object.keys(arr[0]).map((el) => {
      return {
        title: el,
        key: el,
        render:(val, data)=>{
          return typeof val === 'number' ? val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : val
        }
      };
    });
}

