import React, { useState } from "react";
import s from './AddProducts.module.scss'
import ViewProduct from '../../../../components/viewProduct/ViewProduct'
import AddProduct from "../../../../components/addProduct/AddProduct";
import GetAll from "../../../../modules/GetAll";
import { useParams } from "react-router-dom";
import Loader from "../../../../components/Loader";
export default function AddProducts() {
    const [image, setImage] = useState()
    const {id} = useParams()
    return (
        <GetAll name={'product-empyoyee'} url={`/employees-products/${id}`} >
            {({ items }) => {
                if ( items.isloading) return <Loader/>
                return (
                    <div className={s.AddProducts}>
                        <div className="container" >
                            {/* <AddProduct onchange={setImage} /> */}
                            {/* {
                                image ? */}
                                    <ViewProduct />
                                    {/* :
                                    null
                            } */}
                        </div>
                    </div>
                )
            }}
        </GetAll>

    )

}