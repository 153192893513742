import React, {  useState } from "react";
import s from './Forgot.module.scss'
import { Button, Checkbox, Form, Input, Select } from 'antd';
import axios from "axios";
import { setToken } from "service/storage/token";
import Loader from "components/Loader";
import { toast } from 'react-toastify'
import { useNavigate,useOutletContext } from "react-router-dom";
export default function Step1() {
    const [loading, setLoading] = useState(false)
    const [state, setState] = useState()
    const navigate = useNavigate()
    const [num,setNum]=useState('998')
    const [token,setToken]=useOutletContext()
    const onFinish = (values) => {
        setLoading(true)
        const formdata = new FormData
        formdata.append('email_or_phone', values.email ? values.email : "+"+num+ values.phone_number)
        axios
            .post('https://rizonwebappapi.pythonanywhere.com/api/users-forgot-password/', formdata)
            .then((res) => {
                setLoading(false)
                navigate('/ForgotPassword/Step2')
                setToken(res.data.access)
            })
            .catch((err) => {
                setLoading(false)
                toast.error("Foydfalanuvchi topilmadi", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            })
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
            <Select defaultValue={'998'} onChange={(e)=>setNum(e)} style={{ width: 80 }}>
                <Select.Option value="998">+998</Select.Option>
            </Select>
        </Form.Item>
    );
    return (
        <div className={s.Login1}>
            {
                loading ?
                    <div className={s.Login_loader_back} >
                        <Loader />
                    </div>
                    :
                    null
            }
            <Form
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                style={{
                    maxWidth: 600,
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    name="phone_number"
                    label="Phone"
                    rules={[{ required: !state?.email ? true : false, message: 'Please input your phone number!' }]}
                >
                    <Input readOnly={state?.email} onChange={(e)=>setState({...state,phone_number:e.target.value})}  addonBefore={prefixSelector} style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    wrapperCol={{
                        offset: 8,
                        span: 16,
                    }}
                >
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )

}