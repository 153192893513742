import React,{useState} from "react";
import s from './Promotion.module.scss'
import GetAll from "modules/GetAll";
import PromotionInnerMijoz from "components/PromotionInnerMijoz";
import Loader from "components/Loader";
import Button from "components/Button";
import { Link } from "react-router-dom";
import CustomProduct from "components/customerProduct/CustomProduct";
export default function Promotion() {

    return (
        <GetAll name={'user-promotions'} url={'/brand-manager-promotions/'} >
            {({ items }) => {
                if (items.isLoading) return <Loader />
                return (
                    <div className={s.Promotion} >
                        <div className="container" >
                            <div className={s.Promotion_header} >
                            <h1 className={s.Promotion_title} >Kutuvdagi: {items?.data?.pending_coupon}RV</h1>
                                <h1 className={s.Promotion_title} > Jami: {items?.data?.coupon}RV </h1>
                              <Link to={'/HistoryPromotion'} style={{textDecoration:'none'}} ><Button text={'Harid tarixi'} /></Link>  
                            </div>
                            <PromotionInnerMijoz refatch={items.refatch} title={'Junior'} reFatch={items.refetch} data={items?.data?.small_interval} />
                            <PromotionInnerMijoz refatch={items.refatch} title={'Middle'} reFatch={items.refetch} data={items?.data?.middle_interval} />
                            <PromotionInnerMijoz refatch={items.refatch} title={'Senior'} reFatch={items.refetch} data={items?.data?.large_interval} />
                        </div>
                    </div>

                )
            }}
        </GetAll>


    )

}