import { BrowserRouter } from 'react-router-dom'
import Routers from './Routes/Routers';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ToastContainer } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css";
function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: true,
        retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000),
        retry: 2
      }
    }
  });
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routers />
        </BrowserRouter>
        <ToastContainer />
      </QueryClientProvider>
    </div>
  );
}

export default App;
