import { useGetAll } from "hooks";
import usePost from "pages/LandingPage/hooks/usePost";
import React,{useState} from "react";
import {toast} from 'react-toastify'
import Button from "components/Button";
import x from '../../../../assets/icons/cancel-button.png'
import './BuyProd.css'
export default function BuyProd({warehouses,id}) {
    const [open, setOpen] = useState(false)
    const [state, setState] = useState({})
    function submitVal(e) {
        setState({...state,[e.target.name]:e.target.value})
    }
    const {mutate}=usePost()
    function buyFn() {
        const formdata = new FormData()
        formdata.append('customer',state.customer)
        formdata.append('warehouse',state.warehouse)
        formdata.append('product',id)
        formdata.append('amount',state.amount)
        mutate({
            url:'/landing-products/order/',
            data:formdata,
            onSuccess:success,
            onError:error,
        })
    }
    function success(params) {
        toast.success("Yuborildi!", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    function error(params) {
        toast.error("Yuborilmadi!", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    return (
        <>
            {
                open ?
                <div className='BuyProd_wrapper' >
                    <div className='BuyProd' >
                    <img onClick={()=>setOpen(false)} className="remove" src={x} />
                        <h1 className='BuyProd_title' >Ariza qoldirish</h1>
                        <div className='BuyProd_body' >
                            <label>
                                <p>Ism va familiya</p>
                                <input onChange={(e)=>submitVal(e)} name="customer" className="input_add_prod"  />
                            </label>
                            <label>
                                <p>Filial</p>
                                <select onChange={(e)=>submitVal(e)} name="warehouse" className="input_add_prod" >
                                    <option></option>
                                    {
                                        warehouses?.map((elem)=>(
                                    <option value={elem.id} >{elem.name}</option>
                                        ))
                                    }
                                </select>
                            </label>
                            <label>
                                <p>Miqdori</p>
                                <input onChange={(e)=>submitVal(e)} name="amount" className="input_add_prod"  type={'number'} />
                            </label>
                        </div>
                        <Button onClick={()=> state?.amount && state?.warehouse && state?.customer ? buyFn() : null} disabled={state?.amount && state?.warehouse && state?.customer ? false :true} text={'Yuborish'} className="close_btns" ></Button>
                    </div> 
                </div>
                :
                null
            }
            <button className='product_info_button' onClick={()=>setOpen(true)} >Sotib olish</button>
        </>

    )

}