import React from "react";
import s from './HistoryUser.module.scss'
import Table from "../../components/Table/Table";
import search from '../../assets/icons/Search_Magnifying_Glass.png'
import { elements } from "chart.js";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import { generateDate } from "../../service/dates";
export default function HistoryUser({data,setMoreSalary,moreSalary}) {
    const column = [
        {
            title:'Shartnoma raqami',
            key:'id'
        },
        {
            title: 'Sana',
            key: 'dateTime',
            render:(item)=>{
                return(
                    <span>{generateDate(item)}</span>
                )
            }
        },

        {
            title: 'Dori nomi',
            key: 'product',
            render:(item)=>{
                return(
                    <span>{item?.name}</span>
                )
            }
        },
        {
            title: 'Umumiy Miqdori',
            key: 'amount',
        },
        {
            title: 'Summa',
            key: 'summa',
        },
        {
            title: 'Mijoz ID',
            key: 'user',
            render:(item)=>{
                return(
                    <span>{item?.user_id}</span>
                )
            }
        },
    ]

    return (
        <div className={s.HistoryUser}>
            <div className={s.HistoryUser_top} >
                <h1 className={s.HistoryUser_title} >Mijozlar sotuv tarixi</h1>
               <Button onClick={()=>setMoreSalary(!moreSalary)} text={moreSalary ? 'Qisqacha' : 'Batafsil'} />
            </div>
            <div className={s.HistoryUser_table} >
                <Table column={column} data={data} />
            </div>
        </div>
    )

}