import { useMutation } from "@tanstack/react-query";
import { http, queryBuilder } from "../../service";

async function getData({ url, data, params, method = "get", onSuccess = () => {}, onError = () => {} }) {
	return await http[method](queryBuilder(url, params))
		.then(data => onSuccess(data))
		.catch(err => onError(err));
}
const useGetone = () => useMutation(getData);
export default useGetone;
