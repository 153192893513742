import React, { useState } from "react";
import s from './Orders.module.scss'
import Orderss from "../../../container/Orders";
import GetAll from "../../../modules/GetAll";
import Loader from "../../../components/Loader";
export default function Orders() {

    return (
        <GetAll name={'employees-orders'} url={'employees-orders'}>
            {({ items }) => {
                if (items.isLoading) return <Loader/>
                return (
                    <div className={s.Orders} >
                        <div className="container" >
                            <Orderss data={items?.data?.orders} />
                        </div>
                    </div>
                )
            }}
        </GetAll>

    )
}