import React, { useState } from "react";
import s from './Registration.module.scss'
import { Outlet, useNavigate } from "react-router-dom";
import Back from "../../assets/imgs/login_image.png";
export default function Registration() {
    const [token,setToken]=useState()
    return (
        <div className={s.Login}>
            <Outlet context={[token,setToken]}/>
            <img className={s.login_image} src={Back} />
        </div>
    )

}