import React,{useState} from "react";
import s from './DateInput.module.scss'
import './dateinput.css'
import Button from "components/Button";
import { DatePicker, Space } from 'antd';
import calendar from 'assets/icons/Calendar_Event.png'
import { generateMonth } from "service/dates";
export function DateInput({ change }) {
    const onChange = (dateString) => {
        // console.log(generateMonth(dateString));
        change(generateMonth(dateString))
    };
    return (
        <div className={s.DateInput}>
            <DatePicker onChange={onChange} placeholder="Oraliq tanlash" picker="month"/>
        </div>
    )

}