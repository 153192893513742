import React, { useState } from "react";
import s from './Products.module.scss'
import Card from "../../../components/Card";
import CustomProduct from "../../../components/customerProduct/CustomProduct";
import Discount from "../../../components/discount/Discount";
import card1 from '../../../assets/icons/Group 19 (1).png'
import card2 from '../../../assets/icons/Group 19 (2).png'
import GetAll from "modules/GetAll";
import Loader from "components/Loader";
export default function HomeMijoz() {
    return (
        <GetAll url={'/brand-manager-products/'} >
            {({ items }) => {
             const cardAction = [
                    {
                        icon: card1,
                        title: 'Umumiy Savdo',
                        inner: '80 000 000uzs',
                    },
                    {
                        icon: card2,
                        title: 'Mijozlar soni',
                        inner: '591ta',
                    },
                ]
                if (items?.isLoading) return <Loader/>
                return (
                    <div className={s.Actions} >
                        <div className="container" >
                            {/* <div className={s.card_wrapper} >
                                <div className={s.cards_box} >
                                    {
                                        cardAction?.map((elem, i) => (
                                            <Card item={elem} key={i} percentPart={false} />
                                        ))
                                    }
                                </div>

                            </div> */}
                            {
                                items?.data?.products?.map((elem)=>(
                                    <CustomProduct item={elem} />
                                ))
                            }
                            {
                                items?.data?.discounts?.map((item)=>(
                                    <Discount item={item} />
                                ))
                            }
                        </div>
                    </div>
                )
            }}
        </GetAll>


    )
}