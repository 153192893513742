import React, { useState } from "react";
import s from './SallProduct.module.scss'
import Button from "../Button";
import Input from "../Input";
import send from '../../assets/icons/Share_iOS_Export.svg'
import usePost from "../../hooks/usePost";
import { useGetAll } from "../../hooks";
import Loader from "../Loader";
import x from '../../assets/icons/cancel-button.png'
import { toast } from 'react-toastify'
export default function BrendmanagerAdd({ icon,refatch }) {
    const [state, setState] = useState({
        first_name: '',
        last_name: ''
    })
    const [modal, setModal] = useState(false)
    const [disabled, setdisabled] = useState(true)
    function submitVal(e) {
        setState({ ...state, [e.target.name]: e.target.value })
    }
    const { mutate, isLoading } = usePost()
    function GetData(e) {
        mutate({
            url: `users/${state?.id}/user_id/`,
            method: 'get',
            onSuccess: success,
            onError: onErrorr
        })
    }
    function onErrorr(e) {
        toast.error("User topilmadi!", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    function success(data) {
        setState(data.data)
        setdisabled(false)
    }

    function postData() {
        const formdata = new FormData
        formdata.append('user_role', state.user_role)
        mutate({
            url: `/admin-change-user-role/${state.id}/`,
            method: 'put',
            data: formdata,
            onSuccess: successpost,
            onError: onErrorrpost
        })
    }
    function onErrorrpost(e) {
        toast.error("Ro'l o'zgartrilmadi!", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    function successpost(data) {
        setModal(false)
        refatch()
        setState({
            first_name: '',
            last_name: ''
        })
        toast.success("Ro'l o'zgartrildi!", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    return (
        <div className={s.SallProduct} >
            {
                isLoading ?
                    <div className={s.loaders_back} >
                        <Loader />
                    </div>
                    :
                    null
            }
            {
                modal ?
                    <div className={s.SallProduct_modal_back} >
                        <div className={s.SallProduct_modal}>
                            <img onClick={() => setModal(false)} className={s.SallProduct_modal_back_cencel} src={x} />
                            <div className={s.SallProduct_modal_inner} >
                                <Input submitVal={submitVal} disabled={false} name='id' type='text' label=' Mijoz ID' />
                                <Input submitVal={submitVal} disabled={true} value={state?.first_name + ' ' + state?.last_name} name='first_name' type='text' label='Familiya Ism' />
                                {/* <Input  disabled={true} value={state?.phone_number } name='phone_number' type='text' label='Telefon raqam' /> */}
                                <select onChange={(e) => setState({ ...state, user_role: e.target.value })}  disabled={disabled} >
                                    <option>Tanlang</option>
                                    <option value={'brand_manager'} >{'Brand manager '}</option>
                                    <option value={'user'} >{'Mijoz '}</option>
                                </select>
                                <Button onClick={() => state?.id ? GetData() : null} disabled={state?.id ? false : true} text={'Mijoz qidirish'} icon={send} />
                                {/* <Input submitVal={colculate} disabled={disabled} value={state?.amount} name='amount' type='number' label='Umumiy miqdori' /> */}
                            </div>
                            <div style={{marginTop:'20px'}} className={s.SallProduct_modal_bottom} >
                                {/* <h1 className={s.SallProduct_modal_bottom_title} ><p>Summa:</p>{state?.total_price}uzs</h1> */}
                                <Button onClick={() => state?.user_role ? postData() : null} disabled={state?.user_role ? false : true} text={'Yuborish'} icon={send} />
                            </div>
                        </div>
                    </div>
                    :
                    null
            }
            <Button onClick={() => setModal(true)} text={"Brend menejer qo'shish"} icon={icon} />
        </div>
    )
}